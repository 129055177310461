import '../sass/style.scss';

import $ from 'jquery';
import 'slick-carousel';
import 'bootstrap';
import '../../../../../../../web/bundles/ixproductconnector/vendor/bootstrap-select/dist/js/bootstrap-select.min';
import 'swiper';
import '@fancyapps/fancybox';
import '../../../../../../../web/bundles/fosjsrouting/js/router';
import '../../../../../../../web/bundles/ixbase/js/flashes.js';

window.$ = $;

import './ix';
// import './handlers';
import './productfinder';
import './legacy/scripts';
import './ix-overload';
