const isAllPartnersLoaded = function(isAllLoaded) {
    $('.show-more-partners').css("visibility", isAllLoaded ? "hidden" : "visible" );
};

const reloadPartnersMap = (data) => {
    $.ajax({
        url: '/ix-search-map-partners',
        type: 'GET',
        async:true,
        data,
        success: function(resultsData) {
            handlers.setMarkers(resultsData.searchResults, true);

            jQuery('.ajax-reload tbody').html('');
            jQuery('.partner-result .table-responsive').animate({
                scrollTop: 0
            },500);

            if(typeof resultsData.allLoaded !== 'undefined') {
                isAllPartnersLoaded(resultsData.allLoaded);
            }

            if( resultsData.searchResults.length ) {
                for (var i in resultsData.searchResults) {
                    jQuery('.ajax-reload tbody').append(resultsData.searchResults[i].html);
                }
                jQuery('.partner-search .no-results').addClass("hidden");
            } else {
                map.removeMarkers();
                jQuery('.partner-search .no-results').removeClass("hidden");
            }
        }
    })
};


(function ($) {
    $(document).ready(function () {
        let sliderStep = 100;
        if (typeof(mapDistanceSliderStep) !== "undefined") {
            sliderStep = mapDistanceSliderStep;
        }

        $("#slider").slider({
            range: "min", value: sliderStep, min: sliderStep, step: sliderStep, max: 300, slide: function (b, c) {
                $("#form_distance").val(c.value);

                let formLocation = $("#form_location").val();
                let membership = $("#ix_search_membership").val();

                reloadPartnersMap({
                    form: {
                        distance: c.value,
                        location: formLocation
                    },
                    ix_search: {
                        membership: membership
                    },
                    page: 1
                });
            }
        });

        $("#form_distance").val($("#slider").slider("value"));
    });
})(jQuery);
