/**
 * @author dakl <dakl@infolox.de>
 */
(function ( $ ) {
    $('#scroll-to-top').on('click', () => {
        $('html, body').animate({
            scrollTop: 0
        }, 500);
    } );

    var initList = [
        {
            "triggerObj": $('.icon-nav a.language'),
            "eventName": "click",
            "callback": {
                "fnc": languageCallback,
                "param": $('.language-wrapper')
            }
        }
    ];

    init( initList );

    /**
     *
     * @param list
     */
    function init ( list ) {
        return list.forEach(doThis);

        /**
         *
         * @param item
         * @returns {*|Function}
         */
        function doThis ( item ) {
            var trigger = item.triggerObj;
            var e = item.eventName;
            var cb = item.callback.fnc;
            var param = item.callback.param;

            return trigger.on( e, param, cb );
        }
    }

    /**
     *
     * @param event
     */
    function languageCallback ( event ) {
        var target = event.data;
        var css = target.css( 'display' ) === 'none' ? 'block' : 'none';

        return target.css( 'display', css );
    }

    $('.slider-for').each(function(key, item) {
        var sliderIdName = 'slider' + key;
        var sliderNavIdName = 'sliderNav' + key;

        this.id = sliderIdName;
        $('.slider-nav')[key].id = sliderNavIdName;

        var sliderId = '#' + sliderIdName;
        var sliderNavId = '#' + sliderNavIdName;

        $(sliderId).slick({
            slidesToShow: 1,
            draggable: false,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: sliderNavId
        });

        $(sliderNavId).slick({
            draggable: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            asNavFor: sliderId,
            dots: false,
            arrows: true,
            centerMode: false,
            focusOnSelect: true
        });

        const tabs = $('.three-col-tabs-content [data-toggle="tab"]');

        $(tabs).on('shown.bs.tab', () => {
            $(sliderId).slick('setPosition');
            $(sliderNavId).slick('setPosition');
        });
    });

    // fancybox stuff
    $("[data-fancybox]").fancybox({
        showNavArrows : true,
        showArrows  : true,
        arrows      : true,
        closeBtn : true,
        touch: false,
        helpers : {
            title : { type : 'inside' },
            buttons : {}
        }
    });

    // fixes and startup state
    $( document ).ready(function() {
        // slider fixes general
        $('.block').css('width', '45%');
        $('.slider-wrapper').on("click", function() {
            $('.block').css('width', '45%');
        });

        //slider fixes for product-novelty site
        var tab_id_1 = null;
        var tab_id_2 = null;
        var url = null;
        var category = null;
        try {
            url = new URL( window.location.href );
            category = url.searchParams.get("category");
        } catch (err) {
            url = window.location.href;
            // var categories = url.match(/(\?|\&)([^=]+)\=([^&]+)/g); // this can be another solution to get the category param in internet explorer
            if (url.toString().indexOf("category=house_product") >= 1) {category = "house_product";}
            if (url.toString().indexOf("category=industry_product") >= 1) {category = "industry_product";}
            if (url.toString().indexOf("category=portable_instruments") >= 1) {category = "portable_instruments";}
        }

        if ( category === "house_product" ) {
            tab_id_1 =  "industry_product";
            tab_id_2 =  "portable_instruments";
        }
        else {
            if ( category === "portable_instruments" ) {
                tab_id_1 =  "industry_product";
                tab_id_2 =  "house_product";
            }
            else {
                if ( category === "industry_product" ) {
                    tab_id_1 =  "house_product";
                    tab_id_2 =  "portable_instruments";
                }
                else {
                    tab_id_1 =  "industry_product";
                    tab_id_2 =  "portable_instruments";
                    category = "house_product";
                }
            }
        }

        $(window).on("load", function() {
            $('#' + tab_id_1).removeClass("active");
            $('#' + tab_id_2).removeClass("active");
            $("a[data-category="+ category + "]").trigger("click");
        });

        // accordion default open "Alle Informationen" at document.ready
        $('#collapse2').addClass('in');
        $('#collapse2').css({"style":"", "aria-expanded":"true"});
    });

    // nav-bar translation button/link
    function changeDocumentLanguage() {
        let navbarLanguageVariantId = $('#nav-bar-language-variant-id');

        if (navbarLanguageVariantId.attr('isvisible') === 'false') {
            navbarLanguageVariantId.attr('style', 'visibility: visible');
            navbarLanguageVariantId.attr('isvisible', 'true');
        } else {
            navbarLanguageVariantId.attr('isvisible', 'false');
            setTimeout(function(){
                if (navbarLanguageVariantId.attr('isvisible') === 'false') {
                    navbarLanguageVariantId.attr('style', 'visibility: hidden');
                }
            }, 0);
        }
    }

    $( document ).ready(function() {
        $('#icon-nav-bar-id .language').hover(function () {
            changeDocumentLanguage();
        });
        $('#nav-bar-language-variant-id').hover(function () {
            changeDocumentLanguage();
        });
    });

    // prepare for flash notifications
    //$.fn.flashNotification('init');

    $.fn.flashNotification.defaults = {
        hideDelay: 4000,
        autoHide: true,
        animate: true
    };

    // attach add-to-cart
    $(document).on("click", ".pin", function () {
        $(this).addClass('selected');
        // check if cookies are enabled and only add item to cart if they are
        var current_item_id = $(this).data("id");
        var current_item_name = $(this).data("name");
        var cookieEnabled = (navigator.cookieEnabled) ? true : false;

        if (typeof navigator.cookieEnabled == "undefined" && !cookieEnabled)
        {
            document.cookie="testcookie";
            cookieEnabled = (document.cookie.indexOf("testcookie") !== -1) ? true : false;
        }

        if (cookieEnabled) {
            // add item to cart
            $.get( ix_phpvars.wishlist_add_related_object_route + current_item_id, function (response) {
                $.fn.flashNotification('addSuccess',ix_phpvars.wishlist_item_added_msg_prefix + " " + current_item_name + " " + ix_phpvars.wishlist_item_added_msg_suffix);
                $.get( "/" + ix_phpvars.siteaccess + '/ix-wishlist/get-wishlist-content-count/', function (response) {
                    $('.cart span').text( response );
                });
            });
            return false;
        } else {
            $.fn.flashNotification('addInfo',ix_phpvars.cookie_not_enabled_msg);
        }

        return true;
    });

    // check all checkboxes on wishlist site
    $("#c-all").click(function() {
        $('input:checkbox').not(this).prop('checked', this.checked);
    });

    // remove item from cart
    $(document).on("click", ".remove-from-cart", function() {
        var current_wishlist_item = $(this).data("id");
        removeItemFromCart(current_wishlist_item);
        $.fn.flashNotification('addSuccess',ix_phpvars.wishlist_removed_item_msg);
    });

    // helper function for --- remove item from cart --- and --- remove selected items from cart ----
    function removeItemFromCart(current_wishlist_item) {
        $.get( "/ix-wishlist/remove-item/" + current_wishlist_item, function (response) {
            $("tr").filter(function(){
                return $(this).data('id') === "wishlist-item-" + current_wishlist_item
            }).remove();
            $.get( "/" + ix_phpvars.siteaccess + '/ix-wishlist/get-wishlist-content-count/', function (response) {
                $('.cart span').text( response );
                if (response === 0) {
                    window.location.replace("/" + ix_phpvars.siteaccess + "/ix-wishlist/wishlist-content-refresh?refreshpage=" + window.location.pathname);
                }
            });
        });
    }

    // remove selected items from cart
    $(document).on("click", ".delete-all", function() {
        $( "input:checked" ).each(function( index ) {
            var checked_item_element = $( this ).get(0);
            removeItemFromCart( checked_item_element.id.substring(1) );
        });
        $.fn.flashNotification('addSuccess',ix_phpvars.wishlist_removed_selected_msg);
    });

    // wishlist mail text
    $('#wishlist-email-Link').on('click', function (event) {
        event.preventDefault();
        let email = null;
        let subject = 'Anfrage Website';

        let emailBody = 'Sehr geehrte Damen und Herren, \n \n bitte unterbreiten Sie mir ein Angebot für folgende Produkte: \n ';
        emailBody = emailBody + "----------------------------------------------------------\n ";
        for(let i = 0; i < $(this).data("item-count"); i++) {
            email = 'info@afriso.de';

            if (ix_phpvars.siteaccess.indexOf('ch-') > -1) {
                email = 'office@afriso.ch';
            }

            emailBody = emailBody + "Artikel: " + $(this).data("item-name" + i.toString()) + "\n";
            emailBody = emailBody + "Artikelnummer: " + $(this).data("item-number" + i.toString()) + "\n";
            // emailBody = emailBody + "Preis: " + $(this).data("item-price" + i.toString()) + "\n";
        }

        emailBody = emailBody + "----------------------------------------------------------\n ";
        emailBody = emailBody + "\n";
        emailBody = emailBody + "Meine Kontaktdaten sind:\n";
        emailBody = emailBody + "Firma: \n";
        emailBody = emailBody + "Vorname*: \n";
        emailBody = emailBody + "Nachname*: \n";
        emailBody = emailBody + "Straße / Nr.*: \n";
        emailBody = emailBody + "Adresszusatz: \n";
        emailBody = emailBody + "PLZ / Ort*: \n";
        emailBody = emailBody + "Telefonnummer*: \n";
        emailBody = emailBody + "\n* markierte Felder sind Pflichtfelder\n";

        if (null !== email) {
            emailBody = encodeURIComponent(emailBody);

            window.location = 'mailto:' + email + '?subject=' + subject + '&body=' + emailBody;
        }
    });

    //JUMU Cookie Disclaimer
    function SetCookie(c_name,value,expiredays)
    {
        var exdate=new Date();
        exdate.setDate(exdate.getDate()+expiredays);
        document.cookie=c_name+ "=" +escape(value)+";path=/"+((expiredays==null) ? "" : ";expires="+exdate.toGMTString())
    }
    if( document.cookie.indexOf("eucookie") ===-1 ){
        $("#eucookielaw").css('display', 'block');
        $(".cookie_space").css('display', 'block');
    }
    $("#removecookie").click(function () {
        SetCookie('eucookie','eucookie',30);
        $("#eucookielaw").css('display', 'none');
        $(".cookie_space").css('display', 'none');
    });

    // supreme the "registered" logo
    $( document ).ready(function() {
        var searchedText = '®';
        $("custom:contains('" + searchedText + "')").each(function (i, element) {
            var content = $(element).text();
            content = content.replace(searchedText, '<sup>' + searchedText + '</sup>');
            $(element).html(content);
        });
    });

    // ix-search -> download-file-language selector
    $("select[name='download-file-languages']").on("change", function(){
        var optionSelected = $("option:selected", this);
        $("a[download-file-ref-id=" + this.id + "]").attr("href", "/ix_pim_assets/" + optionSelected[0].attributes["data-uri"].value);
    });

    $(".icon-nav .search").click(function () {
        setTimeout( function() { $( '.search-wrapper #search' ).focus() }, 3 );
    });

    $('#myCarousel').carousel({
        interval: 10000
    })

    $('.carousel[data-type="multi"] .item').each(function(){
        var next = $(this).next();
        if (!next.length) {
            next = $(this).siblings(':first');
        }
        next.children(':first-child').clone().appendTo($(this));

        for (var i=0;i<2;i++) {
            next=next.next();
            if (!next.length) {
                next = $(this).siblings(':first');
            }

            next.children(':first-child').clone().appendTo($(this));
        }
    });

    $('#sushiBar-1 .carousel-inner').slick({
        prevArrow: $('#sushiBar-1 [data-slide="prev"]'),
        nextArrow: $('#sushiBar-1 [data-slide="next"]'),
        autoplay:       true,
        autoplaySpeed:  4500,
        infinite:       true,
        pauseOnFocus:   false,
        pauseOnHover:   false,
        slidesToShow:   4,
        slidesToScroll: 1
    });

    $('#sushiBar-3 .carousel-inner').slick({
        prevArrow: $('#sushiBar-3 [data-slide="prev"]'),
        nextArrow: $('#sushiBar-3 [data-slide="next"]'),
        autoplay:       false,
        autoplaySpeed:  4500,
        infinite:       true,
        pauseOnFocus:   false,
        pauseOnHover:   false,
        slidesToShow:   4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });

    $('body :not(script,sup,sub)').contents().filter(function() {
        return this.nodeType === 3;
    }).replaceWith(function() {
        return this.nodeValue.replace(/[®]|(&reg;)/g, '<sup>$&</sup>');
    });


    $('.nestedDefaultRow').map( function(index, row) {
        const leftColumn = $(' > dd:first-of-type()', row);
        const rightColumn = $(' > dd:last-of-type()', row);

        // make it activate but leave hidden, get height and deactivate
        let elem = $(row).closest('.collapse').parent()
        elem.addClass('heightGetter')
        let leftColumnHeight = leftColumn.height() + "px";
        elem.removeClass('heightGetter')

        // get line-height of left column
        let leftColumnLineHeight = leftColumn.css('line-height')

        // prepare attr value
        let rightColumnMargin = 'calc(' +leftColumnHeight+' - '+leftColumnLineHeight+')';

        if (leftColumnHeight !== leftColumnLineHeight) {
            rightColumn.attr('style', 'margin-top:' + rightColumnMargin);
        }
    });

    let leftColumn = null;
    let rightColumn= null;
    let leftColumnFirstChild = null;
    let rightColumnFirstChild = null;

    $('.ezrichtext-field').map( function(index, row) {
        if (row.parentNode.id === 'tabs-left-column') {
            leftColumn = row;
            leftColumnFirstChild = row.firstChild;
        }
        if (row.parentNode.id === 'tabs-right-column') {
            rightColumn = row;
            rightColumnFirstChild = row.firstChild;
        }

        if (null !== leftColumnFirstChild && null !== rightColumnFirstChild) {
            if ('eztoc_1_1_1' === leftColumnFirstChild.id && 'eztoc_1_1_1' !== rightColumnFirstChild.id) {
                fixEzColumnIssue(leftColumn);
            } else if ('eztoc_1_1_1' === rightColumnFirstChild.id && 'eztoc_1_1_1' !== leftColumnFirstChild.id) {
                fixEzColumnIssue(rightColumn);
            }
        }
    });

    function fixEzColumnIssue(columnWithHeading) {
        for (let i = 0; i < columnWithHeading.childNodes.length; i++) {
            if ('H3' === columnWithHeading.childNodes[i].nodeName) {
                columnWithHeading.id = 'ezRichtextColumn';
                break;
            }
        }
        $('#ezRichtextColumn').css('padding-top', '6px');
        $('#eztoc_1_1_1').remove();

    }
})(jQuery);
