var IXPC = {
    afterAjaxSearchInit: function() {}
};

// Overwrite it to add own javascript after ajax call
//IXPC.afterAjaxSearchInit = function () {};

(function($) {
$( document ).ready(function() {
    const wishlistCounterUrl = "/" + ix_phpvars.siteaccess + '/ix-wishlist/get-wishlist-content-count/';

    $.ajax({
        url: wishlistCounterUrl,
        type:       'POST',
        dataType:   'json',
        async:      true,
        success: function(data){
            $("#wishlistCounterField").html(data);
        }
    });

    IXPC.oldFormData = '';

    IXPC.productFinderFormSelector = '.productfinder-filter form';

    IXPC.currentAjax = false;
    IXPC.ajaxContainers = [".ajax-reload .result-list",".ajax-reload .product-finder-list-wrapper",".ajax-reload.downloads-search",".partner-search .ajax-reload"];
 //    var ajaxContainers = [".ajax-reload .result-list",".product-finder-list-wrapper .ajax-reload",".ajax-reload.downloads-search",".partner-search .ajax-reload"];

    IXPC.beforeAjaxSearch = function (xhr, opts) {
        $(IXPC.ajaxContainers.join(", ")).animate({ opacity: 0.4 }, 500);
    };
    IXPC.afterAjaxSearch = function () {
        $(IXPC.ajaxContainers.join(", ")).animate({ opacity: 1 }, 500);
        $("select[name='download-file-languages']").on("change", function(){
            var optionSelected = $("option:selected", this);
            $("a[download-file-ref-id=" + this.id + "]").attr("href", "/ix_pim_assets/" + optionSelected[0].attributes["data-uri"].value);
        });
    };

    IXPC.getProductFinderForm = function () {
        return jQuery('.productfinder-filter').find('form')[0];
    };

    IXPC.getSearchRoute = function (){
        if ( typeof $('#ix-search-route').val() !== 'undefined' ) {
            return $('#ix-search-route').val();
        } else {
            return ix_phpvars.routes.ix_productfinder_fragment;
        }
    };

    IXPC.getSearchRoute = function (){
        if ( typeof $('#ix-search-route').val() !== 'undefined' ) {
            return $('#ix-search-route').val();
        } else {
            if (window.location.pathname.indexOf("/afriso-download-center")>=0 || (typeof ezPageFunctionality !== 'undefined' && ezPageFunctionality === "download_center")) {
                return ix_phpvars.routes.ix_download_center;
            }
            return ix_phpvars.routes.ix_productfinder_fragment;
        }
    };

    /*var beforeAjaxSearch = function (xhr, opts) {
        $('.product-finder-list-wrapper').css({'pointer-events': "none"});
        $(ajaxContainers.join(", ")).animate({ opacity: 0.4, 'pointer-events': "none" }, 500);
    };
    var afterAjaxSearch = function () {
        $('.product-finder-list-wrapper').css({'pointer-events': "auto"});
        $(ajaxContainers.join(", ")).animate({ opacity: 1 }, 500);
        $('[data-toggle="tooltip"]').tooltip();

        // ix-search -> download-file-language selector
        $("select[name='download-file-languages']").on("change", function(){
            var optionSelected = $("option:selected", this);
            $("a[download-file-ref-id=" + this.id + "]").attr("href", "/ix_pim_assets/" + optionSelected[0].attributes["data-uri"].value);
        });
    };

    var getProductFinderForm = function () {
        return jQuery('.productfinder-filter').find('form')[0];
    };*/



    // main data reload action
    IXPC.doSearchProducts = function ( page, limit, view ) {
        if( IXPC.currentAjax === false || IXPC.currentAjax.readyState > 2 ) {
            page = typeof page !== "undefined" && page > 1 ? page : 1;
            limit = typeof limit !== "undefined" && limit > 1 ? limit : 12;
            view = typeof view !== "undefined" ? view : 'teaser';

            // Set hidden form fields by request
            $(IXPC.getProductFinderForm()).find("[name^=page]").val(page);
            $(IXPC.getProductFinderForm()).find("[name=limit]").val(limit);
            $(IXPC.getProductFinderForm()).find("[name=view]").val(view);

            var formData = $( IXPC.getProductFinderForm() ).serialize();
            IXPC.oldFormData = formData;

            var route = IXPC.getSearchRoute();

            // perform ajax request
            IXPC.currentAjax = $.ajax({
                url:        route,
                data:       formData,
                cache:      false,
                beforeSend: IXPC.beforeAjaxSearch,
                complete:   IXPC.afterAjaxSearch,
                error:      IXPC.afterAjaxSearch,
                success:    function (response) {
                    $('.ajax-reload').html(response);
                    IXPC.initInputs();
                    IXPC.afterAjaxSearchInit();
                }
            });
        }
        return false;
    };

    // On tree menu click
    $(document).on('click', '.filter-accordion li a', function () {
        IXPC.getProductFinderForm().reset();
        $('.selectpicker').selectpicker('deselectAll');
        $( IXPC.getProductFinderForm() ).find("[name=group_id]").val($(this).data("groupId"));
        $(this).closest(".filter-accordion").find("a").removeClass("active");
        $(this).addClass("active");
        // $(IXPC.productFinderFormSelector).trigger('change');
        IXPC.doSearchProducts();
        return false;
    });

    // Reset all
    $(document).on("click",".reset-productfinder",function () {
        IXPC.getProductFinderForm().reset();
        $('.productfinder-filter select option:selected').removeAttr('selected');
        $('.productfinder-filter input:not([type=hidden])').val('');
        $('.selectpicker').selectpicker('deselectAll');
        // $(IXPC.productFinderFormSelector).trigger('change');
        IXPC.doSearchProducts();
        return false;
    });

    // Remove selected filter
    $(document).on("click",".selected-values .remove-facet", function () {
        var $input = $("#"+$(this).data("inputId"));
        if($input.attr('multiple') === 'multiple') {
            $input.find('option[data-key=' + $(this).data('optionKey') + ']').prop("selected", false);
        } else {
            $input.val('');
            $input.find('option:selected').removeAttr('selected');
        }
        // $(IXPC.productFinderFormSelector).trigger('change');
        IXPC.doSearchProducts();
        return false;
    });


    // Closed bootstrap dropdown
    IXPC.closeDropdown = function() {
        var $input = $("#" + $(this).data("inputId"));
        $input.selectpicker('toggle');
        if( IXPC.oldFormData !== $(IXPC.getProductFinderForm()).serialize() ) {
            // $(IXPC.productFinderFormSelector).trigger('change');
            IXPC.doSearchProducts();
        }
        return false;
    };

    // On limit click
    $(document).on("click", ".product-finder-list-wrapper .results-per-page a",function () {
        var limit = this.text;
        $(IXPC.getProductFinderForm()).find("[name=limit]").val(limit);
        IXPC.doSearchProducts( 1, limit );
        return false;
    });

    // On pagination click
    $(document).on("click", ".pagination a",function () {
        if (window.location.pathname.indexOf("/ix-search")>=0
            || window.location.pathname.indexOf("/News")>=0
            || (typeof ezPageFunctionality !== 'undefined'
                && ezPageFunctionality === "product-novelties"
            )
        ) {
            return true;
        }

        IXPC.doSearchProducts(
            parseInt( $( this ).attr( "href" ).match( /page[_0-9_a-zA-Z]*=([^&]+)/ )[1] ),
            parseInt( $( this ).attr( "href" ).match( /limit=([^&]+)/ )[1] )
        );

        if (this.closest('.download-center-footer') !== null){ //bottom pagination
            $("html, body").animate({ scrollTop: 250 }, "slow");
        }

        return false;
    });

    // On form submit
    $(document).on('change submit','.productfinder-filter form', function (e) {
        //if(e.target.multiple!==true) {
            IXPC.doSearchProducts();
        //}
        return false;
    });

    // Language preselector
    $('#preselected_language').selectpicker( { selectedTextFormat : 'values' } );
    $('#preselected_language').unbind('change').change(function () {
        let selected = $(this).find(":selected").val();
        $(".language-selector-row").each(function (index) {
            $(this).selectpicker( { selectedTextFormat : 'values' } );
            $(this).selectpicker('val', selected);
        });
        $('.selectpicker').selectpicker('refresh');
    });



    var closeDropdown = function() {
        var $input = $("#" + $(this).data("inputId"));
        $input.selectpicker('toggle');
        if( IXPC.oldFormData !== $(IXPC.getProductFinderForm()).serialize() ) {
            $(IXPC.productFinderFormSelector).trigger('change');
        }
        return false;
    };

    IXPC.initInputs = function () {
        var selectpickerInited = false;

        $('.productfinder-filter select option:empty').remove();

        $('.selectpicker')
            .selectpicker( { selectedTextFormat : 'static' } )
            .on('loaded.bs.select', function (e) {
                if(!selectpickerInited) {
                    selectpickerInited = true;
                    // Add close button in dropdown
                    $.each($('.productfinder-filter button .bs-caret'), function (i, el) {
                        var $form_row = $(el).closest(".form-group");
                        var $btn_close = $("<a>",
                            {
                                href:"#",
                                class: "btn-close",
                                text: ix_phpvars.translation.msg.facet_close || "Schließen", "data-close-text": ix_phpvars.translation.msg.facet_close || "Schließen",
                                "data-search-text": ix_phpvars.translation.msg.facet_apply || "Speichern",
                                "data-input-id": $form_row.find("select").attr("id")
                            }
                        );
                        $btn_close.click(closeDropdown);
                        $form_row.find('ul.dropdown-menu').after($btn_close);
                    });
                }
            })
            .on('changed.bs.select', function () {
                // Rename close button
                if(this.multiple) {
                    var $closeButtons = $('.dropdown-menu.open .btn-close');
                    if (IXPC.oldFormData === $(IXPC.productFinderFormSelector).serialize()) {
                        $closeButtons.removeClass("search").text($closeButtons.data("closeText"));
                    } else {
                        $closeButtons.addClass("search").text($closeButtons.data("searchText"));
                    }
                }
            })
            .on('shown.bs.select', function (e) {
                $(e.target).prev('.dropdown-menu').css("max-height", parseInt($(e.target).prev('.dropdown-menu').css("max-height")) + 30 );
            });
    };

    IXPC.initInputs();
});
})(jQuery);

