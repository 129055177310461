!(function (a) {
    "function" == typeof define && define.amd ? define(["jquery"], a) : a(jQuery);
})(function (a) {
    (a.ui = a.ui || {}), (a.ui.version = "1.12.1");
    var b = 0,
        c = Array.prototype.slice;
    (a.cleanData = (function (b) {
        return function (c) {
            var d, e, f;
            for (f = 0; null != (e = c[f]); f++)
                try {
                    (d = a._data(e, "events")) && d.remove && a(e).triggerHandler("remove");
                } catch (a) {}
            b(c);
        };
    })(a.cleanData)),
        (a.widget = function (b, c, d) {
            var e,
                f,
                g,
                h = {},
                i = b.split(".")[0];
            b = b.split(".")[1];
            var j = i + "-" + b;
            return (
                d || ((d = c), (c = a.Widget)),
                a.isArray(d) && (d = a.extend.apply(null, [{}].concat(d))),
                    (a.expr[":"][j.toLowerCase()] = function (b) {
                        return !!a.data(b, j);
                    }),
                    (a[i] = a[i] || {}),
                    (e = a[i][b]),
                    (f = a[i][b] = function (a, b) {
                        return this._createWidget ? void (arguments.length && this._createWidget(a, b)) : new f(a, b);
                    }),
                    a.extend(f, e, { version: d.version, _proto: a.extend({}, d), _childConstructors: [] }),
                    (g = new c()),
                    (g.options = a.widget.extend({}, g.options)),
                    a.each(d, function (b, d) {
                        return a.isFunction(d)
                            ? void (h[b] = (function () {
                                function a() {
                                    return c.prototype[b].apply(this, arguments);
                                }
                                function e(a) {
                                    return c.prototype[b].apply(this, a);
                                }
                                return function () {
                                    var b,
                                        c = this._super,
                                        f = this._superApply;
                                    return (this._super = a), (this._superApply = e), (b = d.apply(this, arguments)), (this._super = c), (this._superApply = f), b;
                                };
                            })())
                            : void (h[b] = d);
                    }),
                    (f.prototype = a.widget.extend(g, { widgetEventPrefix: e ? g.widgetEventPrefix || b : b }, h, { constructor: f, namespace: i, widgetName: b, widgetFullName: j })),
                    e
                        ? (a.each(e._childConstructors, function (b, c) {
                            var d = c.prototype;
                            a.widget(d.namespace + "." + d.widgetName, f, c._proto);
                        }),
                            delete e._childConstructors)
                        : c._childConstructors.push(f),
                    a.widget.bridge(b, f),
                    f
            );
        }),
        (a.widget.extend = function (b) {
            for (var d, e, f = c.call(arguments, 1), g = 0, h = f.length; h > g; g++)
                for (d in f[g]) (e = f[g][d]), f[g].hasOwnProperty(d) && void 0 !== e && (b[d] = a.isPlainObject(e) ? (a.isPlainObject(b[d]) ? a.widget.extend({}, b[d], e) : a.widget.extend({}, e)) : e);
            return b;
        }),
        (a.widget.bridge = function (b, d) {
            var e = d.prototype.widgetFullName || b;
            a.fn[b] = function (f) {
                var g = "string" == typeof f,
                    h = c.call(arguments, 1),
                    i = this;
                return (
                    g
                        ? this.length || "instance" !== f
                            ? this.each(function () {
                                var c,
                                    d = a.data(this, e);
                                return "instance" === f
                                    ? ((i = d), !1)
                                    : d
                                        ? a.isFunction(d[f]) && "_" !== f.charAt(0)
                                            ? ((c = d[f].apply(d, h)), c !== d && void 0 !== c ? ((i = c && c.jquery ? i.pushStack(c.get()) : c), !1) : void 0)
                                            : a.error("no such method '" + f + "' for " + b + " widget instance")
                                        : a.error("cannot call methods on " + b + " prior to initialization; attempted to call method '" + f + "'");
                            })
                            : (i = void 0)
                        : (h.length && (f = a.widget.extend.apply(null, [f].concat(h))),
                            this.each(function () {
                                var b = a.data(this, e);
                                b ? (b.option(f || {}), b._init && b._init()) : a.data(this, e, new d(f, this));
                            })),
                        i
                );
            };
        }),
        (a.Widget = function () {}),
        (a.Widget._childConstructors = []),
        (a.Widget.prototype = {
            widgetName: "widget",
            widgetEventPrefix: "",
            defaultElement: "<div>",
            options: { classes: {}, disabled: !1, create: null },
            _createWidget: function (c, d) {
                (d = a(d || this.defaultElement || this)[0]),
                    (this.element = a(d)),
                    (this.uuid = b++),
                    (this.eventNamespace = "." + this.widgetName + this.uuid),
                    (this.bindings = a()),
                    (this.hoverable = a()),
                    (this.focusable = a()),
                    (this.classesElementLookup = {}),
                d !== this &&
                (a.data(d, this.widgetFullName, this),
                    this._on(!0, this.element, {
                        remove: function (a) {
                            a.target === d && this.destroy();
                        },
                    }),
                    (this.document = a(d.style ? d.ownerDocument : d.document || d)),
                    (this.window = a(this.document[0].defaultView || this.document[0].parentWindow))),
                    (this.options = a.widget.extend({}, this.options, this._getCreateOptions(), c)),
                    this._create(),
                this.options.disabled && this._setOptionDisabled(this.options.disabled),
                    this._trigger("create", null, this._getCreateEventData()),
                    this._init();
            },
            _getCreateOptions: function () {
                return {};
            },
            _getCreateEventData: a.noop,
            _create: a.noop,
            _init: a.noop,
            destroy: function () {
                var b = this;
                this._destroy(),
                    a.each(this.classesElementLookup, function (a, c) {
                        b._removeClass(c, a);
                    }),
                    this.element.off(this.eventNamespace).removeData(this.widgetFullName),
                    this.widget().off(this.eventNamespace).removeAttr("aria-disabled"),
                    this.bindings.off(this.eventNamespace);
            },
            _destroy: a.noop,
            widget: function () {
                return this.element;
            },
            option: function (b, c) {
                var d,
                    e,
                    f,
                    g = b;
                if (0 === arguments.length) return a.widget.extend({}, this.options);
                if ("string" == typeof b)
                    if (((g = {}), (d = b.split(".")), (b = d.shift()), d.length)) {
                        for (e = g[b] = a.widget.extend({}, this.options[b]), f = 0; d.length - 1 > f; f++) (e[d[f]] = e[d[f]] || {}), (e = e[d[f]]);
                        if (((b = d.pop()), 1 === arguments.length)) return void 0 === e[b] ? null : e[b];
                        e[b] = c;
                    } else {
                        if (1 === arguments.length) return void 0 === this.options[b] ? null : this.options[b];
                        g[b] = c;
                    }
                return this._setOptions(g), this;
            },
            _setOptions: function (a) {
                var b;
                for (b in a) this._setOption(b, a[b]);
                return this;
            },
            _setOption: function (a, b) {
                return "classes" === a && this._setOptionClasses(b), (this.options[a] = b), "disabled" === a && this._setOptionDisabled(b), this;
            },
            _setOptionClasses: function (b) {
                var c, d, e;
                for (c in b) (e = this.classesElementLookup[c]), b[c] !== this.options.classes[c] && e && e.length && ((d = a(e.get())), this._removeClass(e, c), d.addClass(this._classes({ element: d, keys: c, classes: b, add: !0 })));
            },
            _setOptionDisabled: function (a) {
                this._toggleClass(this.widget(), this.widgetFullName + "-disabled", null, !!a), a && (this._removeClass(this.hoverable, null, "ui-state-hover"), this._removeClass(this.focusable, null, "ui-state-focus"));
            },
            enable: function () {
                return this._setOptions({ disabled: !1 });
            },
            disable: function () {
                return this._setOptions({ disabled: !0 });
            },
            _classes: function (b) {
                function c(c, f) {
                    var g, h;
                    for (h = 0; c.length > h; h++)
                        (g = e.classesElementLookup[c[h]] || a()),
                            (g = a(b.add ? a.unique(g.get().concat(b.element.get())) : g.not(b.element).get())),
                            (e.classesElementLookup[c[h]] = g),
                            d.push(c[h]),
                        f && b.classes[c[h]] && d.push(b.classes[c[h]]);
                }
                var d = [],
                    e = this;
                return (
                    (b = a.extend({ element: this.element, classes: this.options.classes || {} }, b)),
                        this._on(b.element, { remove: "_untrackClassesElement" }),
                    b.keys && c(b.keys.match(/\S+/g) || [], !0),
                    b.extra && c(b.extra.match(/\S+/g) || []),
                        d.join(" ")
                );
            },
            _untrackClassesElement: function (b) {
                var c = this;
                a.each(c.classesElementLookup, function (d, e) {
                    -1 !== a.inArray(b.target, e) && (c.classesElementLookup[d] = a(e.not(b.target).get()));
                });
            },
            _removeClass: function (a, b, c) {
                return this._toggleClass(a, b, c, !1);
            },
            _addClass: function (a, b, c) {
                return this._toggleClass(a, b, c, !0);
            },
            _toggleClass: function (a, b, c, d) {
                d = "boolean" == typeof d ? d : c;
                var e = "string" == typeof a || null === a,
                    f = { extra: e ? b : c, keys: e ? a : b, element: e ? this.element : a, add: d };
                return f.element.toggleClass(this._classes(f), d), this;
            },
            _on: function (b, c, d) {
                var e,
                    f = this;
                "boolean" != typeof b && ((d = c), (c = b), (b = !1)),
                    d ? ((c = e = a(c)), (this.bindings = this.bindings.add(c))) : ((d = c), (c = this.element), (e = this.widget())),
                    a.each(d, function (d, g) {
                        function h() {
                            return b || (!0 !== f.options.disabled && !a(this).hasClass("ui-state-disabled")) ? ("string" == typeof g ? f[g] : g).apply(f, arguments) : void 0;
                        }
                        "string" != typeof g && (h.guid = g.guid = g.guid || h.guid || a.guid++);
                        var i = d.match(/^([\w:-]*)\s*(.*)$/),
                            j = i[1] + f.eventNamespace,
                            k = i[2];
                        k ? e.on(j, k, h) : c.on(j, h);
                    });
            },
            _off: function (b, c) {
                (c = (c || "").split(" ").join(this.eventNamespace + " ") + this.eventNamespace),
                    b.off(c).off(c),
                    (this.bindings = a(this.bindings.not(b).get())),
                    (this.focusable = a(this.focusable.not(b).get())),
                    (this.hoverable = a(this.hoverable.not(b).get()));
            },
            _delay: function (a, b) {
                function c() {
                    return ("string" == typeof a ? d[a] : a).apply(d, arguments);
                }
                var d = this;
                return setTimeout(c, b || 0);
            },
            _hoverable: function (b) {
                (this.hoverable = this.hoverable.add(b)),
                    this._on(b, {
                        mouseenter: function (b) {
                            this._addClass(a(b.currentTarget), null, "ui-state-hover");
                        },
                        mouseleave: function (b) {
                            this._removeClass(a(b.currentTarget), null, "ui-state-hover");
                        },
                    });
            },
            _focusable: function (b) {
                (this.focusable = this.focusable.add(b)),
                    this._on(b, {
                        focusin: function (b) {
                            this._addClass(a(b.currentTarget), null, "ui-state-focus");
                        },
                        focusout: function (b) {
                            this._removeClass(a(b.currentTarget), null, "ui-state-focus");
                        },
                    });
            },
            _trigger: function (b, c, d) {
                var e,
                    f,
                    g = this.options[b];
                if (((d = d || {}), (c = a.Event(c)), (c.type = (b === this.widgetEventPrefix ? b : this.widgetEventPrefix + b).toLowerCase()), (c.target = this.element[0]), (f = c.originalEvent))) for (e in f) e in c || (c[e] = f[e]);
                return this.element.trigger(c, d), !((a.isFunction(g) && !1 === g.apply(this.element[0], [c].concat(d))) || c.isDefaultPrevented());
            },
        }),
        a.each({ show: "fadeIn", hide: "fadeOut" }, function (b, c) {
            a.Widget.prototype["_" + b] = function (d, e, f) {
                "string" == typeof e && (e = { effect: e });
                var g,
                    h = e ? (!0 === e || "number" == typeof e ? c : e.effect || c) : b;
                (e = e || {}),
                "number" == typeof e && (e = { duration: e }),
                    (g = !a.isEmptyObject(e)),
                    (e.complete = f),
                e.delay && d.delay(e.delay),
                    g && a.effects && a.effects.effect[h]
                        ? d[b](e)
                        : h !== b && d[h]
                            ? d[h](e.duration, e.easing, f)
                            : d.queue(function (c) {
                                a(this)[b](), f && f.call(d[0]), c();
                            });
            };
        }),
        a.widget,
        a.extend(a.expr[":"], {
            data: a.expr.createPseudo
                ? a.expr.createPseudo(function (b) {
                    return function (c) {
                        return !!a.data(c, b);
                    };
                })
                : function (b, c, d) {
                    return !!a.data(b, d[3]);
                },
        }),
        (a.ui.keyCode = { BACKSPACE: 8, COMMA: 188, DELETE: 46, DOWN: 40, END: 35, ENTER: 13, ESCAPE: 27, HOME: 36, LEFT: 37, PAGE_DOWN: 34, PAGE_UP: 33, PERIOD: 190, RIGHT: 39, SPACE: 32, TAB: 9, UP: 38 }),
        (a.fn.scrollParent = function (b) {
            var c = this.css("position"),
                d = "absolute" === c,
                e = b ? /(auto|scroll|hidden)/ : /(auto|scroll)/,
                f = this.parents()
                    .filter(function () {
                        var b = a(this);
                        return (!d || "static" !== b.css("position")) && e.test(b.css("overflow") + b.css("overflow-y") + b.css("overflow-x"));
                    })
                    .eq(0);
            return "fixed" !== c && f.length ? f : a(this[0].ownerDocument || document);
        }),
        (a.ui.ie = !!/msie [\w.]+/.exec(navigator.userAgent.toLowerCase()));
    var d = !1;
    a(document).on("mouseup", function () {
        d = !1;
    }),
        a.widget("ui.mouse", {
            version: "1.12.1",
            options: { cancel: "input, textarea, button, select, option", distance: 1, delay: 0 },
            _mouseInit: function () {
                var b = this;
                this.element
                    .on("mousedown." + this.widgetName, function (a) {
                        return b._mouseDown(a);
                    })
                    .on("click." + this.widgetName, function (c) {
                        return !0 === a.data(c.target, b.widgetName + ".preventClickEvent") ? (a.removeData(c.target, b.widgetName + ".preventClickEvent"), c.stopImmediatePropagation(), !1) : void 0;
                    }),
                    (this.started = !1);
            },
            _mouseDestroy: function () {
                this.element.off("." + this.widgetName), this._mouseMoveDelegate && this.document.off("mousemove." + this.widgetName, this._mouseMoveDelegate).off("mouseup." + this.widgetName, this._mouseUpDelegate);
            },
            _mouseDown: function (b) {
                if (!d) {
                    (this._mouseMoved = !1), this._mouseStarted && this._mouseUp(b), (this._mouseDownEvent = b);
                    var c = this,
                        e = 1 === b.which,
                        f = !("string" != typeof this.options.cancel || !b.target.nodeName) && a(b.target).closest(this.options.cancel).length;
                    return (
                        !(e && !f && this._mouseCapture(b)) ||
                        ((this.mouseDelayMet = !this.options.delay),
                        this.mouseDelayMet ||
                        (this._mouseDelayTimer = setTimeout(function () {
                            c.mouseDelayMet = !0;
                        }, this.options.delay)),
                            this._mouseDistanceMet(b) && this._mouseDelayMet(b) && ((this._mouseStarted = !1 !== this._mouseStart(b)), !this._mouseStarted)
                                ? (b.preventDefault(), !0)
                                : (!0 === a.data(b.target, this.widgetName + ".preventClickEvent") && a.removeData(b.target, this.widgetName + ".preventClickEvent"),
                                    (this._mouseMoveDelegate = function (a) {
                                        return c._mouseMove(a);
                                    }),
                                    (this._mouseUpDelegate = function (a) {
                                        return c._mouseUp(a);
                                    }),
                                    this.document.on("mousemove." + this.widgetName, this._mouseMoveDelegate).on("mouseup." + this.widgetName, this._mouseUpDelegate),
                                    b.preventDefault(),
                                    (d = !0),
                                    !0))
                    );
                }
            },
            _mouseMove: function (b) {
                if (this._mouseMoved) {
                    if (a.ui.ie && (!document.documentMode || 9 > document.documentMode) && !b.button) return this._mouseUp(b);
                    if (!b.which)
                        if (b.originalEvent.altKey || b.originalEvent.ctrlKey || b.originalEvent.metaKey || b.originalEvent.shiftKey) this.ignoreMissingWhich = !0;
                        else if (!this.ignoreMissingWhich) return this._mouseUp(b);
                }
                return (
                    (b.which || b.button) && (this._mouseMoved = !0),
                        this._mouseStarted
                            ? (this._mouseDrag(b), b.preventDefault())
                            : (this._mouseDistanceMet(b) && this._mouseDelayMet(b) && ((this._mouseStarted = !1 !== this._mouseStart(this._mouseDownEvent, b)), this._mouseStarted ? this._mouseDrag(b) : this._mouseUp(b)), !this._mouseStarted)
                );
            },
            _mouseUp: function (b) {
                this.document.off("mousemove." + this.widgetName, this._mouseMoveDelegate).off("mouseup." + this.widgetName, this._mouseUpDelegate),
                this._mouseStarted && ((this._mouseStarted = !1), b.target === this._mouseDownEvent.target && a.data(b.target, this.widgetName + ".preventClickEvent", !0), this._mouseStop(b)),
                this._mouseDelayTimer && (clearTimeout(this._mouseDelayTimer), delete this._mouseDelayTimer),
                    (this.ignoreMissingWhich = !1),
                    (d = !1),
                    b.preventDefault();
            },
            _mouseDistanceMet: function (a) {
                return Math.max(Math.abs(this._mouseDownEvent.pageX - a.pageX), Math.abs(this._mouseDownEvent.pageY - a.pageY)) >= this.options.distance;
            },
            _mouseDelayMet: function () {
                return this.mouseDelayMet;
            },
            _mouseStart: function () {},
            _mouseDrag: function () {},
            _mouseStop: function () {},
            _mouseCapture: function () {
                return !0;
            },
        }),
        (a.ui.plugin = {
            add: function (b, c, d) {
                var e,
                    f = a.ui[b].prototype;
                for (e in d) (f.plugins[e] = f.plugins[e] || []), f.plugins[e].push([c, d[e]]);
            },
            call: function (a, b, c, d) {
                var e,
                    f = a.plugins[b];
                if (f && (d || (a.element[0].parentNode && 11 !== a.element[0].parentNode.nodeType))) for (e = 0; f.length > e; e++) a.options[f[e][0]] && f[e][1].apply(a.element, c);
            },
        }),
        (a.ui.safeActiveElement = function (a) {
            var b;
            try {
                b = a.activeElement;
            } catch (c) {
                b = a.body;
            }
            return b || (b = a.body), b.nodeName || (b = a.body), b;
        }),
        (a.ui.safeBlur = function (b) {
            b && "body" !== b.nodeName.toLowerCase() && a(b).trigger("blur");
        }),
        a.widget("ui.draggable", a.ui.mouse, {
            version: "1.12.1",
            widgetEventPrefix: "drag",
            options: {
                addClasses: !0,
                appendTo: "parent",
                axis: !1,
                connectToSortable: !1,
                containment: !1,
                cursor: "auto",
                cursorAt: !1,
                grid: !1,
                handle: !1,
                helper: "original",
                iframeFix: !1,
                opacity: !1,
                refreshPositions: !1,
                revert: !1,
                revertDuration: 500,
                scope: "default",
                scroll: !0,
                scrollSensitivity: 20,
                scrollSpeed: 20,
                snap: !1,
                snapMode: "both",
                snapTolerance: 20,
                stack: !1,
                zIndex: !1,
                drag: null,
                start: null,
                stop: null,
            },
            _create: function () {
                "original" === this.options.helper && this._setPositionRelative(), this.options.addClasses && this._addClass("ui-draggable"), this._setHandleClassName(), this._mouseInit();
            },
            _setOption: function (a, b) {
                this._super(a, b), "handle" === a && (this._removeHandleClassName(), this._setHandleClassName());
            },
            _destroy: function () {
                return (this.helper || this.element).is(".ui-draggable-dragging") ? void (this.destroyOnClear = !0) : (this._removeHandleClassName(), void this._mouseDestroy());
            },
            _mouseCapture: function (b) {
                var c = this.options;
                return (
                    !(this.helper || c.disabled || a(b.target).closest(".ui-resizable-handle").length > 0) &&
                    ((this.handle = this._getHandle(b)), !!this.handle && (this._blurActiveElement(b), this._blockFrames(!0 === c.iframeFix ? "iframe" : c.iframeFix), !0))
                );
            },
            _blockFrames: function (b) {
                this.iframeBlocks = this.document.find(b).map(function () {
                    var b = a(this);
                    return a("<div>").css("position", "absolute").appendTo(b.parent()).outerWidth(b.outerWidth()).outerHeight(b.outerHeight()).offset(b.offset())[0];
                });
            },
            _unblockFrames: function () {
                this.iframeBlocks && (this.iframeBlocks.remove(), delete this.iframeBlocks);
            },
            _blurActiveElement: function (b) {
                var c = a.ui.safeActiveElement(this.document[0]);
                a(b.target).closest(c).length || a.ui.safeBlur(c);
            },
            _mouseStart: function (b) {
                var c = this.options;
                return (
                    (this.helper = this._createHelper(b)),
                        this._addClass(this.helper, "ui-draggable-dragging"),
                        this._cacheHelperProportions(),
                    a.ui.ddmanager && (a.ui.ddmanager.current = this),
                        this._cacheMargins(),
                        (this.cssPosition = this.helper.css("position")),
                        (this.scrollParent = this.helper.scrollParent(!0)),
                        (this.offsetParent = this.helper.offsetParent()),
                        (this.hasFixedAncestor =
                            this.helper.parents().filter(function () {
                                return "fixed" === a(this).css("position");
                            }).length > 0),
                        (this.positionAbs = this.element.offset()),
                        this._refreshOffsets(b),
                        (this.originalPosition = this.position = this._generatePosition(b, !1)),
                        (this.originalPageX = b.pageX),
                        (this.originalPageY = b.pageY),
                    c.cursorAt && this._adjustOffsetFromHelper(c.cursorAt),
                        this._setContainment(),
                        !1 === this._trigger("start", b)
                            ? (this._clear(), !1)
                            : (this._cacheHelperProportions(), a.ui.ddmanager && !c.dropBehaviour && a.ui.ddmanager.prepareOffsets(this, b), this._mouseDrag(b, !0), a.ui.ddmanager && a.ui.ddmanager.dragStart(this, b), !0)
                );
            },
            _refreshOffsets: function (a) {
                (this.offset = { top: this.positionAbs.top - this.margins.top, left: this.positionAbs.left - this.margins.left, scroll: !1, parent: this._getParentOffset(), relative: this._getRelativeOffset() }),
                    (this.offset.click = { left: a.pageX - this.offset.left, top: a.pageY - this.offset.top });
            },
            _mouseDrag: function (b, c) {
                if ((this.hasFixedAncestor && (this.offset.parent = this._getParentOffset()), (this.position = this._generatePosition(b, !0)), (this.positionAbs = this._convertPositionTo("absolute")), !c)) {
                    var d = this._uiHash();
                    if (!1 === this._trigger("drag", b, d)) return this._mouseUp(new a.Event("mouseup", b)), !1;
                    this.position = d.position;
                }
                return (this.helper[0].style.left = this.position.left + "px"), (this.helper[0].style.top = this.position.top + "px"), a.ui.ddmanager && a.ui.ddmanager.drag(this, b), !1;
            },
            _mouseStop: function (b) {
                var c = this,
                    d = !1;
                return (
                    a.ui.ddmanager && !this.options.dropBehaviour && (d = a.ui.ddmanager.drop(this, b)),
                    this.dropped && ((d = this.dropped), (this.dropped = !1)),
                        ("invalid" === this.options.revert && !d) || ("valid" === this.options.revert && d) || !0 === this.options.revert || (a.isFunction(this.options.revert) && this.options.revert.call(this.element, d))
                            ? a(this.helper).animate(this.originalPosition, parseInt(this.options.revertDuration, 10), function () {
                                !1 !== c._trigger("stop", b) && c._clear();
                            })
                            : !1 !== this._trigger("stop", b) && this._clear(),
                        !1
                );
            },
            _mouseUp: function (b) {
                return this._unblockFrames(), a.ui.ddmanager && a.ui.ddmanager.dragStop(this, b), this.handleElement.is(b.target) && this.element.trigger("focus"), a.ui.mouse.prototype._mouseUp.call(this, b);
            },
            cancel: function () {
                return this.helper.is(".ui-draggable-dragging") ? this._mouseUp(new a.Event("mouseup", { target: this.element[0] })) : this._clear(), this;
            },
            _getHandle: function (b) {
                return !this.options.handle || !!a(b.target).closest(this.element.find(this.options.handle)).length;
            },
            _setHandleClassName: function () {
                (this.handleElement = this.options.handle ? this.element.find(this.options.handle) : this.element), this._addClass(this.handleElement, "ui-draggable-handle");
            },
            _removeHandleClassName: function () {
                this._removeClass(this.handleElement, "ui-draggable-handle");
            },
            _createHelper: function (b) {
                var c = this.options,
                    d = a.isFunction(c.helper),
                    e = d ? a(c.helper.apply(this.element[0], [b])) : "clone" === c.helper ? this.element.clone().removeAttr("id") : this.element;
                return (
                    e.parents("body").length || e.appendTo("parent" === c.appendTo ? this.element[0].parentNode : c.appendTo),
                    d && e[0] === this.element[0] && this._setPositionRelative(),
                    e[0] === this.element[0] || /(fixed|absolute)/.test(e.css("position")) || e.css("position", "absolute"),
                        e
                );
            },
            _setPositionRelative: function () {
                /^(?:r|a|f)/.test(this.element.css("position")) || (this.element[0].style.position = "relative");
            },
            _adjustOffsetFromHelper: function (b) {
                "string" == typeof b && (b = b.split(" ")),
                a.isArray(b) && (b = { left: +b[0], top: +b[1] || 0 }),
                "left" in b && (this.offset.click.left = b.left + this.margins.left),
                "right" in b && (this.offset.click.left = this.helperProportions.width - b.right + this.margins.left),
                "top" in b && (this.offset.click.top = b.top + this.margins.top),
                "bottom" in b && (this.offset.click.top = this.helperProportions.height - b.bottom + this.margins.top);
            },
            _isRootNode: function (a) {
                return /(html|body)/i.test(a.tagName) || a === this.document[0];
            },
            _getParentOffset: function () {
                var b = this.offsetParent.offset(),
                    c = this.document[0];
                return (
                    "absolute" === this.cssPosition && this.scrollParent[0] !== c && a.contains(this.scrollParent[0], this.offsetParent[0]) && ((b.left += this.scrollParent.scrollLeft()), (b.top += this.scrollParent.scrollTop())),
                    this._isRootNode(this.offsetParent[0]) && (b = { top: 0, left: 0 }),
                        { top: b.top + (parseInt(this.offsetParent.css("borderTopWidth"), 10) || 0), left: b.left + (parseInt(this.offsetParent.css("borderLeftWidth"), 10) || 0) }
                );
            },
            _getRelativeOffset: function () {
                if ("relative" !== this.cssPosition) return { top: 0, left: 0 };
                var a = this.element.position(),
                    b = this._isRootNode(this.scrollParent[0]);
                return { top: a.top - (parseInt(this.helper.css("top"), 10) || 0) + (b ? 0 : this.scrollParent.scrollTop()), left: a.left - (parseInt(this.helper.css("left"), 10) || 0) + (b ? 0 : this.scrollParent.scrollLeft()) };
            },
            _cacheMargins: function () {
                this.margins = {
                    left: parseInt(this.element.css("marginLeft"), 10) || 0,
                    top: parseInt(this.element.css("marginTop"), 10) || 0,
                    right: parseInt(this.element.css("marginRight"), 10) || 0,
                    bottom: parseInt(this.element.css("marginBottom"), 10) || 0,
                };
            },
            _cacheHelperProportions: function () {
                this.helperProportions = { width: this.helper.outerWidth(), height: this.helper.outerHeight() };
            },
            _setContainment: function () {
                var b,
                    c,
                    d,
                    e = this.options,
                    f = this.document[0];
                return (
                    (this.relativeContainer = null),
                        e.containment
                            ? "window" === e.containment
                                ? void (this.containment = [
                                    a(window).scrollLeft() - this.offset.relative.left - this.offset.parent.left,
                                    a(window).scrollTop() - this.offset.relative.top - this.offset.parent.top,
                                    a(window).scrollLeft() + a(window).width() - this.helperProportions.width - this.margins.left,
                                    a(window).scrollTop() + (a(window).height() || f.body.parentNode.scrollHeight) - this.helperProportions.height - this.margins.top,
                                ])
                                : "document" === e.containment
                                    ? void (this.containment = [0, 0, a(f).width() - this.helperProportions.width - this.margins.left, (a(f).height() || f.body.parentNode.scrollHeight) - this.helperProportions.height - this.margins.top])
                                    : e.containment.constructor === Array
                                        ? void (this.containment = e.containment)
                                        : ("parent" === e.containment && (e.containment = this.helper[0].parentNode),
                                            (c = a(e.containment)),
                                            void (
                                                (d = c[0]) &&
                                                ((b = /(scroll|auto)/.test(c.css("overflow"))),
                                                    (this.containment = [
                                                        (parseInt(c.css("borderLeftWidth"), 10) || 0) + (parseInt(c.css("paddingLeft"), 10) || 0),
                                                        (parseInt(c.css("borderTopWidth"), 10) || 0) + (parseInt(c.css("paddingTop"), 10) || 0),
                                                        (b ? Math.max(d.scrollWidth, d.offsetWidth) : d.offsetWidth) -
                                                        (parseInt(c.css("borderRightWidth"), 10) || 0) -
                                                        (parseInt(c.css("paddingRight"), 10) || 0) -
                                                        this.helperProportions.width -
                                                        this.margins.left -
                                                        this.margins.right,
                                                        (b ? Math.max(d.scrollHeight, d.offsetHeight) : d.offsetHeight) -
                                                        (parseInt(c.css("borderBottomWidth"), 10) || 0) -
                                                        (parseInt(c.css("paddingBottom"), 10) || 0) -
                                                        this.helperProportions.height -
                                                        this.margins.top -
                                                        this.margins.bottom,
                                                    ]),
                                                    (this.relativeContainer = c))
                                            ))
                            : void (this.containment = null)
                );
            },
            _convertPositionTo: function (a, b) {
                b || (b = this.position);
                var c = "absolute" === a ? 1 : -1,
                    d = this._isRootNode(this.scrollParent[0]);
                return {
                    top: b.top + this.offset.relative.top * c + this.offset.parent.top * c - ("fixed" === this.cssPosition ? -this.offset.scroll.top : d ? 0 : this.offset.scroll.top) * c,
                    left: b.left + this.offset.relative.left * c + this.offset.parent.left * c - ("fixed" === this.cssPosition ? -this.offset.scroll.left : d ? 0 : this.offset.scroll.left) * c,
                };
            },
            _generatePosition: function (a, b) {
                var c,
                    d,
                    e,
                    f,
                    g = this.options,
                    h = this._isRootNode(this.scrollParent[0]),
                    i = a.pageX,
                    j = a.pageY;
                return (
                    (h && this.offset.scroll) || (this.offset.scroll = { top: this.scrollParent.scrollTop(), left: this.scrollParent.scrollLeft() }),
                    b &&
                    (this.containment &&
                    (this.relativeContainer
                        ? ((d = this.relativeContainer.offset()), (c = [this.containment[0] + d.left, this.containment[1] + d.top, this.containment[2] + d.left, this.containment[3] + d.top]))
                        : (c = this.containment),
                    a.pageX - this.offset.click.left < c[0] && (i = c[0] + this.offset.click.left),
                    a.pageY - this.offset.click.top < c[1] && (j = c[1] + this.offset.click.top),
                    a.pageX - this.offset.click.left > c[2] && (i = c[2] + this.offset.click.left),
                    a.pageY - this.offset.click.top > c[3] && (j = c[3] + this.offset.click.top)),
                    g.grid &&
                    ((e = g.grid[1] ? this.originalPageY + Math.round((j - this.originalPageY) / g.grid[1]) * g.grid[1] : this.originalPageY),
                        (j = c ? (e - this.offset.click.top >= c[1] || e - this.offset.click.top > c[3] ? e : e - this.offset.click.top >= c[1] ? e - g.grid[1] : e + g.grid[1]) : e),
                        (f = g.grid[0] ? this.originalPageX + Math.round((i - this.originalPageX) / g.grid[0]) * g.grid[0] : this.originalPageX),
                        (i = c ? (f - this.offset.click.left >= c[0] || f - this.offset.click.left > c[2] ? f : f - this.offset.click.left >= c[0] ? f - g.grid[0] : f + g.grid[0]) : f)),
                    "y" === g.axis && (i = this.originalPageX),
                    "x" === g.axis && (j = this.originalPageY)),
                        {
                            top: j - this.offset.click.top - this.offset.relative.top - this.offset.parent.top + ("fixed" === this.cssPosition ? -this.offset.scroll.top : h ? 0 : this.offset.scroll.top),
                            left: i - this.offset.click.left - this.offset.relative.left - this.offset.parent.left + ("fixed" === this.cssPosition ? -this.offset.scroll.left : h ? 0 : this.offset.scroll.left),
                        }
                );
            },
            _clear: function () {
                this._removeClass(this.helper, "ui-draggable-dragging"),
                this.helper[0] === this.element[0] || this.cancelHelperRemoval || this.helper.remove(),
                    (this.helper = null),
                    (this.cancelHelperRemoval = !1),
                this.destroyOnClear && this.destroy();
            },
            _trigger: function (b, c, d) {
                return (
                    (d = d || this._uiHash()),
                        a.ui.plugin.call(this, b, [c, d, this], !0),
                    /^(drag|start|stop)/.test(b) && ((this.positionAbs = this._convertPositionTo("absolute")), (d.offset = this.positionAbs)),
                        a.Widget.prototype._trigger.call(this, b, c, d)
                );
            },
            plugins: {},
            _uiHash: function () {
                return { helper: this.helper, position: this.position, originalPosition: this.originalPosition, offset: this.positionAbs };
            },
        }),
        a.ui.plugin.add("draggable", "connectToSortable", {
            start: function (b, c, d) {
                var e = a.extend({}, c, { item: d.element });
                (d.sortables = []),
                    a(d.options.connectToSortable).each(function () {
                        var c = a(this).sortable("instance");
                        c && !c.options.disabled && (d.sortables.push(c), c.refreshPositions(), c._trigger("activate", b, e));
                    });
            },
            stop: function (b, c, d) {
                var e = a.extend({}, c, { item: d.element });
                (d.cancelHelperRemoval = !1),
                    a.each(d.sortables, function () {
                        var a = this;
                        a.isOver
                            ? ((a.isOver = 0),
                                (d.cancelHelperRemoval = !0),
                                (a.cancelHelperRemoval = !1),
                                (a._storedCSS = { position: a.placeholder.css("position"), top: a.placeholder.css("top"), left: a.placeholder.css("left") }),
                                a._mouseStop(b),
                                (a.options.helper = a.options._helper))
                            : ((a.cancelHelperRemoval = !0), a._trigger("deactivate", b, e));
                    });
            },
            drag: function (b, c, d) {
                a.each(d.sortables, function () {
                    var e = !1,
                        f = this;
                    (f.positionAbs = d.positionAbs),
                        (f.helperProportions = d.helperProportions),
                        (f.offset.click = d.offset.click),
                    f._intersectsWith(f.containerCache) &&
                    ((e = !0),
                        a.each(d.sortables, function () {
                            return (
                                (this.positionAbs = d.positionAbs),
                                    (this.helperProportions = d.helperProportions),
                                    (this.offset.click = d.offset.click),
                                this !== f && this._intersectsWith(this.containerCache) && a.contains(f.element[0], this.element[0]) && (e = !1),
                                    e
                            );
                        })),
                        e
                            ? (f.isOver ||
                            ((f.isOver = 1),
                                (d._parent = c.helper.parent()),
                                (f.currentItem = c.helper.appendTo(f.element).data("ui-sortable-item", !0)),
                                (f.options._helper = f.options.helper),
                                (f.options.helper = function () {
                                    return c.helper[0];
                                }),
                                (b.target = f.currentItem[0]),
                                f._mouseCapture(b, !0),
                                f._mouseStart(b, !0, !0),
                                (f.offset.click.top = d.offset.click.top),
                                (f.offset.click.left = d.offset.click.left),
                                (f.offset.parent.left -= d.offset.parent.left - f.offset.parent.left),
                                (f.offset.parent.top -= d.offset.parent.top - f.offset.parent.top),
                                d._trigger("toSortable", b),
                                (d.dropped = f.element),
                                a.each(d.sortables, function () {
                                    this.refreshPositions();
                                }),
                                (d.currentItem = d.element),
                                (f.fromOutside = d)),
                            f.currentItem && (f._mouseDrag(b), (c.position = f.position)))
                            : f.isOver &&
                            ((f.isOver = 0),
                                (f.cancelHelperRemoval = !0),
                                (f.options._revert = f.options.revert),
                                (f.options.revert = !1),
                                f._trigger("out", b, f._uiHash(f)),
                                f._mouseStop(b, !0),
                                (f.options.revert = f.options._revert),
                                (f.options.helper = f.options._helper),
                            f.placeholder && f.placeholder.remove(),
                                c.helper.appendTo(d._parent),
                                d._refreshOffsets(b),
                                (c.position = d._generatePosition(b, !0)),
                                d._trigger("fromSortable", b),
                                (d.dropped = !1),
                                a.each(d.sortables, function () {
                                    this.refreshPositions();
                                }));
                });
            },
        }),
        a.ui.plugin.add("draggable", "cursor", {
            start: function (b, c, d) {
                var e = a("body"),
                    f = d.options;
                e.css("cursor") && (f._cursor = e.css("cursor")), e.css("cursor", f.cursor);
            },
            stop: function (b, c, d) {
                var e = d.options;
                e._cursor && a("body").css("cursor", e._cursor);
            },
        }),
        a.ui.plugin.add("draggable", "opacity", {
            start: function (b, c, d) {
                var e = a(c.helper),
                    f = d.options;
                e.css("opacity") && (f._opacity = e.css("opacity")), e.css("opacity", f.opacity);
            },
            stop: function (b, c, d) {
                var e = d.options;
                e._opacity && a(c.helper).css("opacity", e._opacity);
            },
        }),
        a.ui.plugin.add("draggable", "scroll", {
            start: function (a, b, c) {
                c.scrollParentNotHidden || (c.scrollParentNotHidden = c.helper.scrollParent(!1)),
                c.scrollParentNotHidden[0] !== c.document[0] && "HTML" !== c.scrollParentNotHidden[0].tagName && (c.overflowOffset = c.scrollParentNotHidden.offset());
            },
            drag: function (b, c, d) {
                var e = d.options,
                    f = !1,
                    g = d.scrollParentNotHidden[0],
                    h = d.document[0];
                g !== h && "HTML" !== g.tagName
                    ? ((e.axis && "x" === e.axis) ||
                    (d.overflowOffset.top + g.offsetHeight - b.pageY < e.scrollSensitivity
                        ? (g.scrollTop = f = g.scrollTop + e.scrollSpeed)
                        : b.pageY - d.overflowOffset.top < e.scrollSensitivity && (g.scrollTop = f = g.scrollTop - e.scrollSpeed)),
                    (e.axis && "y" === e.axis) ||
                    (d.overflowOffset.left + g.offsetWidth - b.pageX < e.scrollSensitivity
                        ? (g.scrollLeft = f = g.scrollLeft + e.scrollSpeed)
                        : b.pageX - d.overflowOffset.left < e.scrollSensitivity && (g.scrollLeft = f = g.scrollLeft - e.scrollSpeed)))
                    : ((e.axis && "x" === e.axis) ||
                    (b.pageY - a(h).scrollTop() < e.scrollSensitivity
                        ? (f = a(h).scrollTop(a(h).scrollTop() - e.scrollSpeed))
                        : a(window).height() - (b.pageY - a(h).scrollTop()) < e.scrollSensitivity && (f = a(h).scrollTop(a(h).scrollTop() + e.scrollSpeed))),
                    (e.axis && "y" === e.axis) ||
                    (b.pageX - a(h).scrollLeft() < e.scrollSensitivity
                        ? (f = a(h).scrollLeft(a(h).scrollLeft() - e.scrollSpeed))
                        : a(window).width() - (b.pageX - a(h).scrollLeft()) < e.scrollSensitivity && (f = a(h).scrollLeft(a(h).scrollLeft() + e.scrollSpeed)))),
                !1 !== f && a.ui.ddmanager && !e.dropBehaviour && a.ui.ddmanager.prepareOffsets(d, b);
            },
        }),
        a.ui.plugin.add("draggable", "snap", {
            start: function (b, c, d) {
                var e = d.options;
                (d.snapElements = []),
                    a(e.snap.constructor !== String ? e.snap.items || ":data(ui-draggable)" : e.snap).each(function () {
                        var b = a(this),
                            c = b.offset();
                        this !== d.element[0] && d.snapElements.push({ item: this, width: b.outerWidth(), height: b.outerHeight(), top: c.top, left: c.left });
                    });
            },
            drag: function (b, c, d) {
                var e,
                    f,
                    g,
                    h,
                    i,
                    j,
                    k,
                    l,
                    m,
                    n,
                    o = d.options,
                    p = o.snapTolerance,
                    q = c.offset.left,
                    r = q + d.helperProportions.width,
                    s = c.offset.top,
                    t = s + d.helperProportions.height;
                for (m = d.snapElements.length - 1; m >= 0; m--)
                    (i = d.snapElements[m].left - d.margins.left),
                        (j = i + d.snapElements[m].width),
                        (k = d.snapElements[m].top - d.margins.top),
                        (l = k + d.snapElements[m].height),
                        i - p > r || q > j + p || k - p > t || s > l + p || !a.contains(d.snapElements[m].item.ownerDocument, d.snapElements[m].item)
                            ? (d.snapElements[m].snapping && d.options.snap.release && d.options.snap.release.call(d.element, b, a.extend(d._uiHash(), { snapItem: d.snapElements[m].item })), (d.snapElements[m].snapping = !1))
                            : ("inner" !== o.snapMode &&
                            ((e = p >= Math.abs(k - t)),
                                (f = p >= Math.abs(l - s)),
                                (g = p >= Math.abs(i - r)),
                                (h = p >= Math.abs(j - q)),
                            e && (c.position.top = d._convertPositionTo("relative", { top: k - d.helperProportions.height, left: 0 }).top),
                            f && (c.position.top = d._convertPositionTo("relative", { top: l, left: 0 }).top),
                            g && (c.position.left = d._convertPositionTo("relative", { top: 0, left: i - d.helperProportions.width }).left),
                            h && (c.position.left = d._convertPositionTo("relative", { top: 0, left: j }).left)),
                                (n = e || f || g || h),
                            "outer" !== o.snapMode &&
                            ((e = p >= Math.abs(k - s)),
                                (f = p >= Math.abs(l - t)),
                                (g = p >= Math.abs(i - q)),
                                (h = p >= Math.abs(j - r)),
                            e && (c.position.top = d._convertPositionTo("relative", { top: k, left: 0 }).top),
                            f && (c.position.top = d._convertPositionTo("relative", { top: l - d.helperProportions.height, left: 0 }).top),
                            g && (c.position.left = d._convertPositionTo("relative", { top: 0, left: i }).left),
                            h && (c.position.left = d._convertPositionTo("relative", { top: 0, left: j - d.helperProportions.width }).left)),
                            !d.snapElements[m].snapping && (e || f || g || h || n) && d.options.snap.snap && d.options.snap.snap.call(d.element, b, a.extend(d._uiHash(), { snapItem: d.snapElements[m].item })),
                                (d.snapElements[m].snapping = e || f || g || h || n));
            },
        }),
        a.ui.plugin.add("draggable", "stack", {
            start: function (b, c, d) {
                var e,
                    f = d.options,
                    g = a.makeArray(a(f.stack)).sort(function (b, c) {
                        return (parseInt(a(b).css("zIndex"), 10) || 0) - (parseInt(a(c).css("zIndex"), 10) || 0);
                    });
                g.length &&
                ((e = parseInt(a(g[0]).css("zIndex"), 10) || 0),
                    a(g).each(function (b) {
                        a(this).css("zIndex", e + b);
                    }),
                    this.css("zIndex", e + g.length));
            },
        }),
        a.ui.plugin.add("draggable", "zIndex", {
            start: function (b, c, d) {
                var e = a(c.helper),
                    f = d.options;
                e.css("zIndex") && (f._zIndex = e.css("zIndex")), e.css("zIndex", f.zIndex);
            },
            stop: function (b, c, d) {
                var e = d.options;
                e._zIndex && a(c.helper).css("zIndex", e._zIndex);
            },
        }),
        a.ui.draggable,
        a.widget("ui.slider", a.ui.mouse, {
            version: "1.12.1",
            widgetEventPrefix: "slide",
            options: {
                animate: !1,
                classes: { "ui-slider": "ui-corner-all", "ui-slider-handle": "ui-corner-all", "ui-slider-range": "ui-corner-all ui-widget-header" },
                distance: 0,
                max: 100,
                min: 0,
                orientation: "horizontal",
                range: !1,
                step: 1,
                value: 0,
                values: null,
                change: null,
                slide: null,
                start: null,
                stop: null,
            },
            numPages: 5,
            _create: function () {
                (this._keySliding = !1),
                    (this._mouseSliding = !1),
                    (this._animateOff = !0),
                    (this._handleIndex = null),
                    this._detectOrientation(),
                    this._mouseInit(),
                    this._calculateNewMax(),
                    this._addClass("ui-slider ui-slider-" + this.orientation, "ui-widget ui-widget-content"),
                    this._refresh(),
                    (this._animateOff = !1);
            },
            _refresh: function () {
                this._createRange(), this._createHandles(), this._setupEvents(), this._refreshValue();
            },
            _createHandles: function () {
                var b,
                    c,
                    d = this.options,
                    e = this.element.find(".ui-slider-handle"),
                    f = [];
                for (c = (d.values && d.values.length) || 1, e.length > c && (e.slice(c).remove(), (e = e.slice(0, c))), b = e.length; c > b; b++) f.push("<span tabindex='0'></span>");
                (this.handles = e.add(a(f.join("")).appendTo(this.element))),
                    this._addClass(this.handles, "ui-slider-handle", "ui-state-default"),
                    (this.handle = this.handles.eq(0)),
                    this.handles.each(function (b) {
                        a(this).data("ui-slider-handle-index", b).attr("tabIndex", 0);
                    });
            },
            _createRange: function () {
                var b = this.options;
                b.range
                    ? (!0 === b.range &&
                    (b.values ? (b.values.length && 2 !== b.values.length ? (b.values = [b.values[0], b.values[0]]) : a.isArray(b.values) && (b.values = b.values.slice(0))) : (b.values = [this._valueMin(), this._valueMin()])),
                        this.range && this.range.length
                            ? (this._removeClass(this.range, "ui-slider-range-min ui-slider-range-max"), this.range.css({ left: "", bottom: "" }))
                            : ((this.range = a("<div>").appendTo(this.element)), this._addClass(this.range, "ui-slider-range")),
                    ("min" === b.range || "max" === b.range) && this._addClass(this.range, "ui-slider-range-" + b.range))
                    : (this.range && this.range.remove(), (this.range = null));
            },
            _setupEvents: function () {
                this._off(this.handles), this._on(this.handles, this._handleEvents), this._hoverable(this.handles), this._focusable(this.handles);
            },
            _destroy: function () {
                this.handles.remove(), this.range && this.range.remove(), this._mouseDestroy();
            },
            _mouseCapture: function (b) {
                var c,
                    d,
                    e,
                    f,
                    g,
                    h,
                    i,
                    j = this,
                    k = this.options;
                return (
                    !k.disabled &&
                    ((this.elementSize = { width: this.element.outerWidth(), height: this.element.outerHeight() }),
                        (this.elementOffset = this.element.offset()),
                        (c = { x: b.pageX, y: b.pageY }),
                        (d = this._normValueFromMouse(c)),
                        (e = this._valueMax() - this._valueMin() + 1),
                        this.handles.each(function (b) {
                            var c = Math.abs(d - j.values(b));
                            (e > c || (e === c && (b === j._lastChangedValue || j.values(b) === k.min))) && ((e = c), (f = a(this)), (g = b));
                        }),
                    !1 !== this._start(b, g) &&
                    ((this._mouseSliding = !0),
                        (this._handleIndex = g),
                        this._addClass(f, null, "ui-state-active"),
                        f.trigger("focus"),
                        (h = f.offset()),
                        (i = !a(b.target).parents().addBack().is(".ui-slider-handle")),
                        (this._clickOffset = i
                            ? { left: 0, top: 0 }
                            : {
                                left: b.pageX - h.left - f.width() / 2,
                                top: b.pageY - h.top - f.height() / 2 - (parseInt(f.css("borderTopWidth"), 10) || 0) - (parseInt(f.css("borderBottomWidth"), 10) || 0) + (parseInt(f.css("marginTop"), 10) || 0),
                            }),
                    this.handles.hasClass("ui-state-hover") || this._slide(b, g, d),
                        (this._animateOff = !0),
                        !0))
                );
            },
            _mouseStart: function () {
                return !0;
            },
            _mouseDrag: function (a) {
                var b = { x: a.pageX, y: a.pageY },
                    c = this._normValueFromMouse(b);
                return this._slide(a, this._handleIndex, c), !1;
            },
            _mouseStop: function (a) {
                return (
                    this._removeClass(this.handles, null, "ui-state-active"),
                        (this._mouseSliding = !1),
                        this._stop(a, this._handleIndex),
                        this._change(a, this._handleIndex),
                        (this._handleIndex = null),
                        (this._clickOffset = null),
                        (this._animateOff = !1),
                        !1
                );
            },
            _detectOrientation: function () {
                this.orientation = "vertical" === this.options.orientation ? "vertical" : "horizontal";
            },
            _normValueFromMouse: function (a) {
                var b, c, d, e, f;
                return (
                    "horizontal" === this.orientation
                        ? ((b = this.elementSize.width), (c = a.x - this.elementOffset.left - (this._clickOffset ? this._clickOffset.left : 0)))
                        : ((b = this.elementSize.height), (c = a.y - this.elementOffset.top - (this._clickOffset ? this._clickOffset.top : 0))),
                        (d = c / b),
                    d > 1 && (d = 1),
                    0 > d && (d = 0),
                    "vertical" === this.orientation && (d = 1 - d),
                        (e = this._valueMax() - this._valueMin()),
                        (f = this._valueMin() + d * e),
                        this._trimAlignValue(f)
                );
            },
            _uiHash: function (a, b, c) {
                var d = { handle: this.handles[a], handleIndex: a, value: void 0 !== b ? b : this.value() };
                return this._hasMultipleValues() && ((d.value = void 0 !== b ? b : this.values(a)), (d.values = c || this.values())), d;
            },
            _hasMultipleValues: function () {
                return this.options.values && this.options.values.length;
            },
            _start: function (a, b) {
                return this._trigger("start", a, this._uiHash(b));
            },
            _slide: function (a, b, c) {
                var d,
                    e = this.value(),
                    f = this.values();
                this._hasMultipleValues() && ((d = this.values(b ? 0 : 1)), (e = this.values(b)), 2 === this.options.values.length && !0 === this.options.range && (c = 0 === b ? Math.min(d, c) : Math.max(d, c)), (f[b] = c)),
                c !== e && !1 !== this._trigger("slide", a, this._uiHash(b, c, f)) && (this._hasMultipleValues() ? this.values(b, c) : this.value(c));
            },
            _stop: function (a, b) {
                this._trigger("stop", a, this._uiHash(b));
            },
            _change: function (a, b) {
                this._keySliding || this._mouseSliding || ((this._lastChangedValue = b), this._trigger("change", a, this._uiHash(b)));
            },
            value: function (a) {
                return arguments.length ? ((this.options.value = this._trimAlignValue(a)), this._refreshValue(), void this._change(null, 0)) : this._value();
            },
            values: function (b, c) {
                var d, e, f;
                if (arguments.length > 1) return (this.options.values[b] = this._trimAlignValue(c)), this._refreshValue(), void this._change(null, b);
                if (!arguments.length) return this._values();
                if (!a.isArray(arguments[0])) return this._hasMultipleValues() ? this._values(b) : this.value();
                for (d = this.options.values, e = arguments[0], f = 0; d.length > f; f += 1) (d[f] = this._trimAlignValue(e[f])), this._change(null, f);
                this._refreshValue();
            },
            _setOption: function (b, c) {
                var d,
                    e = 0;
                switch (
                    ("range" === b &&
                    !0 === this.options.range &&
                    ("min" === c ? ((this.options.value = this._values(0)), (this.options.values = null)) : "max" === c && ((this.options.value = this._values(this.options.values.length - 1)), (this.options.values = null))),
                    a.isArray(this.options.values) && (e = this.options.values.length),
                        this._super(b, c),
                        b)
                    ) {
                    case "orientation":
                        this._detectOrientation(),
                            this._removeClass("ui-slider-horizontal ui-slider-vertical")._addClass("ui-slider-" + this.orientation),
                            this._refreshValue(),
                        this.options.range && this._refreshRange(c),
                            this.handles.css("horizontal" === c ? "bottom" : "left", "");
                        break;
                    case "value":
                        (this._animateOff = !0), this._refreshValue(), this._change(null, 0), (this._animateOff = !1);
                        break;
                    case "values":
                        for (this._animateOff = !0, this._refreshValue(), d = e - 1; d >= 0; d--) this._change(null, d);
                        this._animateOff = !1;
                        break;
                    case "step":
                    case "min":
                    case "max":
                        (this._animateOff = !0), this._calculateNewMax(), this._refreshValue(), (this._animateOff = !1);
                        break;
                    case "range":
                        (this._animateOff = !0), this._refresh(), (this._animateOff = !1);
                }
            },
            _setOptionDisabled: function (a) {
                this._super(a), this._toggleClass(null, "ui-state-disabled", !!a);
            },
            _value: function () {
                var a = this.options.value;
                return (a = this._trimAlignValue(a));
            },
            _values: function (a) {
                var b, c, d;
                if (arguments.length) return (b = this.options.values[a]), (b = this._trimAlignValue(b));
                if (this._hasMultipleValues()) {
                    for (c = this.options.values.slice(), d = 0; c.length > d; d += 1) c[d] = this._trimAlignValue(c[d]);
                    return c;
                }
                return [];
            },
            _trimAlignValue: function (a) {
                if (this._valueMin() >= a) return this._valueMin();
                if (a >= this._valueMax()) return this._valueMax();
                var b = this.options.step > 0 ? this.options.step : 1,
                    c = (a - this._valueMin()) % b,
                    d = a - c;
                return 2 * Math.abs(c) >= b && (d += c > 0 ? b : -b), parseFloat(d.toFixed(5));
            },
            _calculateNewMax: function () {
                var a = this.options.max,
                    b = this._valueMin(),
                    c = this.options.step;
                (a = Math.round((a - b) / c) * c + b), a > this.options.max && (a -= c), (this.max = parseFloat(a.toFixed(this._precision())));
            },
            _precision: function () {
                var a = this._precisionOf(this.options.step);
                return null !== this.options.min && (a = Math.max(a, this._precisionOf(this.options.min))), a;
            },
            _precisionOf: function (a) {
                var b = "" + a,
                    c = b.indexOf(".");
                return -1 === c ? 0 : b.length - c - 1;
            },
            _valueMin: function () {
                return this.options.min;
            },
            _valueMax: function () {
                return this.max;
            },
            _refreshRange: function (a) {
                "vertical" === a && this.range.css({ width: "", left: "" }), "horizontal" === a && this.range.css({ height: "", bottom: "" });
            },
            _refreshValue: function () {
                var b,
                    c,
                    d,
                    e,
                    f,
                    g = this.options.range,
                    h = this.options,
                    i = this,
                    j = !this._animateOff && h.animate,
                    k = {};
                this._hasMultipleValues()
                    ? this.handles.each(function (d) {
                        (c = ((i.values(d) - i._valueMin()) / (i._valueMax() - i._valueMin())) * 100),
                            (k["horizontal" === i.orientation ? "left" : "bottom"] = c + "%"),
                            a(this).stop(1, 1)[j ? "animate" : "css"](k, h.animate),
                        !0 === i.options.range &&
                        ("horizontal" === i.orientation
                            ? (0 === d && i.range.stop(1, 1)[j ? "animate" : "css"]({ left: c + "%" }, h.animate), 1 === d && i.range[j ? "animate" : "css"]({ width: c - b + "%" }, { queue: !1, duration: h.animate }))
                            : (0 === d && i.range.stop(1, 1)[j ? "animate" : "css"]({ bottom: c + "%" }, h.animate), 1 === d && i.range[j ? "animate" : "css"]({ height: c - b + "%" }, { queue: !1, duration: h.animate }))),
                            (b = c);
                    })
                    : ((d = this.value()),
                        (e = this._valueMin()),
                        (f = this._valueMax()),
                        (c = f !== e ? ((d - e) / (f - e)) * 100 : 0),
                        (k["horizontal" === this.orientation ? "left" : "bottom"] = c + "%"),
                        this.handle.stop(1, 1)[j ? "animate" : "css"](k, h.animate),
                    "min" === g && "horizontal" === this.orientation && this.range.stop(1, 1)[j ? "animate" : "css"]({ width: c + "%" }, h.animate),
                    "max" === g && "horizontal" === this.orientation && this.range.stop(1, 1)[j ? "animate" : "css"]({ width: 100 - c + "%" }, h.animate),
                    "min" === g && "vertical" === this.orientation && this.range.stop(1, 1)[j ? "animate" : "css"]({ height: c + "%" }, h.animate),
                    "max" === g && "vertical" === this.orientation && this.range.stop(1, 1)[j ? "animate" : "css"]({ height: 100 - c + "%" }, h.animate));
            },
            _handleEvents: {
                keydown: function (b) {
                    var c,
                        d,
                        e,
                        f = a(b.target).data("ui-slider-handle-index");
                    switch (b.keyCode) {
                        case a.ui.keyCode.HOME:
                        case a.ui.keyCode.END:
                        case a.ui.keyCode.PAGE_UP:
                        case a.ui.keyCode.PAGE_DOWN:
                        case a.ui.keyCode.UP:
                        case a.ui.keyCode.RIGHT:
                        case a.ui.keyCode.DOWN:
                        case a.ui.keyCode.LEFT:
                            if ((b.preventDefault(), !this._keySliding && ((this._keySliding = !0), this._addClass(a(b.target), null, "ui-state-active"), !1 === this._start(b, f)))) return;
                    }
                    switch (((e = this.options.step), (c = d = this._hasMultipleValues() ? this.values(f) : this.value()), b.keyCode)) {
                        case a.ui.keyCode.HOME:
                            d = this._valueMin();
                            break;
                        case a.ui.keyCode.END:
                            d = this._valueMax();
                            break;
                        case a.ui.keyCode.PAGE_UP:
                            d = this._trimAlignValue(c + (this._valueMax() - this._valueMin()) / this.numPages);
                            break;
                        case a.ui.keyCode.PAGE_DOWN:
                            d = this._trimAlignValue(c - (this._valueMax() - this._valueMin()) / this.numPages);
                            break;
                        case a.ui.keyCode.UP:
                        case a.ui.keyCode.RIGHT:
                            if (c === this._valueMax()) return;
                            d = this._trimAlignValue(c + e);
                            break;
                        case a.ui.keyCode.DOWN:
                        case a.ui.keyCode.LEFT:
                            if (c === this._valueMin()) return;
                            d = this._trimAlignValue(c - e);
                    }
                    this._slide(b, f, d);
                },
                keyup: function (b) {
                    var c = a(b.target).data("ui-slider-handle-index");
                    this._keySliding && ((this._keySliding = !1), this._stop(b, c), this._change(b, c), this._removeClass(a(b.target), null, "ui-state-active"));
                },
            },
        });
}),
    (function (a) {
        "use strict";
        "function" == typeof define && define.amd ? define(["jquery"], a) : "undefined" != typeof exports ? (module.exports = a(require("jquery"))) : a(jQuery);
    })(function (a) {
        "use strict";
        var b = window.Slick || {};
        (b = (function () {
            function b(b, d) {
                var e,
                    f = this;
                (f.defaults = {
                    accessibility: !0,
                    adaptiveHeight: !1,
                    appendArrows: a(b),
                    appendDots: a(b),
                    arrows: !0,
                    asNavFor: null,
                    prevArrow: '<button type="button" data-role="none" class="slick-prev" aria-label="Previous" tabindex="0" role="button">Previous</button>',
                    nextArrow: '<button type="button" data-role="none" class="slick-next" aria-label="Next" tabindex="0" role="button">Next</button>',
                    autoplay: !1,
                    autoplaySpeed: 3e3,
                    centerMode: !1,
                    centerPadding: "50px",
                    cssEase: "ease",
                    customPaging: function (b, c) {
                        return a('<button type="button" data-role="none" role="button" tabindex="0" />').text(c + 1);
                    },
                    dots: !1,
                    dotsClass: "slick-dots",
                    draggable: !0,
                    easing: "linear",
                    edgeFriction: 0.35,
                    fade: !1,
                    focusOnSelect: !1,
                    infinite: !0,
                    initialSlide: 0,
                    lazyLoad: "ondemand",
                    mobileFirst: !1,
                    pauseOnHover: !0,
                    pauseOnFocus: !0,
                    pauseOnDotsHover: !1,
                    respondTo: "window",
                    responsive: null,
                    rows: 1,
                    rtl: !1,
                    slide: "",
                    slidesPerRow: 1,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    speed: 500,
                    swipe: !0,
                    swipeToSlide: !1,
                    touchMove: !0,
                    touchThreshold: 5,
                    useCSS: !0,
                    useTransform: !0,
                    variableWidth: !1,
                    vertical: !1,
                    verticalSwiping: !1,
                    waitForAnimate: !0,
                    zIndex: 1e3,
                }),
                    (f.initials = {
                        animating: !1,
                        dragging: !1,
                        autoPlayTimer: null,
                        currentDirection: 0,
                        currentLeft: null,
                        currentSlide: 0,
                        direction: 1,
                        $dots: null,
                        listWidth: null,
                        listHeight: null,
                        loadIndex: 0,
                        $nextArrow: null,
                        $prevArrow: null,
                        slideCount: null,
                        slideWidth: null,
                        $slideTrack: null,
                        $slides: null,
                        sliding: !1,
                        slideOffset: 0,
                        swipeLeft: null,
                        $list: null,
                        touchObject: {},
                        transformsEnabled: !1,
                        unslicked: !1,
                    }),
                    a.extend(f, f.initials),
                    (f.activeBreakpoint = null),
                    (f.animType = null),
                    (f.animProp = null),
                    (f.breakpoints = []),
                    (f.breakpointSettings = []),
                    (f.cssTransitions = !1),
                    (f.focussed = !1),
                    (f.interrupted = !1),
                    (f.hidden = "hidden"),
                    (f.paused = !0),
                    (f.positionProp = null),
                    (f.respondTo = null),
                    (f.rowCount = 1),
                    (f.shouldClick = !0),
                    (f.$slider = a(b)),
                    (f.$slidesCache = null),
                    (f.transformType = null),
                    (f.transitionType = null),
                    (f.visibilityChange = "visibilitychange"),
                    (f.windowWidth = 0),
                    (f.windowTimer = null),
                    (e = a(b).data("slick") || {}),
                    (f.options = a.extend({}, f.defaults, d, e)),
                    (f.currentSlide = f.options.initialSlide),
                    (f.originalSettings = f.options),
                    void 0 !== document.mozHidden
                        ? ((f.hidden = "mozHidden"), (f.visibilityChange = "mozvisibilitychange"))
                        : void 0 !== document.webkitHidden && ((f.hidden = "webkitHidden"), (f.visibilityChange = "webkitvisibilitychange")),
                    (f.autoPlay = a.proxy(f.autoPlay, f)),
                    (f.autoPlayClear = a.proxy(f.autoPlayClear, f)),
                    (f.autoPlayIterator = a.proxy(f.autoPlayIterator, f)),
                    (f.changeSlide = a.proxy(f.changeSlide, f)),
                    (f.clickHandler = a.proxy(f.clickHandler, f)),
                    (f.selectHandler = a.proxy(f.selectHandler, f)),
                    (f.setPosition = a.proxy(f.setPosition, f)),
                    (f.swipeHandler = a.proxy(f.swipeHandler, f)),
                    (f.dragHandler = a.proxy(f.dragHandler, f)),
                    (f.keyHandler = a.proxy(f.keyHandler, f)),
                    (f.instanceUid = c++),
                    (f.htmlExpr = /^(?:\s*(<[\w\W]+>)[^>]*)$/),
                    f.registerBreakpoints(),
                    f.init(!0);
            }
            var c = 0;
            return b;
        })()),
            (b.prototype.activateADA = function () {
                this.$slideTrack.find(".slick-active").attr({ "aria-hidden": "false" }).find("a, input, button, select").attr({ tabindex: "0" });
            }),
            (b.prototype.addSlide = b.prototype.slickAdd = function (b, c, d) {
                var e = this;
                if ("boolean" == typeof c) (d = c), (c = null);
                else if (0 > c || c >= e.slideCount) return !1;
                e.unload(),
                    "number" == typeof c
                        ? 0 === c && 0 === e.$slides.length
                            ? a(b).appendTo(e.$slideTrack)
                            : d
                                ? a(b).insertBefore(e.$slides.eq(c))
                                : a(b).insertAfter(e.$slides.eq(c))
                        : !0 === d
                            ? a(b).prependTo(e.$slideTrack)
                            : a(b).appendTo(e.$slideTrack),
                    (e.$slides = e.$slideTrack.children(this.options.slide)),
                    e.$slideTrack.children(this.options.slide).detach(),
                    e.$slideTrack.append(e.$slides),
                    e.$slides.each(function (b, c) {
                        a(c).attr("data-slick-index", b);
                    }),
                    (e.$slidesCache = e.$slides),
                    e.reinit();
            }),
            (b.prototype.animateHeight = function () {
                var a = this;
                if (1 === a.options.slidesToShow && !0 === a.options.adaptiveHeight && !1 === a.options.vertical) {
                    var b = a.$slides.eq(a.currentSlide).outerHeight(!0);
                    a.$list.animate({ height: b }, a.options.speed);
                }
            }),
            (b.prototype.animateSlide = function (b, c) {
                var d = {},
                    e = this;
                e.animateHeight(),
                !0 === e.options.rtl && !1 === e.options.vertical && (b = -b),
                    !1 === e.transformsEnabled
                        ? !1 === e.options.vertical
                            ? e.$slideTrack.animate({ left: b }, e.options.speed, e.options.easing, c)
                            : e.$slideTrack.animate({ top: b }, e.options.speed, e.options.easing, c)
                        : !1 === e.cssTransitions
                            ? (!0 === e.options.rtl && (e.currentLeft = -e.currentLeft),
                                a({ animStart: e.currentLeft }).animate(
                                    { animStart: b },
                                    {
                                        duration: e.options.speed,
                                        easing: e.options.easing,
                                        step: function (a) {
                                            (a = Math.ceil(a)), !1 === e.options.vertical ? ((d[e.animType] = "translate(" + a + "px, 0px)"), e.$slideTrack.css(d)) : ((d[e.animType] = "translate(0px," + a + "px)"), e.$slideTrack.css(d));
                                        },
                                        complete: function () {
                                            c && c.call();
                                        },
                                    }
                                ))
                            : (e.applyTransition(),
                                (b = Math.ceil(b)),
                                !1 === e.options.vertical ? (d[e.animType] = "translate3d(" + b + "px, 0px, 0px)") : (d[e.animType] = "translate3d(0px," + b + "px, 0px)"),
                                e.$slideTrack.css(d),
                            c &&
                            setTimeout(function () {
                                e.disableTransition(), c.call();
                            }, e.options.speed));
            }),
            (b.prototype.getNavTarget = function () {
                var b = this,
                    c = b.options.asNavFor;
                return c && null !== c && (c = a(c).not(b.$slider)), c;
            }),
            (b.prototype.asNavFor = function (b) {
                var c = this,
                    d = c.getNavTarget();
                null !== d &&
                "object" == typeof d &&
                d.each(function () {
                    var c = a(this).slick("getSlick");
                    c.unslicked || c.slideHandler(b, !0);
                });
            }),
            (b.prototype.applyTransition = function (a) {
                var b = this,
                    c = {};
                !1 === b.options.fade ? (c[b.transitionType] = b.transformType + " " + b.options.speed + "ms " + b.options.cssEase) : (c[b.transitionType] = "opacity " + b.options.speed + "ms " + b.options.cssEase),
                    !1 === b.options.fade ? b.$slideTrack.css(c) : b.$slides.eq(a).css(c);
            }),
            (b.prototype.autoPlay = function () {
                var a = this;
                a.autoPlayClear(), a.slideCount > a.options.slidesToShow && (a.autoPlayTimer = setInterval(a.autoPlayIterator, a.options.autoplaySpeed));
            }),
            (b.prototype.autoPlayClear = function () {
                var a = this;
                a.autoPlayTimer && clearInterval(a.autoPlayTimer);
            }),
            (b.prototype.autoPlayIterator = function () {
                var a = this,
                    b = a.currentSlide + a.options.slidesToScroll;
                a.paused ||
                a.interrupted ||
                a.focussed ||
                (!1 === a.options.infinite &&
                (1 === a.direction && a.currentSlide + 1 === a.slideCount - 1 ? (a.direction = 0) : 0 === a.direction && ((b = a.currentSlide - a.options.slidesToScroll), a.currentSlide - 1 == 0 && (a.direction = 1))),
                    a.slideHandler(b));
            }),
            (b.prototype.buildArrows = function () {
                var b = this;
                !0 === b.options.arrows &&
                ((b.$prevArrow = a(b.options.prevArrow).addClass("slick-arrow")),
                    (b.$nextArrow = a(b.options.nextArrow).addClass("slick-arrow")),
                    b.slideCount > b.options.slidesToShow
                        ? (b.$prevArrow.removeClass("slick-hidden").removeAttr("aria-hidden tabindex"),
                            b.$nextArrow.removeClass("slick-hidden").removeAttr("aria-hidden tabindex"),
                        b.htmlExpr.test(b.options.prevArrow) && b.$prevArrow.prependTo(b.options.appendArrows),
                        b.htmlExpr.test(b.options.nextArrow) && b.$nextArrow.appendTo(b.options.appendArrows),
                        !0 !== b.options.infinite && b.$prevArrow.addClass("slick-disabled").attr("aria-disabled", "true"))
                        : b.$prevArrow.add(b.$nextArrow).addClass("slick-hidden").attr({ "aria-disabled": "true", tabindex: "-1" }));
            }),
            (b.prototype.buildDots = function () {
                var b,
                    c,
                    d = this;
                if (!0 === d.options.dots && d.slideCount > d.options.slidesToShow) {
                    for (d.$slider.addClass("slick-dotted"), c = a("<ul />").addClass(d.options.dotsClass), b = 0; b <= d.getDotCount(); b += 1) c.append(a("<li />").append(d.options.customPaging.call(this, d, b)));
                    (d.$dots = c.appendTo(d.options.appendDots)), d.$dots.find("li").first().addClass("slick-active").attr("aria-hidden", "false");
                }
            }),
            (b.prototype.buildOut = function () {
                var b = this;
                (b.$slides = b.$slider.children(b.options.slide + ":not(.slick-cloned)").addClass("slick-slide")),
                    (b.slideCount = b.$slides.length),
                    b.$slides.each(function (b, c) {
                        a(c)
                            .attr("data-slick-index", b)
                            .data("originalStyling", a(c).attr("style") || "");
                    }),
                    b.$slider.addClass("slick-slider"),
                    (b.$slideTrack = 0 === b.slideCount ? a('<div class="slick-track"/>').appendTo(b.$slider) : b.$slides.wrapAll('<div class="slick-track"/>').parent()),
                    (b.$list = b.$slideTrack.wrap('<div aria-live="polite" class="slick-list"/>').parent()),
                    b.$slideTrack.css("opacity", 0),
                (!0 === b.options.centerMode || !0 === b.options.swipeToSlide) && (b.options.slidesToScroll = 1),
                    a("img[data-lazy]", b.$slider).not("[src]").addClass("slick-loading"),
                    b.setupInfinite(),
                    b.buildArrows(),
                    b.buildDots(),
                    b.updateDots(),
                    b.setSlideClasses("number" == typeof b.currentSlide ? b.currentSlide : 0),
                !0 === b.options.draggable && b.$list.addClass("draggable");
            }),
            (b.prototype.buildRows = function () {
                var a,
                    b,
                    c,
                    d,
                    e,
                    f,
                    g,
                    h = this;
                if (((d = document.createDocumentFragment()), (f = h.$slider.children()), h.options.rows > 1)) {
                    for (g = h.options.slidesPerRow * h.options.rows, e = Math.ceil(f.length / g), a = 0; e > a; a++) {
                        var i = document.createElement("div");
                        for (b = 0; b < h.options.rows; b++) {
                            var j = document.createElement("div");
                            for (c = 0; c < h.options.slidesPerRow; c++) {
                                var k = a * g + (b * h.options.slidesPerRow + c);
                                f.get(k) && j.appendChild(f.get(k));
                            }
                            i.appendChild(j);
                        }
                        d.appendChild(i);
                    }
                    h.$slider.empty().append(d),
                        h.$slider
                            .children()
                            .children()
                            .children()
                            .css({ width: 100 / h.options.slidesPerRow + "%", display: "inline-block" });
                }
            }),
            (b.prototype.checkResponsive = function (b, c) {
                var d,
                    e,
                    f,
                    g = this,
                    h = !1,
                    i = g.$slider.width(),
                    j = window.innerWidth || a(window).width();
                if (("window" === g.respondTo ? (f = j) : "slider" === g.respondTo ? (f = i) : "min" === g.respondTo && (f = Math.min(j, i)), g.options.responsive && g.options.responsive.length && null !== g.options.responsive)) {
                    e = null;
                    for (d in g.breakpoints) g.breakpoints.hasOwnProperty(d) && (!1 === g.originalSettings.mobileFirst ? f < g.breakpoints[d] && (e = g.breakpoints[d]) : f > g.breakpoints[d] && (e = g.breakpoints[d]));
                    null !== e
                        ? null !== g.activeBreakpoint
                            ? (e !== g.activeBreakpoint || c) &&
                            ((g.activeBreakpoint = e),
                                "unslick" === g.breakpointSettings[e] ? g.unslick(e) : ((g.options = a.extend({}, g.originalSettings, g.breakpointSettings[e])), !0 === b && (g.currentSlide = g.options.initialSlide), g.refresh(b)),
                                (h = e))
                            : ((g.activeBreakpoint = e),
                                "unslick" === g.breakpointSettings[e] ? g.unslick(e) : ((g.options = a.extend({}, g.originalSettings, g.breakpointSettings[e])), !0 === b && (g.currentSlide = g.options.initialSlide), g.refresh(b)),
                                (h = e))
                        : null !== g.activeBreakpoint && ((g.activeBreakpoint = null), (g.options = g.originalSettings), !0 === b && (g.currentSlide = g.options.initialSlide), g.refresh(b), (h = e)),
                    b || !1 === h || g.$slider.trigger("breakpoint", [g, h]);
                }
            }),
            (b.prototype.changeSlide = function (b, c) {
                var d,
                    e,
                    f,
                    g = this,
                    h = a(b.currentTarget);
                switch ((h.is("a") && b.preventDefault(), h.is("li") || (h = h.closest("li")), (f = g.slideCount % g.options.slidesToScroll != 0), (d = f ? 0 : (g.slideCount - g.currentSlide) % g.options.slidesToScroll), b.data.message)) {
                    case "previous":
                        (e = 0 === d ? g.options.slidesToScroll : g.options.slidesToShow - d), g.slideCount > g.options.slidesToShow && g.slideHandler(g.currentSlide - e, !1, c);
                        break;
                    case "next":
                        (e = 0 === d ? g.options.slidesToScroll : d), g.slideCount > g.options.slidesToShow && g.slideHandler(g.currentSlide + e, !1, c);
                        break;
                    case "index":
                        var i = 0 === b.data.index ? 0 : b.data.index || h.index() * g.options.slidesToScroll;
                        g.slideHandler(g.checkNavigable(i), !1, c), h.children().trigger("focus");
                        break;
                    default:
                        return;
                }
            }),
            (b.prototype.checkNavigable = function (a) {
                var b, c;
                if (((b = this.getNavigableIndexes()), (c = 0), a > b[b.length - 1])) a = b[b.length - 1];
                else
                    for (var d in b) {
                        if (a < b[d]) {
                            a = c;
                            break;
                        }
                        c = b[d];
                    }
                return a;
            }),
            (b.prototype.cleanUpEvents = function () {
                var b = this;
                b.options.dots && null !== b.$dots && a("li", b.$dots).off("click.slick", b.changeSlide).off("mouseenter.slick", a.proxy(b.interrupt, b, !0)).off("mouseleave.slick", a.proxy(b.interrupt, b, !1)),
                    b.$slider.off("focus.slick blur.slick"),
                !0 === b.options.arrows && b.slideCount > b.options.slidesToShow && (b.$prevArrow && b.$prevArrow.off("click.slick", b.changeSlide), b.$nextArrow && b.$nextArrow.off("click.slick", b.changeSlide)),
                    b.$list.off("touchstart.slick mousedown.slick", b.swipeHandler),
                    b.$list.off("touchmove.slick mousemove.slick", b.swipeHandler),
                    b.$list.off("touchend.slick mouseup.slick", b.swipeHandler),
                    b.$list.off("touchcancel.slick mouseleave.slick", b.swipeHandler),
                    b.$list.off("click.slick", b.clickHandler),
                    a(document).off(b.visibilityChange, b.visibility),
                    b.cleanUpSlideEvents(),
                !0 === b.options.accessibility && b.$list.off("keydown.slick", b.keyHandler),
                !0 === b.options.focusOnSelect && a(b.$slideTrack).children().off("click.slick", b.selectHandler),
                    a(window).off("orientationchange.slick.slick-" + b.instanceUid, b.orientationChange),
                    a(window).off("resize.slick.slick-" + b.instanceUid, b.resize),
                    a("[draggable!=true]", b.$slideTrack).off("dragstart", b.preventDefault),
                    a(window).off("load.slick.slick-" + b.instanceUid, b.setPosition),
                    a(document).off("ready.slick.slick-" + b.instanceUid, b.setPosition);
            }),
            (b.prototype.cleanUpSlideEvents = function () {
                var b = this;
                b.$list.off("mouseenter.slick", a.proxy(b.interrupt, b, !0)), b.$list.off("mouseleave.slick", a.proxy(b.interrupt, b, !1));
            }),
            (b.prototype.cleanUpRows = function () {
                var a,
                    b = this;
                b.options.rows > 1 && ((a = b.$slides.children().children()), a.removeAttr("style"), b.$slider.empty().append(a));
            }),
            (b.prototype.clickHandler = function (a) {
                !1 === this.shouldClick && (a.stopImmediatePropagation(), a.stopPropagation(), a.preventDefault());
            }),
            (b.prototype.destroy = function (b) {
                var c = this;
                c.autoPlayClear(),
                    (c.touchObject = {}),
                    c.cleanUpEvents(),
                    a(".slick-cloned", c.$slider).detach(),
                c.$dots && c.$dots.remove(),
                c.$prevArrow &&
                c.$prevArrow.length &&
                (c.$prevArrow.removeClass("slick-disabled slick-arrow slick-hidden").removeAttr("aria-hidden aria-disabled tabindex").css("display", ""), c.htmlExpr.test(c.options.prevArrow) && c.$prevArrow.remove()),
                c.$nextArrow &&
                c.$nextArrow.length &&
                (c.$nextArrow.removeClass("slick-disabled slick-arrow slick-hidden").removeAttr("aria-hidden aria-disabled tabindex").css("display", ""), c.htmlExpr.test(c.options.nextArrow) && c.$nextArrow.remove()),
                c.$slides &&
                (c.$slides
                    .removeClass("slick-slide slick-active slick-center slick-visible slick-current")
                    .removeAttr("aria-hidden")
                    .removeAttr("data-slick-index")
                    .each(function () {
                        a(this).attr("style", a(this).data("originalStyling"));
                    }),
                    c.$slideTrack.children(this.options.slide).detach(),
                    c.$slideTrack.detach(),
                    c.$list.detach(),
                    c.$slider.append(c.$slides)),
                    c.cleanUpRows(),
                    c.$slider.removeClass("slick-slider"),
                    c.$slider.removeClass("slick-initialized"),
                    c.$slider.removeClass("slick-dotted"),
                    (c.unslicked = !0),
                b || c.$slider.trigger("destroy", [c]);
            }),
            (b.prototype.disableTransition = function (a) {
                var b = this,
                    c = {};
                (c[b.transitionType] = ""), !1 === b.options.fade ? b.$slideTrack.css(c) : b.$slides.eq(a).css(c);
            }),
            (b.prototype.fadeSlide = function (a, b) {
                var c = this;
                !1 === c.cssTransitions
                    ? (c.$slides.eq(a).css({ zIndex: c.options.zIndex }), c.$slides.eq(a).animate({ opacity: 1 }, c.options.speed, c.options.easing, b))
                    : (c.applyTransition(a),
                        c.$slides.eq(a).css({ opacity: 1, zIndex: c.options.zIndex }),
                    b &&
                    setTimeout(function () {
                        c.disableTransition(a), b.call();
                    }, c.options.speed));
            }),
            (b.prototype.fadeSlideOut = function (a) {
                var b = this;
                !1 === b.cssTransitions ? b.$slides.eq(a).animate({ opacity: 0, zIndex: b.options.zIndex - 2 }, b.options.speed, b.options.easing) : (b.applyTransition(a), b.$slides.eq(a).css({ opacity: 0, zIndex: b.options.zIndex - 2 }));
            }),
            (b.prototype.filterSlides = b.prototype.slickFilter = function (a) {
                var b = this;
                null !== a && ((b.$slidesCache = b.$slides), b.unload(), b.$slideTrack.children(this.options.slide).detach(), b.$slidesCache.filter(a).appendTo(b.$slideTrack), b.reinit());
            }),
            (b.prototype.focusHandler = function () {
                var b = this;
                b.$slider.off("focus.slick blur.slick").on("focus.slick blur.slick", "*:not(.slick-arrow)", function (c) {
                    c.stopImmediatePropagation();
                    var d = a(this);
                    setTimeout(function () {
                        b.options.pauseOnFocus && ((b.focussed = d.is(":focus")), b.autoPlay());
                    }, 0);
                });
            }),
            (b.prototype.getCurrent = b.prototype.slickCurrentSlide = function () {
                return this.currentSlide;
            }),
            (b.prototype.getDotCount = function () {
                var a = this,
                    b = 0,
                    c = 0,
                    d = 0;
                if (!0 === a.options.infinite) for (; b < a.slideCount; ) ++d, (b = c + a.options.slidesToScroll), (c += a.options.slidesToScroll <= a.options.slidesToShow ? a.options.slidesToScroll : a.options.slidesToShow);
                else if (!0 === a.options.centerMode) d = a.slideCount;
                else if (a.options.asNavFor) for (; b < a.slideCount; ) ++d, (b = c + a.options.slidesToScroll), (c += a.options.slidesToScroll <= a.options.slidesToShow ? a.options.slidesToScroll : a.options.slidesToShow);
                else d = 1 + Math.ceil((a.slideCount - a.options.slidesToShow) / a.options.slidesToScroll);
                return d - 1;
            }),
            (b.prototype.getLeft = function (a) {
                var b,
                    c,
                    d,
                    e = this,
                    f = 0;
                return (
                    (e.slideOffset = 0),
                        (c = e.$slides.first().outerHeight(!0)),
                        !0 === e.options.infinite
                            ? (e.slideCount > e.options.slidesToShow && ((e.slideOffset = e.slideWidth * e.options.slidesToShow * -1), (f = c * e.options.slidesToShow * -1)),
                            e.slideCount % e.options.slidesToScroll != 0 &&
                            a + e.options.slidesToScroll > e.slideCount &&
                            e.slideCount > e.options.slidesToShow &&
                            (a > e.slideCount
                                ? ((e.slideOffset = (e.options.slidesToShow - (a - e.slideCount)) * e.slideWidth * -1), (f = (e.options.slidesToShow - (a - e.slideCount)) * c * -1))
                                : ((e.slideOffset = (e.slideCount % e.options.slidesToScroll) * e.slideWidth * -1), (f = (e.slideCount % e.options.slidesToScroll) * c * -1))))
                            : a + e.options.slidesToShow > e.slideCount && ((e.slideOffset = (a + e.options.slidesToShow - e.slideCount) * e.slideWidth), (f = (a + e.options.slidesToShow - e.slideCount) * c)),
                    e.slideCount <= e.options.slidesToShow && ((e.slideOffset = 0), (f = 0)),
                        !0 === e.options.centerMode && !0 === e.options.infinite
                            ? (e.slideOffset += e.slideWidth * Math.floor(e.options.slidesToShow / 2) - e.slideWidth)
                            : !0 === e.options.centerMode && ((e.slideOffset = 0), (e.slideOffset += e.slideWidth * Math.floor(e.options.slidesToShow / 2))),
                        (b = !1 === e.options.vertical ? a * e.slideWidth * -1 + e.slideOffset : a * c * -1 + f),
                    !0 === e.options.variableWidth &&
                    ((d = e.slideCount <= e.options.slidesToShow || !1 === e.options.infinite ? e.$slideTrack.children(".slick-slide").eq(a) : e.$slideTrack.children(".slick-slide").eq(a + e.options.slidesToShow)),
                        (b = !0 === e.options.rtl ? (d[0] ? -1 * (e.$slideTrack.width() - d[0].offsetLeft - d.width()) : 0) : d[0] ? -1 * d[0].offsetLeft : 0),
                    !0 === e.options.centerMode &&
                    ((d = e.slideCount <= e.options.slidesToShow || !1 === e.options.infinite ? e.$slideTrack.children(".slick-slide").eq(a) : e.$slideTrack.children(".slick-slide").eq(a + e.options.slidesToShow + 1)),
                        (b = !0 === e.options.rtl ? (d[0] ? -1 * (e.$slideTrack.width() - d[0].offsetLeft - d.width()) : 0) : d[0] ? -1 * d[0].offsetLeft : 0),
                        (b += (e.$list.width() - d.outerWidth()) / 2))),
                        b
                );
            }),
            (b.prototype.getOption = b.prototype.slickGetOption = function (a) {
                return this.options[a];
            }),
            (b.prototype.getNavigableIndexes = function () {
                var a,
                    b = this,
                    c = 0,
                    d = 0,
                    e = [];
                for (!1 === b.options.infinite ? (a = b.slideCount) : ((c = -1 * b.options.slidesToScroll), (d = -1 * b.options.slidesToScroll), (a = 2 * b.slideCount)); a > c; )
                    e.push(c), (c = d + b.options.slidesToScroll), (d += b.options.slidesToScroll <= b.options.slidesToShow ? b.options.slidesToScroll : b.options.slidesToShow);
                return e;
            }),
            (b.prototype.getSlick = function () {
                return this;
            }),
            (b.prototype.getSlideCount = function () {
                var b,
                    c,
                    d = this;
                return (
                    (c = !0 === d.options.centerMode ? d.slideWidth * Math.floor(d.options.slidesToShow / 2) : 0),
                        !0 === d.options.swipeToSlide
                            ? (d.$slideTrack.find(".slick-slide").each(function (e, f) {
                                return f.offsetLeft - c + a(f).outerWidth() / 2 > -1 * d.swipeLeft ? ((b = f), !1) : void 0;
                            }),
                            Math.abs(a(b).attr("data-slick-index") - d.currentSlide) || 1)
                            : d.options.slidesToScroll
                );
            }),
            (b.prototype.goTo = b.prototype.slickGoTo = function (a, b) {
                this.changeSlide({ data: { message: "index", index: parseInt(a) } }, b);
            }),
            (b.prototype.init = function (b) {
                var c = this;
                a(c.$slider).hasClass("slick-initialized") ||
                (a(c.$slider).addClass("slick-initialized"), c.buildRows(), c.buildOut(), c.setProps(), c.startLoad(), c.loadSlider(), c.initializeEvents(), c.updateArrows(), c.updateDots(), c.checkResponsive(!0), c.focusHandler()),
                b && c.$slider.trigger("init", [c]),
                !0 === c.options.accessibility && c.initADA(),
                c.options.autoplay && ((c.paused = !1), c.autoPlay());
            }),
            (b.prototype.initADA = function () {
                var b = this;
                b.$slides.add(b.$slideTrack.find(".slick-cloned")).attr({ "aria-hidden": "true", tabindex: "-1" }).find("a, input, button, select").attr({ tabindex: "-1" }),
                    b.$slideTrack.attr("role", "listbox"),
                    b.$slides.not(b.$slideTrack.find(".slick-cloned")).each(function (c) {
                        a(this).attr({ role: "option", "aria-describedby": "slick-slide" + b.instanceUid + c });
                    }),
                null !== b.$dots &&
                b.$dots
                    .attr("role", "tablist")
                    .find("li")
                    .each(function (c) {
                        a(this).attr({ role: "presentation", "aria-selected": "false", "aria-controls": "navigation" + b.instanceUid + c, id: "slick-slide" + b.instanceUid + c });
                    })
                    .first()
                    .attr("aria-selected", "true")
                    .end()
                    .find("button")
                    .attr("role", "button")
                    .end()
                    .closest("div")
                    .attr("role", "toolbar"),
                    b.activateADA();
            }),
            (b.prototype.initArrowEvents = function () {
                var a = this;
                !0 === a.options.arrows &&
                a.slideCount > a.options.slidesToShow &&
                (a.$prevArrow.off("click.slick").on("click.slick", { message: "previous" }, a.changeSlide), a.$nextArrow.off("click.slick").on("click.slick", { message: "next" }, a.changeSlide));
            }),
            (b.prototype.initDotEvents = function () {
                var b = this;
                !0 === b.options.dots && b.slideCount > b.options.slidesToShow && a("li", b.$dots).on("click.slick", { message: "index" }, b.changeSlide),
                !0 === b.options.dots && !0 === b.options.pauseOnDotsHover && a("li", b.$dots).on("mouseenter.slick", a.proxy(b.interrupt, b, !0)).on("mouseleave.slick", a.proxy(b.interrupt, b, !1));
            }),
            (b.prototype.initSlideEvents = function () {
                var b = this;
                b.options.pauseOnHover && (b.$list.on("mouseenter.slick", a.proxy(b.interrupt, b, !0)), b.$list.on("mouseleave.slick", a.proxy(b.interrupt, b, !1)));
            }),
            (b.prototype.initializeEvents = function () {
                var b = this;
                b.initArrowEvents(),
                    b.initDotEvents(),
                    b.initSlideEvents(),
                    b.$list.on("touchstart.slick mousedown.slick", { action: "start" }, b.swipeHandler),
                    b.$list.on("touchmove.slick mousemove.slick", { action: "move" }, b.swipeHandler),
                    b.$list.on("touchend.slick mouseup.slick", { action: "end" }, b.swipeHandler),
                    b.$list.on("touchcancel.slick mouseleave.slick", { action: "end" }, b.swipeHandler),
                    b.$list.on("click.slick", b.clickHandler),
                    a(document).on(b.visibilityChange, a.proxy(b.visibility, b)),
                !0 === b.options.accessibility && b.$list.on("keydown.slick", b.keyHandler),
                !0 === b.options.focusOnSelect && a(b.$slideTrack).children().on("click.slick", b.selectHandler),
                    a(window).on("orientationchange.slick.slick-" + b.instanceUid, a.proxy(b.orientationChange, b)),
                    a(window).on("resize.slick.slick-" + b.instanceUid, a.proxy(b.resize, b)),
                    a("[draggable!=true]", b.$slideTrack).on("dragstart", b.preventDefault),
                    a(window).on("load.slick.slick-" + b.instanceUid, b.setPosition),
                    a(document).on("ready.slick.slick-" + b.instanceUid, b.setPosition);
            }),
            (b.prototype.initUI = function () {
                var a = this;
                !0 === a.options.arrows && a.slideCount > a.options.slidesToShow && (a.$prevArrow.show(), a.$nextArrow.show()), !0 === a.options.dots && a.slideCount > a.options.slidesToShow && a.$dots.show();
            }),
            (b.prototype.keyHandler = function (a) {
                var b = this;
                a.target.tagName.match("TEXTAREA|INPUT|SELECT") ||
                (37 === a.keyCode && !0 === b.options.accessibility
                    ? b.changeSlide({ data: { message: !0 === b.options.rtl ? "next" : "previous" } })
                    : 39 === a.keyCode && !0 === b.options.accessibility && b.changeSlide({ data: { message: !0 === b.options.rtl ? "previous" : "next" } }));
            }),
            (b.prototype.lazyLoad = function () {
                function b(b) {
                    a("img[data-lazy]", b).each(function () {
                        var b = a(this),
                            c = a(this).attr("data-lazy"),
                            d = document.createElement("img");
                        (d.onload = function () {
                            b.animate({ opacity: 0 }, 100, function () {
                                b.attr("src", c).animate({ opacity: 1 }, 200, function () {
                                    b.removeAttr("data-lazy").removeClass("slick-loading");
                                }),
                                    g.$slider.trigger("lazyLoaded", [g, b, c]);
                            });
                        }),
                            (d.onerror = function () {
                                b.removeAttr("data-lazy").removeClass("slick-loading").addClass("slick-lazyload-error"), g.$slider.trigger("lazyLoadError", [g, b, c]);
                            }),
                            (d.src = c);
                    });
                }
                var c,
                    d,
                    e,
                    f,
                    g = this;
                !0 === g.options.centerMode
                    ? !0 === g.options.infinite
                        ? ((e = g.currentSlide + (g.options.slidesToShow / 2 + 1)), (f = e + g.options.slidesToShow + 2))
                        : ((e = Math.max(0, g.currentSlide - (g.options.slidesToShow / 2 + 1))), (f = g.options.slidesToShow / 2 + 1 + 2 + g.currentSlide))
                    : ((e = g.options.infinite ? g.options.slidesToShow + g.currentSlide : g.currentSlide), (f = Math.ceil(e + g.options.slidesToShow)), !0 === g.options.fade && (e > 0 && e--, f <= g.slideCount && f++)),
                    (c = g.$slider.find(".slick-slide").slice(e, f)),
                    b(c),
                    g.slideCount <= g.options.slidesToShow
                        ? ((d = g.$slider.find(".slick-slide")), b(d))
                        : g.currentSlide >= g.slideCount - g.options.slidesToShow
                            ? ((d = g.$slider.find(".slick-cloned").slice(0, g.options.slidesToShow)), b(d))
                            : 0 === g.currentSlide && ((d = g.$slider.find(".slick-cloned").slice(-1 * g.options.slidesToShow)), b(d));
            }),
            (b.prototype.loadSlider = function () {
                var a = this;
                a.setPosition(), a.$slideTrack.css({ opacity: 1 }), a.$slider.removeClass("slick-loading"), a.initUI(), "progressive" === a.options.lazyLoad && a.progressiveLazyLoad();
            }),
            (b.prototype.next = b.prototype.slickNext = function () {
                this.changeSlide({ data: { message: "next" } });
            }),
            (b.prototype.orientationChange = function () {
                var a = this;
                a.checkResponsive(), a.setPosition();
            }),
            (b.prototype.pause = b.prototype.slickPause = function () {
                var a = this;
                a.autoPlayClear(), (a.paused = !0);
            }),
            (b.prototype.play = b.prototype.slickPlay = function () {
                var a = this;
                a.autoPlay(), (a.options.autoplay = !0), (a.paused = !1), (a.focussed = !1), (a.interrupted = !1);
            }),
            (b.prototype.postSlide = function (a) {
                var b = this;
                b.unslicked || (b.$slider.trigger("afterChange", [b, a]), (b.animating = !1), b.setPosition(), (b.swipeLeft = null), b.options.autoplay && b.autoPlay(), !0 === b.options.accessibility && b.initADA());
            }),
            (b.prototype.prev = b.prototype.slickPrev = function () {
                this.changeSlide({ data: { message: "previous" } });
            }),
            (b.prototype.preventDefault = function (a) {
                a.preventDefault();
            }),
            (b.prototype.progressiveLazyLoad = function (b) {
                b = b || 1;
                var c,
                    d,
                    e,
                    f = this,
                    g = a("img[data-lazy]", f.$slider);
                g.length
                    ? ((c = g.first()),
                        (d = c.attr("data-lazy")),
                        (e = document.createElement("img")),
                        (e.onload = function () {
                            c.attr("src", d).removeAttr("data-lazy").removeClass("slick-loading"), !0 === f.options.adaptiveHeight && f.setPosition(), f.$slider.trigger("lazyLoaded", [f, c, d]), f.progressiveLazyLoad();
                        }),
                        (e.onerror = function () {
                            3 > b
                                ? setTimeout(function () {
                                    f.progressiveLazyLoad(b + 1);
                                }, 500)
                                : (c.removeAttr("data-lazy").removeClass("slick-loading").addClass("slick-lazyload-error"), f.$slider.trigger("lazyLoadError", [f, c, d]), f.progressiveLazyLoad());
                        }),
                        (e.src = d))
                    : f.$slider.trigger("allImagesLoaded", [f]);
            }),
            (b.prototype.refresh = function (b) {
                var c,
                    d,
                    e = this;
                (d = e.slideCount - e.options.slidesToShow),
                !e.options.infinite && e.currentSlide > d && (e.currentSlide = d),
                e.slideCount <= e.options.slidesToShow && (e.currentSlide = 0),
                    (c = e.currentSlide),
                    e.destroy(!0),
                    a.extend(e, e.initials, { currentSlide: c }),
                    e.init(),
                b || e.changeSlide({ data: { message: "index", index: c } }, !1);
            }),
            (b.prototype.registerBreakpoints = function () {
                var b,
                    c,
                    d,
                    e = this,
                    f = e.options.responsive || null;
                if ("array" === a.type(f) && f.length) {
                    e.respondTo = e.options.respondTo || "window";
                    for (b in f)
                        if (((d = e.breakpoints.length - 1), (c = f[b].breakpoint), f.hasOwnProperty(b))) {
                            for (; d >= 0; ) e.breakpoints[d] && e.breakpoints[d] === c && e.breakpoints.splice(d, 1), d--;
                            e.breakpoints.push(c), (e.breakpointSettings[c] = f[b].settings);
                        }
                    e.breakpoints.sort(function (a, b) {
                        return e.options.mobileFirst ? a - b : b - a;
                    });
                }
            }),
            (b.prototype.reinit = function () {
                var b = this;
                (b.$slides = b.$slideTrack.children(b.options.slide).addClass("slick-slide")),
                    (b.slideCount = b.$slides.length),
                b.currentSlide >= b.slideCount && 0 !== b.currentSlide && (b.currentSlide = b.currentSlide - b.options.slidesToScroll),
                b.slideCount <= b.options.slidesToShow && (b.currentSlide = 0),
                    b.registerBreakpoints(),
                    b.setProps(),
                    b.setupInfinite(),
                    b.buildArrows(),
                    b.updateArrows(),
                    b.initArrowEvents(),
                    b.buildDots(),
                    b.updateDots(),
                    b.initDotEvents(),
                    b.cleanUpSlideEvents(),
                    b.initSlideEvents(),
                    b.checkResponsive(!1, !0),
                !0 === b.options.focusOnSelect && a(b.$slideTrack).children().on("click.slick", b.selectHandler),
                    b.setSlideClasses("number" == typeof b.currentSlide ? b.currentSlide : 0),
                    b.setPosition(),
                    b.focusHandler(),
                    (b.paused = !b.options.autoplay),
                    b.autoPlay(),
                    b.$slider.trigger("reInit", [b]);
            }),
            (b.prototype.resize = function () {
                var b = this;
                a(window).width() !== b.windowWidth &&
                (clearTimeout(b.windowDelay),
                    (b.windowDelay = window.setTimeout(function () {
                        (b.windowWidth = a(window).width()), b.checkResponsive(), b.unslicked || b.setPosition();
                    }, 50)));
            }),
            (b.prototype.removeSlide = b.prototype.slickRemove = function (a, b, c) {
                var d = this;
                return (
                    "boolean" == typeof a ? ((b = a), (a = !0 === b ? 0 : d.slideCount - 1)) : (a = !0 === b ? --a : a),
                    !(d.slideCount < 1 || 0 > a || a > d.slideCount - 1) &&
                    (d.unload(),
                        !0 === c ? d.$slideTrack.children().remove() : d.$slideTrack.children(this.options.slide).eq(a).remove(),
                        (d.$slides = d.$slideTrack.children(this.options.slide)),
                        d.$slideTrack.children(this.options.slide).detach(),
                        d.$slideTrack.append(d.$slides),
                        (d.$slidesCache = d.$slides),
                        void d.reinit())
                );
            }),
            (b.prototype.setCSS = function (a) {
                var b,
                    c,
                    d = this,
                    e = {};
                !0 === d.options.rtl && (a = -a),
                    (b = "left" == d.positionProp ? Math.ceil(a) + "px" : "0px"),
                    (c = "top" == d.positionProp ? Math.ceil(a) + "px" : "0px"),
                    (e[d.positionProp] = a),
                    !1 === d.transformsEnabled
                        ? d.$slideTrack.css(e)
                        : ((e = {}), !1 === d.cssTransitions ? ((e[d.animType] = "translate(" + b + ", " + c + ")"), d.$slideTrack.css(e)) : ((e[d.animType] = "translate3d(" + b + ", " + c + ", 0px)"), d.$slideTrack.css(e)));
            }),
            (b.prototype.setDimensions = function () {
                var a = this;
                !1 === a.options.vertical
                    ? !0 === a.options.centerMode && a.$list.css({ padding: "0px " + a.options.centerPadding })
                    : (a.$list.height(a.$slides.first().outerHeight(!0) * a.options.slidesToShow), !0 === a.options.centerMode && a.$list.css({ padding: a.options.centerPadding + " 0px" })),
                    (a.listWidth = a.$list.width()),
                    (a.listHeight = a.$list.height()),
                    !1 === a.options.vertical && !1 === a.options.variableWidth
                        ? ((a.slideWidth = Math.ceil(a.listWidth / a.options.slidesToShow)), a.$slideTrack.width(Math.ceil(a.slideWidth * a.$slideTrack.children(".slick-slide").length)))
                        : !0 === a.options.variableWidth
                            ? a.$slideTrack.width(5e3 * a.slideCount)
                            : ((a.slideWidth = Math.ceil(a.listWidth)), a.$slideTrack.height(Math.ceil(a.$slides.first().outerHeight(!0) * a.$slideTrack.children(".slick-slide").length)));
                var b = a.$slides.first().outerWidth(!0) - a.$slides.first().width();
                !1 === a.options.variableWidth && a.$slideTrack.children(".slick-slide").width(a.slideWidth - b);
            }),
            (b.prototype.setFade = function () {
                var b,
                    c = this;
                c.$slides.each(function (d, e) {
                    (b = c.slideWidth * d * -1),
                        !0 === c.options.rtl ? a(e).css({ position: "relative", right: b, top: 0, zIndex: c.options.zIndex - 2, opacity: 0 }) : a(e).css({ position: "relative", left: b, top: 0, zIndex: c.options.zIndex - 2, opacity: 0 });
                }),
                    c.$slides.eq(c.currentSlide).css({ zIndex: c.options.zIndex - 1, opacity: 1 });
            }),
            (b.prototype.setHeight = function () {
                var a = this;
                if (1 === a.options.slidesToShow && !0 === a.options.adaptiveHeight && !1 === a.options.vertical) {
                    var b = a.$slides.eq(a.currentSlide).outerHeight(!0);
                    a.$list.css("height", b);
                }
            }),
            (b.prototype.setOption = b.prototype.slickSetOption = function () {
                var b,
                    c,
                    d,
                    e,
                    f,
                    g = this,
                    h = !1;
                if (
                    ("object" === a.type(arguments[0])
                        ? ((d = arguments[0]), (h = arguments[1]), (f = "multiple"))
                        : "string" === a.type(arguments[0]) &&
                        ((d = arguments[0]), (e = arguments[1]), (h = arguments[2]), "responsive" === arguments[0] && "array" === a.type(arguments[1]) ? (f = "responsive") : void 0 !== arguments[1] && (f = "single")),
                    "single" === f)
                )
                    g.options[d] = e;
                else if ("multiple" === f)
                    a.each(d, function (a, b) {
                        g.options[a] = b;
                    });
                else if ("responsive" === f)
                    for (c in e)
                        if ("array" !== a.type(g.options.responsive)) g.options.responsive = [e[c]];
                        else {
                            for (b = g.options.responsive.length - 1; b >= 0; ) g.options.responsive[b].breakpoint === e[c].breakpoint && g.options.responsive.splice(b, 1), b--;
                            g.options.responsive.push(e[c]);
                        }
                h && (g.unload(), g.reinit());
            }),
            (b.prototype.setPosition = function () {
                var a = this;
                a.setDimensions(), a.setHeight(), !1 === a.options.fade ? a.setCSS(a.getLeft(a.currentSlide)) : a.setFade(), a.$slider.trigger("setPosition", [a]);
            }),
            (b.prototype.setProps = function () {
                var a = this,
                    b = document.body.style;
                (a.positionProp = !0 === a.options.vertical ? "top" : "left"),
                    "top" === a.positionProp ? a.$slider.addClass("slick-vertical") : a.$slider.removeClass("slick-vertical"),
                (void 0 !== b.WebkitTransition || void 0 !== b.MozTransition || void 0 !== b.msTransition) && !0 === a.options.useCSS && (a.cssTransitions = !0),
                a.options.fade && ("number" == typeof a.options.zIndex ? a.options.zIndex < 3 && (a.options.zIndex = 3) : (a.options.zIndex = a.defaults.zIndex)),
                void 0 !== b.OTransform && ((a.animType = "OTransform"), (a.transformType = "-o-transform"), (a.transitionType = "OTransition"), void 0 === b.perspectiveProperty && void 0 === b.webkitPerspective && (a.animType = !1)),
                void 0 !== b.MozTransform &&
                ((a.animType = "MozTransform"), (a.transformType = "-moz-transform"), (a.transitionType = "MozTransition"), void 0 === b.perspectiveProperty && void 0 === b.MozPerspective && (a.animType = !1)),
                void 0 !== b.webkitTransform &&
                ((a.animType = "webkitTransform"), (a.transformType = "-webkit-transform"), (a.transitionType = "webkitTransition"), void 0 === b.perspectiveProperty && void 0 === b.webkitPerspective && (a.animType = !1)),
                void 0 !== b.msTransform && ((a.animType = "msTransform"), (a.transformType = "-ms-transform"), (a.transitionType = "msTransition"), void 0 === b.msTransform && (a.animType = !1)),
                void 0 !== b.transform && !1 !== a.animType && ((a.animType = "transform"), (a.transformType = "transform"), (a.transitionType = "transition")),
                    (a.transformsEnabled = a.options.useTransform && null !== a.animType && !1 !== a.animType);
            }),
            (b.prototype.setSlideClasses = function (a) {
                var b,
                    c,
                    d,
                    e,
                    f = this;
                (c = f.$slider.find(".slick-slide").removeClass("slick-active slick-center slick-current").attr("aria-hidden", "true")),
                    f.$slides.eq(a).addClass("slick-current"),
                    !0 === f.options.centerMode
                        ? ((b = Math.floor(f.options.slidesToShow / 2)),
                        !0 === f.options.infinite &&
                        (a >= b && a <= f.slideCount - 1 - b
                            ? f.$slides
                                .slice(a - b, a + b + 1)
                                .addClass("slick-active")
                                .attr("aria-hidden", "false")
                            : ((d = f.options.slidesToShow + a),
                                c
                                    .slice(d - b + 1, d + b + 2)
                                    .addClass("slick-active")
                                    .attr("aria-hidden", "false")),
                            0 === a ? c.eq(c.length - 1 - f.options.slidesToShow).addClass("slick-center") : a === f.slideCount - 1 && c.eq(f.options.slidesToShow).addClass("slick-center")),
                            f.$slides.eq(a).addClass("slick-center"))
                        : a >= 0 && a <= f.slideCount - f.options.slidesToShow
                            ? f.$slides
                                .slice(a, a + f.options.slidesToShow)
                                .addClass("slick-active")
                                .attr("aria-hidden", "false")
                            : c.length <= f.options.slidesToShow
                                ? c.addClass("slick-active").attr("aria-hidden", "false")
                                : ((e = f.slideCount % f.options.slidesToShow),
                                    (d = !0 === f.options.infinite ? f.options.slidesToShow + a : a),
                                    f.options.slidesToShow == f.options.slidesToScroll && f.slideCount - a < f.options.slidesToShow
                                        ? c
                                            .slice(d - (f.options.slidesToShow - e), d + e)
                                            .addClass("slick-active")
                                            .attr("aria-hidden", "false")
                                        : c
                                            .slice(d, d + f.options.slidesToShow)
                                            .addClass("slick-active")
                                            .attr("aria-hidden", "false")),
                "ondemand" === f.options.lazyLoad && f.lazyLoad();
            }),
            (b.prototype.setupInfinite = function () {
                var b,
                    c,
                    d,
                    e = this;
                if ((!0 === e.options.fade && (e.options.centerMode = !1), !0 === e.options.infinite && !1 === e.options.fade && ((c = null), e.slideCount > e.options.slidesToShow))) {
                    for (d = !0 === e.options.centerMode ? e.options.slidesToShow + 1 : e.options.slidesToShow, b = e.slideCount; b > e.slideCount - d; b -= 1)
                        (c = b - 1),
                            a(e.$slides[c])
                                .clone(!0)
                                .attr("id", "")
                                .attr("data-slick-index", c - e.slideCount)
                                .prependTo(e.$slideTrack)
                                .addClass("slick-cloned");
                    for (b = 0; d > b; b += 1)
                        (c = b),
                            a(e.$slides[c])
                                .clone(!0)
                                .attr("id", "")
                                .attr("data-slick-index", c + e.slideCount)
                                .appendTo(e.$slideTrack)
                                .addClass("slick-cloned");
                    e.$slideTrack
                        .find(".slick-cloned")
                        .find("[id]")
                        .each(function () {
                            a(this).attr("id", "");
                        });
                }
            }),
            (b.prototype.interrupt = function (a) {
                var b = this;
                a || b.autoPlay(), (b.interrupted = a);
            }),
            (b.prototype.selectHandler = function (b) {
                var c = this,
                    d = a(b.target).is(".slick-slide") ? a(b.target) : a(b.target).parents(".slick-slide"),
                    e = parseInt(d.attr("data-slick-index"));
                return e || (e = 0), c.slideCount <= c.options.slidesToShow ? (c.setSlideClasses(e), void c.asNavFor(e)) : void c.slideHandler(e);
            }),
            (b.prototype.slideHandler = function (a, b, c) {
                var d,
                    e,
                    f,
                    g,
                    h,
                    i = null,
                    j = this;
                return (
                    (b = b || !1),
                        (!0 === j.animating && !0 === j.options.waitForAnimate) || (!0 === j.options.fade && j.currentSlide === a) || j.slideCount <= j.options.slidesToShow
                            ? void 0
                            : (!1 === b && j.asNavFor(a),
                                (d = a),
                                (i = j.getLeft(d)),
                                (g = j.getLeft(j.currentSlide)),
                                (j.currentLeft = null === j.swipeLeft ? g : j.swipeLeft),
                                !1 === j.options.infinite && !1 === j.options.centerMode && (0 > a || a > j.getDotCount() * j.options.slidesToScroll)
                                    ? void (
                                        !1 === j.options.fade &&
                                        ((d = j.currentSlide),
                                            !0 !== c
                                                ? j.animateSlide(g, function () {
                                                    j.postSlide(d);
                                                })
                                                : j.postSlide(d))
                                    )
                                    : !1 === j.options.infinite && !0 === j.options.centerMode && (0 > a || a > j.slideCount - j.options.slidesToScroll)
                                        ? void (
                                            !1 === j.options.fade &&
                                            ((d = j.currentSlide),
                                                !0 !== c
                                                    ? j.animateSlide(g, function () {
                                                        j.postSlide(d);
                                                    })
                                                    : j.postSlide(d))
                                        )
                                        : (j.options.autoplay && clearInterval(j.autoPlayTimer),
                                            (e =
                                                0 > d
                                                    ? j.slideCount % j.options.slidesToScroll != 0
                                                        ? j.slideCount - (j.slideCount % j.options.slidesToScroll)
                                                        : j.slideCount + d
                                                    : d >= j.slideCount
                                                        ? j.slideCount % j.options.slidesToScroll != 0
                                                            ? 0
                                                            : d - j.slideCount
                                                        : d),
                                            (j.animating = !0),
                                            j.$slider.trigger("beforeChange", [j, j.currentSlide, e]),
                                            (f = j.currentSlide),
                                            (j.currentSlide = e),
                                            j.setSlideClasses(j.currentSlide),
                                        j.options.asNavFor && ((h = j.getNavTarget()), (h = h.slick("getSlick")), h.slideCount <= h.options.slidesToShow && h.setSlideClasses(j.currentSlide)),
                                            j.updateDots(),
                                            j.updateArrows(),
                                            !0 === j.options.fade
                                                ? (!0 !== c
                                                    ? (j.fadeSlideOut(f),
                                                        j.fadeSlide(e, function () {
                                                            j.postSlide(e);
                                                        }))
                                                    : j.postSlide(e),
                                                    void j.animateHeight())
                                                : void (!0 !== c
                                                    ? j.animateSlide(i, function () {
                                                        j.postSlide(e);
                                                    })
                                                    : j.postSlide(e))))
                );
            }),
            (b.prototype.startLoad = function () {
                var a = this;
                !0 === a.options.arrows && a.slideCount > a.options.slidesToShow && (a.$prevArrow.hide(), a.$nextArrow.hide()),
                !0 === a.options.dots && a.slideCount > a.options.slidesToShow && a.$dots.hide(),
                    a.$slider.addClass("slick-loading");
            }),
            (b.prototype.swipeDirection = function () {
                var a,
                    b,
                    c,
                    d,
                    e = this;
                return (
                    (a = e.touchObject.startX - e.touchObject.curX),
                        (b = e.touchObject.startY - e.touchObject.curY),
                        (c = Math.atan2(b, a)),
                        (d = Math.round((180 * c) / Math.PI)),
                    0 > d && (d = 360 - Math.abs(d)),
                        45 >= d && d >= 0
                            ? !1 === e.options.rtl
                                ? "left"
                                : "right"
                            : 360 >= d && d >= 315
                                ? !1 === e.options.rtl
                                    ? "left"
                                    : "right"
                                : d >= 135 && 225 >= d
                                    ? !1 === e.options.rtl
                                        ? "right"
                                        : "left"
                                    : !0 === e.options.verticalSwiping
                                        ? d >= 35 && 135 >= d
                                            ? "down"
                                            : "up"
                                        : "vertical"
                );
            }),
            (b.prototype.swipeEnd = function (a) {
                var b,
                    c,
                    d = this;
                if (((d.dragging = !1), (d.interrupted = !1), (d.shouldClick = !(d.touchObject.swipeLength > 10)), void 0 === d.touchObject.curX)) return !1;
                if ((!0 === d.touchObject.edgeHit && d.$slider.trigger("edge", [d, d.swipeDirection()]), d.touchObject.swipeLength >= d.touchObject.minSwipe)) {
                    switch ((c = d.swipeDirection())) {
                        case "left":
                        case "down":
                            (b = d.options.swipeToSlide ? d.checkNavigable(d.currentSlide + d.getSlideCount()) : d.currentSlide + d.getSlideCount()), (d.currentDirection = 0);
                            break;
                        case "right":
                        case "up":
                            (b = d.options.swipeToSlide ? d.checkNavigable(d.currentSlide - d.getSlideCount()) : d.currentSlide - d.getSlideCount()), (d.currentDirection = 1);
                    }
                    "vertical" != c && (d.slideHandler(b), (d.touchObject = {}), d.$slider.trigger("swipe", [d, c]));
                } else d.touchObject.startX !== d.touchObject.curX && (d.slideHandler(d.currentSlide), (d.touchObject = {}));
            }),
            (b.prototype.swipeHandler = function (a) {
                var b = this;
                if (!(!1 === b.options.swipe || ("ontouchend" in document && !1 === b.options.swipe) || (!1 === b.options.draggable && -1 !== a.type.indexOf("mouse"))))
                    switch (
                        ((b.touchObject.fingerCount = a.originalEvent && void 0 !== a.originalEvent.touches ? a.originalEvent.touches.length : 1),
                            (b.touchObject.minSwipe = b.listWidth / b.options.touchThreshold),
                        !0 === b.options.verticalSwiping && (b.touchObject.minSwipe = b.listHeight / b.options.touchThreshold),
                            a.data.action)
                        ) {
                        case "start":
                            b.swipeStart(a);
                            break;
                        case "move":
                            b.swipeMove(a);
                            break;
                        case "end":
                            b.swipeEnd(a);
                    }
            }),
            (b.prototype.swipeMove = function (a) {
                var b,
                    c,
                    d,
                    e,
                    f,
                    g = this;
                return (
                    (f = void 0 !== a.originalEvent ? a.originalEvent.touches : null),
                    !(!g.dragging || (f && 1 !== f.length)) &&
                    ((b = g.getLeft(g.currentSlide)),
                        (g.touchObject.curX = void 0 !== f ? f[0].pageX : a.clientX),
                        (g.touchObject.curY = void 0 !== f ? f[0].pageY : a.clientY),
                        (g.touchObject.swipeLength = Math.round(Math.sqrt(Math.pow(g.touchObject.curX - g.touchObject.startX, 2)))),
                    !0 === g.options.verticalSwiping && (g.touchObject.swipeLength = Math.round(Math.sqrt(Math.pow(g.touchObject.curY - g.touchObject.startY, 2)))),
                        (c = g.swipeDirection()),
                        "vertical" !== c
                            ? (void 0 !== a.originalEvent && g.touchObject.swipeLength > 4 && a.preventDefault(),
                                (e = (!1 === g.options.rtl ? 1 : -1) * (g.touchObject.curX > g.touchObject.startX ? 1 : -1)),
                            !0 === g.options.verticalSwiping && (e = g.touchObject.curY > g.touchObject.startY ? 1 : -1),
                                (d = g.touchObject.swipeLength),
                                (g.touchObject.edgeHit = !1),
                            !1 === g.options.infinite &&
                            ((0 === g.currentSlide && "right" === c) || (g.currentSlide >= g.getDotCount() && "left" === c)) &&
                            ((d = g.touchObject.swipeLength * g.options.edgeFriction), (g.touchObject.edgeHit = !0)),
                                !1 === g.options.vertical ? (g.swipeLeft = b + d * e) : (g.swipeLeft = b + d * (g.$list.height() / g.listWidth) * e),
                            !0 === g.options.verticalSwiping && (g.swipeLeft = b + d * e),
                            !0 !== g.options.fade && !1 !== g.options.touchMove && (!0 === g.animating ? ((g.swipeLeft = null), !1) : void g.setCSS(g.swipeLeft)))
                            : void 0)
                );
            }),
            (b.prototype.swipeStart = function (a) {
                var b,
                    c = this;
                return (
                    (c.interrupted = !0),
                        1 !== c.touchObject.fingerCount || c.slideCount <= c.options.slidesToShow
                            ? ((c.touchObject = {}), !1)
                            : (void 0 !== a.originalEvent && void 0 !== a.originalEvent.touches && (b = a.originalEvent.touches[0]),
                                (c.touchObject.startX = c.touchObject.curX = void 0 !== b ? b.pageX : a.clientX),
                                (c.touchObject.startY = c.touchObject.curY = void 0 !== b ? b.pageY : a.clientY),
                                void (c.dragging = !0))
                );
            }),
            (b.prototype.unfilterSlides = b.prototype.slickUnfilter = function () {
                var a = this;
                null !== a.$slidesCache && (a.unload(), a.$slideTrack.children(this.options.slide).detach(), a.$slidesCache.appendTo(a.$slideTrack), a.reinit());
            }),
            (b.prototype.unload = function () {
                var b = this;
                a(".slick-cloned", b.$slider).remove(),
                b.$dots && b.$dots.remove(),
                b.$prevArrow && b.htmlExpr.test(b.options.prevArrow) && b.$prevArrow.remove(),
                b.$nextArrow && b.htmlExpr.test(b.options.nextArrow) && b.$nextArrow.remove(),
                    b.$slides.removeClass("slick-slide slick-active slick-visible slick-current").attr("aria-hidden", "true").css("width", "");
            }),
            (b.prototype.unslick = function (a) {
                var b = this;
                b.$slider.trigger("unslick", [b, a]), b.destroy();
            }),
            (b.prototype.updateArrows = function () {
                var a = this;
                Math.floor(a.options.slidesToShow / 2),
                !0 === a.options.arrows &&
                a.slideCount > a.options.slidesToShow &&
                !a.options.infinite &&
                (a.$prevArrow.removeClass("slick-disabled").attr("aria-disabled", "false"),
                    a.$nextArrow.removeClass("slick-disabled").attr("aria-disabled", "false"),
                    0 === a.currentSlide
                        ? (a.$prevArrow.addClass("slick-disabled").attr("aria-disabled", "true"), a.$nextArrow.removeClass("slick-disabled").attr("aria-disabled", "false"))
                        : a.currentSlide >= a.slideCount - a.options.slidesToShow && !1 === a.options.centerMode
                            ? (a.$nextArrow.addClass("slick-disabled").attr("aria-disabled", "true"), a.$prevArrow.removeClass("slick-disabled").attr("aria-disabled", "false"))
                            : a.currentSlide >= a.slideCount - 1 &&
                            !0 === a.options.centerMode &&
                            (a.$nextArrow.addClass("slick-disabled").attr("aria-disabled", "true"), a.$prevArrow.removeClass("slick-disabled").attr("aria-disabled", "false")));
            }),
            (b.prototype.updateDots = function () {
                var a = this;
                null !== a.$dots &&
                (a.$dots.find("li").removeClass("slick-active").attr("aria-hidden", "true"),
                    a.$dots
                        .find("li")
                        .eq(Math.floor(a.currentSlide / a.options.slidesToScroll))
                        .addClass("slick-active")
                        .attr("aria-hidden", "false"));
            }),
            (b.prototype.visibility = function () {
                var a = this;
                a.options.autoplay && (document[a.hidden] ? (a.interrupted = !0) : (a.interrupted = !1));
            }),
            (a.fn.slick = function () {
                var a,
                    c,
                    d = this,
                    e = arguments[0],
                    f = Array.prototype.slice.call(arguments, 1),
                    g = d.length;
                for (a = 0; g > a; a++) if (("object" == typeof e || void 0 === e ? (d[a].slick = new b(d[a], e)) : (c = d[a].slick[e].apply(d[a].slick, f)), void 0 !== c)) return c;
                return d;
            });
    }),
    /*
    (function (a, b, c, d) {
        "use strict";
        var e = c("html"),
            f = c(a),
            g = c(b),
            h = (c.fancybox = function () {
                h.open.apply(this, arguments);
            }),
            i = navigator.userAgent.match(/msie/i),
            j = null,
            k = void 0 !== b.createTouch,
            l = function (a) {
                return a && a.hasOwnProperty && a instanceof c;
            },
            m = function (a) {
                return a && "string" === c.type(a);
            },
            n = function (a) {
                return m(a) && a.indexOf("%") > 0;
            },
            o = function (a) {
                return a && !(a.style.overflow && "hidden" === a.style.overflow) && ((a.clientWidth && a.scrollWidth > a.clientWidth) || (a.clientHeight && a.scrollHeight > a.clientHeight));
            },
            p = function (a, b) {
                var c = parseInt(a, 10) || 0;
                return b && n(a) && (c = (h.getViewport()[b] / 100) * c), Math.ceil(c);
            },
            q = function (a, b) {
                return p(a, b) + "px";
            };
        c.extend(h, {
            version: "2.1.5",
            defaults: {
                padding: 15,
                margin: 20,
                width: 800,
                height: 600,
                minWidth: 100,
                minHeight: 100,
                maxWidth: 9999,
                maxHeight: 9999,
                pixelRatio: 1,
                autoSize: !0,
                autoHeight: !1,
                autoWidth: !1,
                autoResize: !0,
                autoCenter: !k,
                fitToView: !0,
                aspectRatio: !1,
                topRatio: 0.5,
                leftRatio: 0.5,
                scrolling: "auto",
                wrapCSS: "",
                arrows: !0,
                closeBtn: !0,
                closeClick: !1,
                nextClick: !1,
                mouseWheel: !0,
                autoPlay: !1,
                playSpeed: 3e3,
                preload: 3,
                modal: !1,
                loop: !0,
                ajax: { dataType: "html", headers: { "X-fancyBox": !0 } },
                iframe: { scrolling: "auto", preload: !0 },
                swf: { wmode: "transparent", allowfullscreen: "true", allowscriptaccess: "always" },
                keys: { next: { 13: "left", 34: "up", 39: "left", 40: "up" }, prev: { 8: "right", 33: "down", 37: "right", 38: "down" }, close: [27], play: [32], toggle: [70] },
                direction: { next: "left", prev: "right" },
                scrollOutside: !0,
                index: 0,
                type: null,
                href: null,
                content: null,
                title: null,
                tpl: {
                    wrap: '<div class="fancybox-wrap" tabIndex="-1"><div class="fancybox-skin"><div class="fancybox-outer"><div class="fancybox-inner"></div></div></div></div>',
                    image: '<img class="fancybox-image" src="{href}" alt="" />',
                    iframe:
                        '<iframe id="fancybox-frame{rnd}" name="fancybox-frame{rnd}" class="fancybox-iframe" frameborder="0" vspace="0" hspace="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen' +
                        (i ? ' allowtransparency="true"' : "") +
                        "></iframe>",
                    error: '<p class="fancybox-error">The requested content cannot be loaded.<br/>Please try again later.</p>',
                    closeBtn: '<a title="Close" class="fancybox-item fancybox-close" href="javascript:;"></a>',
                    next: '<a title="Next" class="fancybox-nav fancybox-next" href="javascript:;"><span></span></a>',
                    prev: '<a title="Previous" class="fancybox-nav fancybox-prev" href="javascript:;"><span></span></a>',
                    loading: '<div id="fancybox-loading"><div></div></div>',
                },
                openEffect: "fade",
                openSpeed: 250,
                openEasing: "swing",
                openOpacity: !0,
                openMethod: "zoomIn",
                closeEffect: "fade",
                closeSpeed: 250,
                closeEasing: "swing",
                closeOpacity: !0,
                closeMethod: "zoomOut",
                nextEffect: "elastic",
                nextSpeed: 250,
                nextEasing: "swing",
                nextMethod: "changeIn",
                prevEffect: "elastic",
                prevSpeed: 250,
                prevEasing: "swing",
                prevMethod: "changeOut",
                helpers: { overlay: !0, title: !0 },
                onCancel: c.noop,
                beforeLoad: c.noop,
                afterLoad: c.noop,
                beforeShow: c.noop,
                afterShow: c.noop,
                beforeChange: c.noop,
                beforeClose: c.noop,
                afterClose: c.noop,
            },
            group: {},
            opts: {},
            previous: null,
            coming: null,
            current: null,
            isActive: !1,
            isOpen: !1,
            isOpened: !1,
            wrap: null,
            skin: null,
            outer: null,
            inner: null,
            player: { timer: null, isActive: !1 },
            ajaxLoad: null,
            imgPreload: null,
            transitions: {},
            helpers: {},
            open: function (a, b) {
                if (a && (c.isPlainObject(b) || (b = {}), !1 !== h.close(!0)))
                    return (
                        c.isArray(a) || (a = l(a) ? c(a).get() : [a]),
                            c.each(a, function (d, e) {
                                var f,
                                    g,
                                    i,
                                    j,
                                    k,
                                    n,
                                    o,
                                    p = {};
                                "object" === c.type(e) &&
                                (e.nodeType && (e = c(e)),
                                    l(e)
                                        ? ((p = {
                                            href: e.data("fancybox-href") || e.attr("href"),
                                            title: c("<div/>")
                                                .text(e.data("fancybox-title") || e.attr("title") || "")
                                                .html(),
                                            isDom: !0,
                                            element: e,
                                        }),
                                        c.metadata && c.extend(!0, p, e.metadata()))
                                        : (p = e)),
                                    (f = b.href || p.href || (m(e) ? e : null)),
                                    (g = void 0 !== b.title ? b.title : p.title || ""),
                                    (i = b.content || p.content),
                                    (j = i ? "html" : b.type || p.type),
                                !j && p.isDom && ((j = e.data("fancybox-type")) || ((k = e.prop("class").match(/fancybox\.(\w+)/)), (j = k ? k[1] : null))),
                                m(f) &&
                                (j || (h.isImage(f) ? (j = "image") : h.isSWF(f) ? (j = "swf") : "#" === f.charAt(0) ? (j = "inline") : m(e) && ((j = "html"), (i = e))),
                                "ajax" === j && ((n = f.split(/\s+/, 2)), (f = n.shift()), (o = n.shift()))),
                                i || ("inline" === j ? (f ? (i = c(m(f) ? f.replace(/.*(?=#[^\s]+$)/, "") : f)) : p.isDom && (i = e)) : "html" === j ? (i = f) : j || f || !p.isDom || ((j = "inline"), (i = e))),
                                    c.extend(p, { href: f, type: j, content: i, title: g, selector: o }),
                                    (a[d] = p);
                            }),
                            (h.opts = c.extend(!0, {}, h.defaults, b)),
                        void 0 !== b.keys && (h.opts.keys = !!b.keys && c.extend({}, h.defaults.keys, b.keys)),
                            (h.group = a),
                            h._start(h.opts.index)
                    );
            },
            cancel: function () {
                var a = h.coming;
                (a && !1 === h.trigger("onCancel")) ||
                (h.hideLoading(),
                a &&
                (h.ajaxLoad && h.ajaxLoad.abort(),
                    (h.ajaxLoad = null),
                h.imgPreload && (h.imgPreload.onload = h.imgPreload.onerror = null),
                a.wrap && a.wrap.stop(!0, !0).trigger("onReset").remove(),
                    (h.coming = null),
                h.current || h._afterZoomOut(a)));
            },
            close: function (a) {
                h.cancel(),
                !1 !== h.trigger("beforeClose") &&
                (h.unbindEvents(),
                h.isActive &&
                (h.isOpen && !0 !== a
                    ? ((h.isOpen = h.isOpened = !1), (h.isClosing = !0), c(".fancybox-item, .fancybox-nav").remove(), h.wrap.stop(!0, !0).removeClass("fancybox-opened"), h.transitions[h.current.closeMethod]())
                    : (c(".fancybox-wrap").stop(!0).trigger("onReset").remove(), h._afterZoomOut())));
            },
            play: function (a) {
                var b = function () {
                        clearTimeout(h.player.timer);
                    },
                    c = function () {
                        b(), h.current && h.player.isActive && (h.player.timer = setTimeout(h.next, h.current.playSpeed));
                    },
                    d = function () {
                        b(), g.unbind(".player"), (h.player.isActive = !1), h.trigger("onPlayEnd");
                    };
                !0 === a || (!h.player.isActive && !1 !== a)
                    ? (function () {
                        h.current &&
                        (h.current.loop || h.current.index < h.group.length - 1) &&
                        ((h.player.isActive = !0), g.bind({ "onCancel.player beforeClose.player": d, "onUpdate.player": c, "beforeLoad.player": b }), c(), h.trigger("onPlayStart"));
                    })()
                    : d();
            },
            next: function (a) {
                var b = h.current;
                b && (m(a) || (a = b.direction.next), h.jumpto(b.index + 1, a, "next"));
            },
            prev: function (a) {
                var b = h.current;
                b && (m(a) || (a = b.direction.prev), h.jumpto(b.index - 1, a, "prev"));
            },
            jumpto: function (a, b, c) {
                var d = h.current;
                d &&
                ((a = p(a)),
                    (h.direction = b || d.direction[a >= d.index ? "next" : "prev"]),
                    (h.router = c || "jumpto"),
                d.loop && (a < 0 && (a = d.group.length + (a % d.group.length)), (a %= d.group.length)),
                void 0 !== d.group[a] && (h.cancel(), h._start(a)));
            },
            reposition: function (a, b) {
                var d,
                    e = h.current,
                    f = e ? e.wrap : null;
                f && ((d = h._getPosition(b)), a && "scroll" === a.type ? (delete d.position, f.stop(!0, !0).animate(d, 200)) : (f.css(d), (e.pos = c.extend({}, e.dim, d))));
            },
            update: function (a) {
                var b = a && a.originalEvent && a.originalEvent.type,
                    c = !b || "orientationchange" === b;
                c && (clearTimeout(j), (j = null)),
                h.isOpen &&
                !j &&
                (j = setTimeout(
                    function () {
                        var d = h.current;
                        d &&
                        !h.isClosing &&
                        (h.wrap.removeClass("fancybox-tmp"), (c || "load" === b || ("resize" === b && d.autoResize)) && h._setDimension(), ("scroll" === b && d.canShrink) || h.reposition(a), h.trigger("onUpdate"), (j = null));
                    },
                    c && !k ? 0 : 300
                ));
            },
            toggle: function (a) {
                h.isOpen && ((h.current.fitToView = "boolean" === c.type(a) ? a : !h.current.fitToView), k && (h.wrap.removeAttr("style").addClass("fancybox-tmp"), h.trigger("onUpdate")), h.update());
            },
            hideLoading: function () {
                g.unbind(".loading"), c("#fancybox-loading").remove();
            },
            showLoading: function () {
                var a, b;
                h.hideLoading(),
                    (a = c(h.opts.tpl.loading).click(h.cancel).appendTo("body")),
                    g.bind("keydown.loading", function (a) {
                        27 === (a.which || a.keyCode) && (a.preventDefault(), h.cancel());
                    }),
                h.defaults.fixed || ((b = h.getViewport()), a.css({ position: "absolute", top: 0.5 * b.h + b.y, left: 0.5 * b.w + b.x })),
                    h.trigger("onLoading");
            },
            getViewport: function () {
                var b = (h.current && h.current.locked) || !1,
                    c = { x: f.scrollLeft(), y: f.scrollTop() };
                return b && b.length ? ((c.w = b[0].clientWidth), (c.h = b[0].clientHeight)) : ((c.w = k && a.innerWidth ? a.innerWidth : f.width()), (c.h = k && a.innerHeight ? a.innerHeight : f.height())), c;
            },
            unbindEvents: function () {
                h.wrap && l(h.wrap) && h.wrap.unbind(".fb"), g.unbind(".fb"), f.unbind(".fb");
            },
            bindEvents: function () {
                var a,
                    b = h.current;
                b &&
                (f.bind("orientationchange.fb" + (k ? "" : " resize.fb") + (b.autoCenter && !b.locked ? " scroll.fb" : ""), h.update),
                    (a = b.keys),
                a &&
                g.bind("keydown.fb", function (d) {
                    var e = d.which || d.keyCode,
                        f = d.target || d.srcElement;
                    if (27 === e && h.coming) return !1;
                    d.ctrlKey ||
                    d.altKey ||
                    d.shiftKey ||
                    d.metaKey ||
                    (f && (f.type || c(f).is("[contenteditable]"))) ||
                    c.each(a, function (a, f) {
                        return b.group.length > 1 && void 0 !== f[e] ? (h[a](f[e]), d.preventDefault(), !1) : c.inArray(e, f) > -1 ? (h[a](), d.preventDefault(), !1) : void 0;
                    });
                }),
                c.fn.mousewheel &&
                b.mouseWheel &&
                h.wrap.bind("mousewheel.fb", function (a, d, e, f) {
                    for (var g = a.target || null, i = c(g), j = !1; i.length && !(j || i.is(".fancybox-skin") || i.is(".fancybox-wrap")); ) (j = o(i[0])), (i = c(i).parent());
                    0 === d || j || (h.group.length > 1 && !b.canShrink && (f > 0 || e > 0 ? h.prev(f > 0 ? "down" : "left") : (f < 0 || e < 0) && h.next(f < 0 ? "up" : "right"), a.preventDefault()));
                }));
            },
            trigger: function (a, b) {
                var d,
                    e = b || h.coming || h.current;
                if (e) {
                    if ((c.isFunction(e[a]) && (d = e[a].apply(e, Array.prototype.slice.call(arguments, 1))), !1 === d)) return !1;
                    e.helpers &&
                    c.each(e.helpers, function (b, d) {
                        d && h.helpers[b] && c.isFunction(h.helpers[b][a]) && h.helpers[b][a](c.extend(!0, {}, h.helpers[b].defaults, d), e);
                    });
                }
                g.trigger(a);
            },
            isImage: function (a) {
                return m(a) && a.match(/(^data:image\/.*,)|(\.(jp(e|g|eg)|gif|png|bmp|webp|svg)((\?|#).*)?$)/i);
            },
            isSWF: function (a) {
                return m(a) && a.match(/\.(swf)((\?|#).*)?$/i);
            },
            _start: function (a) {
                var b,
                    d,
                    e,
                    f,
                    g,
                    i = {};
                if (((a = p(a)), !(b = h.group[a] || null))) return !1;
                if (
                    ((i = c.extend(!0, {}, h.opts, b)),
                        (f = i.margin),
                        (g = i.padding),
                    "number" === c.type(f) && (i.margin = [f, f, f, f]),
                    "number" === c.type(g) && (i.padding = [g, g, g, g]),
                    i.modal && c.extend(!0, i, { closeBtn: !1, closeClick: !1, nextClick: !1, arrows: !1, mouseWheel: !1, keys: null, helpers: { overlay: { closeClick: !1 } } }),
                    i.autoSize && (i.autoWidth = i.autoHeight = !0),
                    "auto" === i.width && (i.autoWidth = !0),
                    "auto" === i.height && (i.autoHeight = !0),
                        (i.group = h.group),
                        (i.index = a),
                        (h.coming = i),
                    !1 === h.trigger("beforeLoad"))
                )
                    return void (h.coming = null);
                if (((e = i.type), (d = i.href), !e)) return (h.coming = null), !(!h.current || !h.router || "jumpto" === h.router) && ((h.current.index = a), h[h.router](h.direction));
                if (
                    ((h.isActive = !0),
                    ("image" !== e && "swf" !== e) || ((i.autoHeight = i.autoWidth = !1), (i.scrolling = "visible")),
                    "image" === e && (i.aspectRatio = !0),
                    "iframe" === e && k && (i.scrolling = "scroll"),
                        (i.wrap = c(i.tpl.wrap)
                            .addClass("fancybox-" + (k ? "mobile" : "desktop") + " fancybox-type-" + e + " fancybox-tmp " + i.wrapCSS)
                            .appendTo(i.parent || "body")),
                        c.extend(i, { skin: c(".fancybox-skin", i.wrap), outer: c(".fancybox-outer", i.wrap), inner: c(".fancybox-inner", i.wrap) }),
                        c.each(["Top", "Right", "Bottom", "Left"], function (a, b) {
                            i.skin.css("padding" + b, q(i.padding[a]));
                        }),
                        h.trigger("onReady"),
                    "inline" === e || "html" === e)
                ) {
                    if (!i.content || !i.content.length) return h._error("content");
                } else if (!d) return h._error("href");
                "image" === e ? h._loadImage() : "ajax" === e ? h._loadAjax() : "iframe" === e ? h._loadIframe() : h._afterLoad();
            },
            _error: function (a) {
                c.extend(h.coming, { type: "html", autoWidth: !0, autoHeight: !0, minWidth: 0, minHeight: 0, scrolling: "no", hasError: a, content: h.coming.tpl.error }), h._afterLoad();
            },
            _loadImage: function () {
                var a = (h.imgPreload = new Image());
                (a.onload = function () {
                    (this.onload = this.onerror = null), (h.coming.width = this.width / h.opts.pixelRatio), (h.coming.height = this.height / h.opts.pixelRatio), h._afterLoad();
                }),
                    (a.onerror = function () {
                        (this.onload = this.onerror = null), h._error("image");
                    }),
                    (a.src = h.coming.href),
                !0 !== a.complete && h.showLoading();
            },
            _loadAjax: function () {
                var a = h.coming;
                h.showLoading(),
                    (h.ajaxLoad = c.ajax(
                        c.extend({}, a.ajax, {
                            url: a.href,
                            error: function (a, b) {
                                h.coming && "abort" !== b ? h._error("ajax", a) : h.hideLoading();
                            },
                            success: function (b, c) {
                                "success" === c && ((a.content = b), h._afterLoad());
                            },
                        })
                    ));
            },
            _loadIframe: function () {
                var a = h.coming,
                    b = c(a.tpl.iframe.replace(/\{rnd\}/g, new Date().getTime()))
                        .attr("scrolling", k ? "auto" : a.iframe.scrolling)
                        .attr("src", a.href);
                c(a.wrap).bind("onReset", function () {
                    try {
                        c(this).find("iframe").hide().attr("src", "//about:blank").end().empty();
                    } catch (a) {}
                }),
                a.iframe.preload &&
                (h.showLoading(),
                    b.one("load", function () {
                        c(this).data("ready", 1), k || c(this).bind("load.fb", h.update), c(this).parents(".fancybox-wrap").width("100%").removeClass("fancybox-tmp").show(), h._afterLoad();
                    })),
                    (a.content = b.appendTo(a.inner)),
                a.iframe.preload || h._afterLoad();
            },
            _preloadImages: function () {
                var a,
                    b,
                    c = h.group,
                    d = h.current,
                    e = c.length,
                    f = d.preload ? Math.min(d.preload, e - 1) : 0;
                for (b = 1; b <= f; b += 1) (a = c[(d.index + b) % e]), "image" === a.type && a.href && (new Image().src = a.href);
            },
            _afterLoad: function () {
                var a,
                    b,
                    d,
                    e,
                    f,
                    g,
                    i = h.coming,
                    j = h.current,
                    k = "fancybox-placeholder";
                if ((h.hideLoading(), i && !1 !== h.isActive)) {
                    if (!1 === h.trigger("afterLoad", i, j)) return i.wrap.stop(!0).trigger("onReset").remove(), void (h.coming = null);
                    switch (
                        (j && (h.trigger("beforeChange", j), j.wrap.stop(!0).removeClass("fancybox-opened").find(".fancybox-item, .fancybox-nav").remove()),
                            h.unbindEvents(),
                            (a = i),
                            (b = i.content),
                            (d = i.type),
                            (e = i.scrolling),
                            c.extend(h, { wrap: a.wrap, skin: a.skin, outer: a.outer, inner: a.inner, current: a, previous: j }),
                            (f = a.href),
                            d)
                        ) {
                        case "inline":
                        case "ajax":
                        case "html":
                            a.selector
                                ? (b = c("<div>").html(b).find(a.selector))
                                : l(b) &&
                                (b.data(k) ||
                                b.data(
                                    k,
                                    c('<div class="' + k + '"></div>')
                                        .insertAfter(b)
                                        .hide()
                                ),
                                    (b = b.show().detach()),
                                    a.wrap.bind("onReset", function () {
                                        c(this).find(b).length && b.hide().replaceAll(b.data(k)).data(k, !1);
                                    }));
                            break;
                        case "image":
                            b = a.tpl.image.replace(/\{href\}/g, f);
                            break;
                        case "swf":
                            (b = '<object id="fancybox-swf" classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" width="100%" height="100%"><param name="movie" value="' + f + '"></param>'),
                                (g = ""),
                                c.each(a.swf, function (a, c) {
                                    (b += '<param name="' + a + '" value="' + c + '"></param>'), (g += " " + a + '="' + c + '"');
                                }),
                                (b += '<embed src="' + f + '" type="application/x-shockwave-flash" width="100%" height="100%"' + g + "></embed></object>");
                    }
                    (l(b) && b.parent().is(a.inner)) || a.inner.append(b),
                        h.trigger("beforeShow"),
                        a.inner.css("overflow", "yes" === e ? "scroll" : "no" === e ? "hidden" : e),
                        h._setDimension(),
                        h.reposition(),
                        (h.isOpen = !1),
                        (h.coming = null),
                        h.bindEvents(),
                        h.isOpened ? j.prevMethod && h.transitions[j.prevMethod]() : c(".fancybox-wrap").not(a.wrap).stop(!0).trigger("onReset").remove(),
                        h.transitions[h.isOpened ? a.nextMethod : a.openMethod](),
                        h._preloadImages();
                }
            },
            _setDimension: function () {
                var a,
                    b,
                    d,
                    e,
                    f,
                    g,
                    i,
                    j,
                    k,
                    l,
                    m,
                    o,
                    r,
                    s,
                    t,
                    u = h.getViewport(),
                    v = 0,
                    w = !1,
                    x = !1,
                    y = h.wrap,
                    z = h.skin,
                    A = h.inner,
                    B = h.current,
                    C = B.width,
                    D = B.height,
                    E = B.minWidth,
                    F = B.minHeight,
                    G = B.maxWidth,
                    H = B.maxHeight,
                    I = B.scrolling,
                    J = B.scrollOutside ? B.scrollbarWidth : 0,
                    K = B.margin,
                    L = p(K[1] + K[3]),
                    M = p(K[0] + K[2]);
                if (
                    (y.add(z).add(A).width("auto").height("auto").removeClass("fancybox-tmp"),
                        (a = p(z.outerWidth(!0) - z.width())),
                        (b = p(z.outerHeight(!0) - z.height())),
                        (d = L + a),
                        (e = M + b),
                        (f = n(C) ? ((u.w - d) * p(C)) / 100 : C),
                        (g = n(D) ? ((u.h - e) * p(D)) / 100 : D),
                    "iframe" === B.type)
                ) {
                    if (((s = B.content), B.autoHeight && s && 1 === s.data("ready")))
                        try {
                            s[0].contentWindow.document.location && (A.width(f).height(9999), (t = s.contents().find("body")), J && t.css("overflow-x", "hidden"), (g = t.outerHeight(!0)));
                        } catch (a) {}
                } else (B.autoWidth || B.autoHeight) && (A.addClass("fancybox-tmp"), B.autoWidth || A.width(f), B.autoHeight || A.height(g), B.autoWidth && (f = A.width()), B.autoHeight && (g = A.height()), A.removeClass("fancybox-tmp"));
                if (
                    ((C = p(f)),
                        (D = p(g)),
                        (k = f / g),
                        (E = p(n(E) ? p(E, "w") - d : E)),
                        (G = p(n(G) ? p(G, "w") - d : G)),
                        (F = p(n(F) ? p(F, "h") - e : F)),
                        (H = p(n(H) ? p(H, "h") - e : H)),
                        (i = G),
                        (j = H),
                    B.fitToView && ((G = Math.min(u.w - d, G)), (H = Math.min(u.h - e, H))),
                        (o = u.w - L),
                        (r = u.h - M),
                        B.aspectRatio
                            ? (C > G && ((C = G), (D = p(C / k))), D > H && ((D = H), (C = p(D * k))), C < E && ((C = E), (D = p(C / k))), D < F && ((D = F), (C = p(D * k))))
                            : ((C = Math.max(E, Math.min(C, G))), B.autoHeight && "iframe" !== B.type && (A.width(C), (D = A.height())), (D = Math.max(F, Math.min(D, H)))),
                        B.fitToView)
                )
                    if ((A.width(C).height(D), y.width(C + a), (l = y.width()), (m = y.height()), B.aspectRatio))
                        for (; (l > o || m > r) && C > E && D > F && !(v++ > 19); )
                            (D = Math.max(F, Math.min(H, D - 10))), (C = p(D * k)), C < E && ((C = E), (D = p(C / k))), C > G && ((C = G), (D = p(C / k))), A.width(C).height(D), y.width(C + a), (l = y.width()), (m = y.height());
                    else (C = Math.max(E, Math.min(C, C - (l - o)))), (D = Math.max(F, Math.min(D, D - (m - r))));
                J && "auto" === I && D < g && C + a + J < o && (C += J),
                    A.width(C).height(D),
                    y.width(C + a),
                    (l = y.width()),
                    (m = y.height()),
                    (w = (l > o || m > r) && C > E && D > F),
                    (x = B.aspectRatio ? C < i && D < j && C < f && D < g : (C < i || D < j) && (C < f || D < g)),
                    c.extend(B, { dim: { width: q(l), height: q(m) }, origWidth: f, origHeight: g, canShrink: w, canExpand: x, wPadding: a, hPadding: b, wrapSpace: m - z.outerHeight(!0), skinSpace: z.height() - D }),
                !s && B.autoHeight && D > F && D < H && !x && A.height("auto");
            },
            _getPosition: function (a) {
                var b = h.current,
                    c = h.getViewport(),
                    d = b.margin,
                    e = h.wrap.width() + d[1] + d[3],
                    f = h.wrap.height() + d[0] + d[2],
                    g = { position: "absolute", top: d[0], left: d[3] };
                return (
                    b.autoCenter && b.fixed && !a && f <= c.h && e <= c.w ? (g.position = "fixed") : b.locked || ((g.top += c.y), (g.left += c.x)),
                        (g.top = q(Math.max(g.top, g.top + (c.h - f) * b.topRatio))),
                        (g.left = q(Math.max(g.left, g.left + (c.w - e) * b.leftRatio))),
                        g
                );
            },
            _afterZoomIn: function () {
                var a = h.current;
                a &&
                ((h.isOpen = h.isOpened = !0),
                    h.wrap.css("overflow", "visible").addClass("fancybox-opened").hide().show(0),
                    h.update(),
                (a.closeClick || (a.nextClick && h.group.length > 1)) &&
                h.inner.css("cursor", "pointer").bind("click.fb", function (b) {
                    c(b.target).is("a") || c(b.target).parent().is("a") || (b.preventDefault(), h[a.closeClick ? "close" : "next"]());
                }),
                a.closeBtn &&
                c(a.tpl.closeBtn)
                    .appendTo(h.skin)
                    .bind("click.fb", function (a) {
                        a.preventDefault(), h.close();
                    }),
                a.arrows &&
                h.group.length > 1 &&
                ((a.loop || a.index > 0) && c(a.tpl.prev).appendTo(h.outer).bind("click.fb", h.prev), (a.loop || a.index < h.group.length - 1) && c(a.tpl.next).appendTo(h.outer).bind("click.fb", h.next)),
                    h.trigger("afterShow"),
                    a.loop || a.index !== a.group.length - 1 ? h.opts.autoPlay && !h.player.isActive && ((h.opts.autoPlay = !1), h.play(!0)) : h.play(!1));
            },
            _afterZoomOut: function (a) {
                (a = a || h.current),
                    c(".fancybox-wrap").trigger("onReset").remove(),
                    c.extend(h, { group: {}, opts: {}, router: !1, current: null, isActive: !1, isOpened: !1, isOpen: !1, isClosing: !1, wrap: null, skin: null, outer: null, inner: null }),
                    h.trigger("afterClose", a);
            },
        }),
            (h.transitions = {
                getOrigPosition: function () {
                    var a = h.current,
                        b = a.element,
                        c = a.orig,
                        d = {},
                        e = 50,
                        f = 50,
                        g = a.hPadding,
                        i = a.wPadding,
                        j = h.getViewport();
                    return (
                        !c && a.isDom && b.is(":visible") && ((c = b.find("img:first")), c.length || (c = b)),
                            l(c) ? ((d = c.offset()), c.is("img") && ((e = c.outerWidth()), (f = c.outerHeight()))) : ((d.top = j.y + (j.h - f) * a.topRatio), (d.left = j.x + (j.w - e) * a.leftRatio)),
                        ("fixed" === h.wrap.css("position") || a.locked) && ((d.top -= j.y), (d.left -= j.x)),
                            (d = { top: q(d.top - g * a.topRatio), left: q(d.left - i * a.leftRatio), width: q(e + i), height: q(f + g) })
                    );
                },
                step: function (a, b) {
                    var c,
                        d,
                        e,
                        f = b.prop,
                        g = h.current,
                        i = g.wrapSpace,
                        j = g.skinSpace;
                    ("width" !== f && "height" !== f) ||
                    ((c = b.end === b.start ? 1 : (a - b.start) / (b.end - b.start)),
                    h.isClosing && (c = 1 - c),
                        (d = "width" === f ? g.wPadding : g.hPadding),
                        (e = a - d),
                        h.skin[f](p("width" === f ? e : e - i * c)),
                        h.inner[f](p("width" === f ? e : e - i * c - j * c)));
                },
                zoomIn: function () {
                    var a = h.current,
                        b = a.pos,
                        d = a.openEffect,
                        e = "elastic" === d,
                        f = c.extend({ opacity: 1 }, b);
                    delete f.position,
                        e ? ((b = this.getOrigPosition()), a.openOpacity && (b.opacity = 0.1)) : "fade" === d && (b.opacity = 0.1),
                        h.wrap.css(b).animate(f, { duration: "none" === d ? 0 : a.openSpeed, easing: a.openEasing, step: e ? this.step : null, complete: h._afterZoomIn });
                },
                zoomOut: function () {
                    var a = h.current,
                        b = a.closeEffect,
                        c = "elastic" === b,
                        d = { opacity: 0.1 };
                    c && ((d = this.getOrigPosition()), a.closeOpacity && (d.opacity = 0.1)), h.wrap.animate(d, { duration: "none" === b ? 0 : a.closeSpeed, easing: a.closeEasing, step: c ? this.step : null, complete: h._afterZoomOut });
                },
                changeIn: function () {
                    var a,
                        b = h.current,
                        c = b.nextEffect,
                        d = b.pos,
                        e = { opacity: 1 },
                        f = h.direction;
                    (d.opacity = 0.1),
                    "elastic" === c && ((a = "down" === f || "up" === f ? "top" : "left"), "down" === f || "right" === f ? ((d[a] = q(p(d[a]) - 200)), (e[a] = "+=200px")) : ((d[a] = q(p(d[a]) + 200)), (e[a] = "-=200px"))),
                        "none" === c ? h._afterZoomIn() : h.wrap.css(d).animate(e, { duration: b.nextSpeed, easing: b.nextEasing, complete: h._afterZoomIn });
                },
                changeOut: function () {
                    var a = h.previous,
                        b = a.prevEffect,
                        d = { opacity: 0.1 },
                        e = h.direction;
                    "elastic" === b && (d["down" === e || "up" === e ? "top" : "left"] = ("up" === e || "left" === e ? "-" : "+") + "=200px"),
                        a.wrap.animate(d, {
                            duration: "none" === b ? 0 : a.prevSpeed,
                            easing: a.prevEasing,
                            complete: function () {
                                c(this).trigger("onReset").remove();
                            },
                        });
                },
            }),
            (h.helpers.overlay = {
                defaults: { closeClick: !0, speedOut: 200, showEarly: !0, css: {}, locked: !k, fixed: !0 },
                overlay: null,
                fixed: !1,
                el: c("html"),
                create: function (a) {
                    var b;
                    (a = c.extend({}, this.defaults, a)),
                    this.overlay && this.close(),
                        (b = h.coming ? h.coming.parent : a.parent),
                        (this.overlay = c('<div class="fancybox-overlay"></div>').appendTo(b && b.length ? b : "body")),
                        (this.fixed = !1),
                    a.fixed && h.defaults.fixed && (this.overlay.addClass("fancybox-overlay-fixed"), (this.fixed = !0));
                },
                open: function (a) {
                    var b = this;
                    (a = c.extend({}, this.defaults, a)),
                        this.overlay ? this.overlay.unbind(".overlay").width("auto").height("auto") : this.create(a),
                    this.fixed || (f.bind("resize.overlay", c.proxy(this.update, this)), this.update()),
                    a.closeClick &&
                    this.overlay.bind("click.overlay", function (a) {
                        if (c(a.target).hasClass("fancybox-overlay")) return h.isActive ? h.close() : b.close(), !1;
                    }),
                        this.overlay.css(a.css).show();
                },
                close: function () {
                    f.unbind("resize.overlay"),
                    this.el.hasClass("fancybox-lock") && (c(".fancybox-margin").removeClass("fancybox-margin"), this.el.removeClass("fancybox-lock"), f.scrollTop(this.scrollV).scrollLeft(this.scrollH)),
                        c(".fancybox-overlay").remove().hide(),
                        c.extend(this, { overlay: null, fixed: !1 });
                },
                update: function () {
                    var a,
                        c = "100%";
                    this.overlay.width(c).height("100%"),
                        i ? ((a = Math.max(b.documentElement.offsetWidth, b.body.offsetWidth)), g.width() > a && (c = g.width())) : g.width() > f.width() && (c = g.width()),
                        this.overlay.width(c).height(g.height());
                },
                onReady: function (a, b) {
                    var d = this.overlay;
                    c(".fancybox-overlay").stop(!0, !0), d || this.create(a), a.locked && this.fixed && b.fixed && ((b.locked = this.overlay.append(b.wrap)), (b.fixed = !1)), !0 === a.showEarly && this.beforeShow.apply(this, arguments);
                },
                beforeShow: function (a, b) {
                    b.locked &&
                    !this.el.hasClass("fancybox-lock") &&
                    (!1 !== this.fixPosition &&
                    c("*:not(object)")
                        .filter(function () {
                            return "fixed" === c(this).css("position") && !c(this).hasClass("fancybox-overlay") && !c(this).hasClass("fancybox-wrap");
                        })
                        .addClass("fancybox-margin"),
                        this.el.addClass("fancybox-margin"),
                        (this.scrollV = f.scrollTop()),
                        (this.scrollH = f.scrollLeft()),
                        this.el.addClass("fancybox-lock"),
                        f.scrollTop(this.scrollV).scrollLeft(this.scrollH)),
                        this.open(a);
                },
                onUpdate: function () {
                    this.fixed || this.update();
                },
                afterClose: function (a) {
                    this.overlay && !h.coming && this.overlay.fadeOut(a.speedOut, c.proxy(this.close, this));
                },
            }),
            (h.helpers.title = {
                defaults: { type: "float", position: "bottom" },
                beforeShow: function (a) {
                    var b,
                        d,
                        e = h.current,
                        f = e.title,
                        g = a.type;
                    if ((c.isFunction(f) && (f = f.call(e.element, e)), m(f) && "" !== c.trim(f))) {
                        switch (((b = c('<div class="fancybox-title fancybox-title-' + g + '-wrap">' + f + "</div>")), g)) {
                            case "inside":
                                d = h.skin;
                                break;
                            case "outside":
                                d = h.wrap;
                                break;
                            case "over":
                                d = h.inner;
                                break;
                            default:
                                (d = h.skin), b.appendTo("body"), i && b.width(b.width()), b.wrapInner('<span class="child"></span>'), (h.current.margin[2] += Math.abs(p(b.css("margin-bottom"))));
                        }
                        b["top" === a.position ? "prependTo" : "appendTo"](d);
                    }
                },
            }),
            (c.fn.fancybox = function (a) {
                var b,
                    d = c(this),
                    e = this.selector || "",
                    f = function (f) {
                        var g,
                            i,
                            j = c(this).blur(),
                            k = b;
                        f.ctrlKey ||
                        f.altKey ||
                        f.shiftKey ||
                        f.metaKey ||
                        j.is(".fancybox-wrap") ||
                        ((g = a.groupAttr || "data-fancybox-group"),
                            (i = j.attr(g)),
                        i || ((g = "rel"), (i = j.get(0)[g])),
                        i && "" !== i && "nofollow" !== i && ((j = e.length ? c(e) : d), (j = j.filter("[" + g + '="' + i + '"]')), (k = j.index(this))),
                            (a.index = k),
                        !1 !== h.open(j, a) && f.preventDefault());
                    };
                return (
                    (a = a || {}),
                        (b = a.index || 0),
                        e && !1 !== a.live ? g.undelegate(e, "click.fb-start").delegate(e + ":not('.fancybox-item, .fancybox-nav')", "click.fb-start", f) : d.unbind("click.fb-start").bind("click.fb-start", f),
                        this.filter("[data-fancybox-start=1]").trigger("click"),
                        this
                );
            }),
            g.ready(function () {
                var b, d;
                void 0 === c.scrollbarWidth &&
                (c.scrollbarWidth = function () {
                    var a = c('<div style="width:50px;height:50px;overflow:auto"><div/></div>').appendTo("body"),
                        b = a.children(),
                        d = b.innerWidth() - b.height(99).innerWidth();
                    return a.remove(), d;
                }),
                void 0 === c.support.fixedPosition &&
                (c.support.fixedPosition = (function () {
                    var a = c('<div style="position:fixed;top:20px;"></div>').appendTo("body"),
                        b = 20 === a[0].offsetTop || 15 === a[0].offsetTop;
                    return a.remove(), b;
                })()),
                    c.extend(h.defaults, { scrollbarWidth: c.scrollbarWidth(), fixed: c.support.fixedPosition, parent: c("body") }),
                    (b = c(a).width()),
                    e.addClass("fancybox-lock-test"),
                    (d = c(a).width()),
                    e.removeClass("fancybox-lock-test"),
                    c("<style type='text/css'>.fancybox-margin{margin-right:" + (d - b) + "px;}</style>").appendTo("head");
            });
    })(window, document, jQuery),

     */
    (function a(b, c, d) {
        function e(g, h) {
            if (!c[g]) {
                if (!b[g]) {
                    var i = "function" == typeof require && require;
                    if (!h && i) return i(g, !0);
                    if (f) return f(g, !0);
                    var j = new Error("Cannot find module '" + g + "'");
                    throw ((j.code = "MODULE_NOT_FOUND"), j);
                }
                var k = (c[g] = { exports: {} });
                b[g][0].call(
                    k.exports,
                    function (a) {
                        var c = b[g][1][a];
                        return e(c || a);
                    },
                    k,
                    k.exports,
                    a,
                    b,
                    c,
                    d
                );
            }
            return c[g].exports;
        }
        for (var f = "function" == typeof require && require, g = 0; g < d.length; g++) e(d[g]);
        return e;
    })(
        {
            1: [
                function (a, b, c) {
                    "use strict";
                    var d = a("../main");
                    "function" == typeof define && define.amd ? define(d) : ((window.PerfectScrollbar = d), void 0 === window.Ps && (window.Ps = d));
                },
                { "../main": 7 },
            ],
            2: [
                function (a, b, c) {
                    "use strict";
                    function d(a, b) {
                        var c = a.className.split(" ");
                        c.indexOf(b) < 0 && c.push(b), (a.className = c.join(" "));
                    }
                    function e(a, b) {
                        var c = a.className.split(" "),
                            d = c.indexOf(b);
                        d >= 0 && c.splice(d, 1), (a.className = c.join(" "));
                    }
                    (c.add = function (a, b) {
                        a.classList ? a.classList.add(b) : d(a, b);
                    }),
                        (c.remove = function (a, b) {
                            a.classList ? a.classList.remove(b) : e(a, b);
                        }),
                        (c.list = function (a) {
                            return a.classList ? Array.prototype.slice.apply(a.classList) : a.className.split(" ");
                        });
                },
                {},
            ],
            3: [
                function (a, b, c) {
                    "use strict";
                    function d(a, b) {
                        return window.getComputedStyle(a)[b];
                    }
                    function e(a, b, c) {
                        return "number" == typeof c && (c = c.toString() + "px"), (a.style[b] = c), a;
                    }
                    function f(a, b) {
                        for (var c in b) {
                            var d = b[c];
                            "number" == typeof d && (d = d.toString() + "px"), (a.style[c] = d);
                        }
                        return a;
                    }
                    var g = {};
                    (g.e = function (a, b) {
                        var c = document.createElement(a);
                        return (c.className = b), c;
                    }),
                        (g.appendTo = function (a, b) {
                            return b.appendChild(a), a;
                        }),
                        (g.css = function (a, b, c) {
                            return "object" == typeof b ? f(a, b) : void 0 === c ? d(a, b) : e(a, b, c);
                        }),
                        (g.matches = function (a, b) {
                            return void 0 !== a.matches
                                ? a.matches(b)
                                : void 0 !== a.matchesSelector
                                    ? a.matchesSelector(b)
                                    : void 0 !== a.webkitMatchesSelector
                                        ? a.webkitMatchesSelector(b)
                                        : void 0 !== a.mozMatchesSelector
                                            ? a.mozMatchesSelector(b)
                                            : void 0 !== a.msMatchesSelector
                                                ? a.msMatchesSelector(b)
                                                : void 0;
                        }),
                        (g.remove = function (a) {
                            void 0 !== a.remove ? a.remove() : a.parentNode && a.parentNode.removeChild(a);
                        }),
                        (g.queryChildren = function (a, b) {
                            return Array.prototype.filter.call(a.childNodes, function (a) {
                                return g.matches(a, b);
                            });
                        }),
                        (b.exports = g);
                },
                {},
            ],
            4: [
                function (a, b, c) {
                    "use strict";
                    var d = function (a) {
                        (this.element = a), (this.events = {});
                    };
                    (d.prototype.bind = function (a, b) {
                        void 0 === this.events[a] && (this.events[a] = []), this.events[a].push(b), this.element.addEventListener(a, b, !1);
                    }),
                        (d.prototype.unbind = function (a, b) {
                            var c = void 0 !== b;
                            this.events[a] = this.events[a].filter(function (d) {
                                return !(!c || d === b) || (this.element.removeEventListener(a, d, !1), !1);
                            }, this);
                        }),
                        (d.prototype.unbindAll = function () {
                            for (var a in this.events) this.unbind(a);
                        });
                    var e = function () {
                        this.eventElements = [];
                    };
                    (e.prototype.eventElement = function (a) {
                        var b = this.eventElements.filter(function (b) {
                            return b.element === a;
                        })[0];
                        return void 0 === b && ((b = new d(a)), this.eventElements.push(b)), b;
                    }),
                        (e.prototype.bind = function (a, b, c) {
                            this.eventElement(a).bind(b, c);
                        }),
                        (e.prototype.unbind = function (a, b, c) {
                            this.eventElement(a).unbind(b, c);
                        }),
                        (e.prototype.unbindAll = function () {
                            for (var a = 0; a < this.eventElements.length; a++) this.eventElements[a].unbindAll();
                        }),
                        (e.prototype.once = function (a, b, c) {
                            var d = this.eventElement(a),
                                e = function (a) {
                                    d.unbind(b, e), c(a);
                                };
                            d.bind(b, e);
                        }),
                        (b.exports = e);
                },
                {},
            ],
            5: [
                function (a, b, c) {
                    "use strict";
                    b.exports = (function () {
                        function a() {
                            return Math.floor(65536 * (1 + Math.random()))
                                .toString(16)
                                .substring(1);
                        }
                        return function () {
                            return a() + a() + "-" + a() + "-" + a() + "-" + a() + "-" + a() + a() + a();
                        };
                    })();
                },
                {},
            ],
            6: [
                function (a, b, c) {
                    "use strict";
                    var d = a("./class"),
                        e = a("./dom"),
                        f = (c.toInt = function (a) {
                            return parseInt(a, 10) || 0;
                        }),
                        g = (c.clone = function (a) {
                            if (a) {
                                if (a.constructor === Array) return a.map(g);
                                if ("object" == typeof a) {
                                    var b = {};
                                    for (var c in a) b[c] = g(a[c]);
                                    return b;
                                }
                                return a;
                            }
                            return null;
                        });
                    (c.extend = function (a, b) {
                        var c = g(a);
                        for (var d in b) c[d] = g(b[d]);
                        return c;
                    }),
                        (c.isEditable = function (a) {
                            return e.matches(a, "input,[contenteditable]") || e.matches(a, "select,[contenteditable]") || e.matches(a, "textarea,[contenteditable]") || e.matches(a, "button,[contenteditable]");
                        }),
                        (c.removePsClasses = function (a) {
                            for (var b = d.list(a), c = 0; c < b.length; c++) {
                                var e = b[c];
                                0 === e.indexOf("ps-") && d.remove(a, e);
                            }
                        }),
                        (c.outerWidth = function (a) {
                            return f(e.css(a, "width")) + f(e.css(a, "paddingLeft")) + f(e.css(a, "paddingRight")) + f(e.css(a, "borderLeftWidth")) + f(e.css(a, "borderRightWidth"));
                        }),
                        (c.startScrolling = function (a, b) {
                            d.add(a, "ps-in-scrolling"), void 0 !== b ? d.add(a, "ps-" + b) : (d.add(a, "ps-x"), d.add(a, "ps-y"));
                        }),
                        (c.stopScrolling = function (a, b) {
                            d.remove(a, "ps-in-scrolling"), void 0 !== b ? d.remove(a, "ps-" + b) : (d.remove(a, "ps-x"), d.remove(a, "ps-y"));
                        }),
                        (c.env = {
                            isWebKit: "WebkitAppearance" in document.documentElement.style,
                            supportsTouch: "ontouchstart" in window || (window.DocumentTouch && document instanceof window.DocumentTouch),
                            supportsIePointer: null !== window.navigator.msMaxTouchPoints,
                        });
                },
                { "./class": 2, "./dom": 3 },
            ],
            7: [
                function (a, b, c) {
                    "use strict";
                    var d = a("./plugin/destroy"),
                        e = a("./plugin/initialize"),
                        f = a("./plugin/update");
                    b.exports = { initialize: e, update: f, destroy: d };
                },
                { "./plugin/destroy": 9, "./plugin/initialize": 17, "./plugin/update": 21 },
            ],
            8: [
                function (a, b, c) {
                    "use strict";
                    b.exports = {
                        handlers: ["click-rail", "drag-scrollbar", "keyboard", "wheel", "touch"],
                        maxScrollbarLength: null,
                        minScrollbarLength: null,
                        scrollXMarginOffset: 0,
                        scrollYMarginOffset: 0,
                        suppressScrollX: !1,
                        suppressScrollY: !1,
                        swipePropagation: !0,
                        useBothWheelAxes: !1,
                        wheelPropagation: !1,
                        wheelSpeed: 1,
                        theme: "default",
                    };
                },
                {},
            ],
            9: [
                function (a, b, c) {
                    "use strict";
                    var d = a("../lib/helper"),
                        e = a("../lib/dom"),
                        f = a("./instances");
                    b.exports = function (a) {
                        var b = f.get(a);
                        b && (b.event.unbindAll(), e.remove(b.scrollbarX), e.remove(b.scrollbarY), e.remove(b.scrollbarXRail), e.remove(b.scrollbarYRail), d.removePsClasses(a), f.remove(a));
                    };
                },
                { "../lib/dom": 3, "../lib/helper": 6, "./instances": 18 },
            ],
            10: [
                function (a, b, c) {
                    "use strict";
                    function d(a, b) {
                        function c(a) {
                            return a.getBoundingClientRect();
                        }
                        var d = function (a) {
                            a.stopPropagation();
                        };
                        b.event.bind(b.scrollbarY, "click", d),
                            b.event.bind(b.scrollbarYRail, "click", function (d) {
                                var e = d.pageY - window.pageYOffset - c(b.scrollbarYRail).top,
                                    h = e > b.scrollbarYTop ? 1 : -1;
                                g(a, "top", a.scrollTop + h * b.containerHeight), f(a), d.stopPropagation();
                            }),
                            b.event.bind(b.scrollbarX, "click", d),
                            b.event.bind(b.scrollbarXRail, "click", function (d) {
                                var e = d.pageX - window.pageXOffset - c(b.scrollbarXRail).left,
                                    h = e > b.scrollbarXLeft ? 1 : -1;
                                g(a, "left", a.scrollLeft + h * b.containerWidth), f(a), d.stopPropagation();
                            });
                    }
                    var e = a("../instances"),
                        f = a("../update-geometry"),
                        g = a("../update-scroll");
                    b.exports = function (a) {
                        d(a, e.get(a));
                    };
                },
                { "../instances": 18, "../update-geometry": 19, "../update-scroll": 20 },
            ],
            11: [
                function (a, b, c) {
                    "use strict";
                    function d(a, b) {
                        function c(c) {
                            var e = d + c * b.railXRatio,
                                g = Math.max(0, b.scrollbarXRail.getBoundingClientRect().left) + b.railXRatio * (b.railXWidth - b.scrollbarXWidth);
                            b.scrollbarXLeft = e < 0 ? 0 : e > g ? g : e;
                            var h = f.toInt((b.scrollbarXLeft * (b.contentWidth - b.containerWidth)) / (b.containerWidth - b.railXRatio * b.scrollbarXWidth)) - b.negativeScrollAdjustment;
                            j(a, "left", h);
                        }
                        var d = null,
                            e = null,
                            h = function (b) {
                                c(b.pageX - e), i(a), b.stopPropagation(), b.preventDefault();
                            },
                            k = function () {
                                f.stopScrolling(a, "x"), b.event.unbind(b.ownerDocument, "mousemove", h);
                            };
                        b.event.bind(b.scrollbarX, "mousedown", function (c) {
                            (e = c.pageX),
                                (d = f.toInt(g.css(b.scrollbarX, "left")) * b.railXRatio),
                                f.startScrolling(a, "x"),
                                b.event.bind(b.ownerDocument, "mousemove", h),
                                b.event.once(b.ownerDocument, "mouseup", k),
                                c.stopPropagation(),
                                c.preventDefault();
                        });
                    }
                    function e(a, b) {
                        function c(c) {
                            var e = d + c * b.railYRatio,
                                g = Math.max(0, b.scrollbarYRail.getBoundingClientRect().top) + b.railYRatio * (b.railYHeight - b.scrollbarYHeight);
                            b.scrollbarYTop = e < 0 ? 0 : e > g ? g : e;
                            var h = f.toInt((b.scrollbarYTop * (b.contentHeight - b.containerHeight)) / (b.containerHeight - b.railYRatio * b.scrollbarYHeight));
                            j(a, "top", h);
                        }
                        var d = null,
                            e = null,
                            h = function (b) {
                                c(b.pageY - e), i(a), b.stopPropagation(), b.preventDefault();
                            },
                            k = function () {
                                f.stopScrolling(a, "y"), b.event.unbind(b.ownerDocument, "mousemove", h);
                            };
                        b.event.bind(b.scrollbarY, "mousedown", function (c) {
                            (e = c.pageY),
                                (d = f.toInt(g.css(b.scrollbarY, "top")) * b.railYRatio),
                                f.startScrolling(a, "y"),
                                b.event.bind(b.ownerDocument, "mousemove", h),
                                b.event.once(b.ownerDocument, "mouseup", k),
                                c.stopPropagation(),
                                c.preventDefault();
                        });
                    }
                    var f = a("../../lib/helper"),
                        g = a("../../lib/dom"),
                        h = a("../instances"),
                        i = a("../update-geometry"),
                        j = a("../update-scroll");
                    b.exports = function (a) {
                        var b = h.get(a);
                        d(a, b), e(a, b);
                    };
                },
                { "../../lib/dom": 3, "../../lib/helper": 6, "../instances": 18, "../update-geometry": 19, "../update-scroll": 20 },
            ],
            12: [
                function (a, b, c) {
                    "use strict";
                    function d(a, b) {
                        function c(c, d) {
                            var e = a.scrollTop;
                            if (0 === c) {
                                if (!b.scrollbarYActive) return !1;
                                if ((0 === e && d > 0) || (e >= b.contentHeight - b.containerHeight && d < 0)) return !b.settings.wheelPropagation;
                            }
                            var f = a.scrollLeft;
                            if (0 === d) {
                                if (!b.scrollbarXActive) return !1;
                                if ((0 === f && c < 0) || (f >= b.contentWidth - b.containerWidth && c > 0)) return !b.settings.wheelPropagation;
                            }
                            return !0;
                        }
                        var d = !1;
                        b.event.bind(a, "mouseenter", function () {
                            d = !0;
                        }),
                            b.event.bind(a, "mouseleave", function () {
                                d = !1;
                            });
                        var g = !1;
                        b.event.bind(b.ownerDocument, "keydown", function (j) {
                            if (!((j.isDefaultPrevented && j.isDefaultPrevented()) || j.defaultPrevented)) {
                                var k = f.matches(b.scrollbarX, ":focus") || f.matches(b.scrollbarY, ":focus");
                                if (d || k) {
                                    var l = document.activeElement ? document.activeElement : b.ownerDocument.activeElement;
                                    if (l) {
                                        if ("IFRAME" === l.tagName) l = l.contentDocument.activeElement;
                                        else for (; l.shadowRoot; ) l = l.shadowRoot.activeElement;
                                        if (e.isEditable(l)) return;
                                    }
                                    var m = 0,
                                        n = 0;
                                    switch (j.which) {
                                        case 37:
                                            m = j.metaKey ? -b.contentWidth : j.altKey ? -b.containerWidth : -30;
                                            break;
                                        case 38:
                                            n = j.metaKey ? b.contentHeight : j.altKey ? b.containerHeight : 30;
                                            break;
                                        case 39:
                                            m = j.metaKey ? b.contentWidth : j.altKey ? b.containerWidth : 30;
                                            break;
                                        case 40:
                                            n = j.metaKey ? -b.contentHeight : j.altKey ? -b.containerHeight : -30;
                                            break;
                                        case 33:
                                            n = 90;
                                            break;
                                        case 32:
                                            n = j.shiftKey ? 90 : -90;
                                            break;
                                        case 34:
                                            n = -90;
                                            break;
                                        case 35:
                                            n = j.ctrlKey ? -b.contentHeight : -b.containerHeight;
                                            break;
                                        case 36:
                                            n = j.ctrlKey ? a.scrollTop : b.containerHeight;
                                            break;
                                        default:
                                            return;
                                    }
                                    i(a, "top", a.scrollTop - n), i(a, "left", a.scrollLeft + m), h(a), (g = c(m, n)) && j.preventDefault();
                                }
                            }
                        });
                    }
                    var e = a("../../lib/helper"),
                        f = a("../../lib/dom"),
                        g = a("../instances"),
                        h = a("../update-geometry"),
                        i = a("../update-scroll");
                    b.exports = function (a) {
                        d(a, g.get(a));
                    };
                },
                { "../../lib/dom": 3, "../../lib/helper": 6, "../instances": 18, "../update-geometry": 19, "../update-scroll": 20 },
            ],
            13: [
                function (a, b, c) {
                    "use strict";
                    function d(a, b) {
                        function c(c, d) {
                            var e = a.scrollTop;
                            if (0 === c) {
                                if (!b.scrollbarYActive) return !1;
                                if ((0 === e && d > 0) || (e >= b.contentHeight - b.containerHeight && d < 0)) return !b.settings.wheelPropagation;
                            }
                            var f = a.scrollLeft;
                            if (0 === d) {
                                if (!b.scrollbarXActive) return !1;
                                if ((0 === f && c < 0) || (f >= b.contentWidth - b.containerWidth && c > 0)) return !b.settings.wheelPropagation;
                            }
                            return !0;
                        }
                        function d(a) {
                            var b = a.deltaX,
                                c = -1 * a.deltaY;
                            return (
                                (void 0 !== b && void 0 !== c) || ((b = (-1 * a.wheelDeltaX) / 6), (c = a.wheelDeltaY / 6)),
                                a.deltaMode && 1 === a.deltaMode && ((b *= 10), (c *= 10)),
                                b !== b && c !== c && ((b = 0), (c = a.wheelDelta)),
                                    a.shiftKey ? [-c, -b] : [b, c]
                            );
                        }
                        function e(b, c) {
                            var d = a.querySelector("textarea:hover, select[multiple]:hover, .ps-child:hover");
                            if (d) {
                                if (!window.getComputedStyle(d).overflow.match(/(scroll|auto)/)) return !1;
                                var e = d.scrollHeight - d.clientHeight;
                                if (e > 0 && !((0 === d.scrollTop && c > 0) || (d.scrollTop === e && c < 0))) return !0;
                                var f = d.scrollLeft - d.clientWidth;
                                if (f > 0 && !((0 === d.scrollLeft && b < 0) || (d.scrollLeft === f && b > 0))) return !0;
                            }
                            return !1;
                        }
                        function h(h) {
                            var j = d(h),
                                k = j[0],
                                l = j[1];
                            e(k, l) ||
                            ((i = !1),
                                b.settings.useBothWheelAxes
                                    ? b.scrollbarYActive && !b.scrollbarXActive
                                        ? (l ? g(a, "top", a.scrollTop - l * b.settings.wheelSpeed) : g(a, "top", a.scrollTop + k * b.settings.wheelSpeed), (i = !0))
                                        : b.scrollbarXActive && !b.scrollbarYActive && (k ? g(a, "left", a.scrollLeft + k * b.settings.wheelSpeed) : g(a, "left", a.scrollLeft - l * b.settings.wheelSpeed), (i = !0))
                                    : (g(a, "top", a.scrollTop - l * b.settings.wheelSpeed), g(a, "left", a.scrollLeft + k * b.settings.wheelSpeed)),
                                f(a),
                            (i = i || c(k, l)) && (h.stopPropagation(), h.preventDefault()));
                        }
                        var i = !1;
                        void 0 !== window.onwheel ? b.event.bind(a, "wheel", h) : void 0 !== window.onmousewheel && b.event.bind(a, "mousewheel", h);
                    }
                    var e = a("../instances"),
                        f = a("../update-geometry"),
                        g = a("../update-scroll");
                    b.exports = function (a) {
                        d(a, e.get(a));
                    };
                },
                { "../instances": 18, "../update-geometry": 19, "../update-scroll": 20 },
            ],
            14: [
                function (a, b, c) {
                    "use strict";
                    function d(a, b) {
                        b.event.bind(a, "scroll", function () {
                            f(a);
                        });
                    }
                    var e = a("../instances"),
                        f = a("../update-geometry");
                    b.exports = function (a) {
                        d(a, e.get(a));
                    };
                },
                { "../instances": 18, "../update-geometry": 19 },
            ],
            15: [
                function (a, b, c) {
                    "use strict";
                    function d(a, b) {
                        function c() {
                            var a = window.getSelection ? window.getSelection() : document.getSelection ? document.getSelection() : "";
                            return 0 === a.toString().length ? null : a.getRangeAt(0).commonAncestorContainer;
                        }
                        function d() {
                            j ||
                            (j = setInterval(function () {
                                return f.get(a) ? (h(a, "top", a.scrollTop + k.top), h(a, "left", a.scrollLeft + k.left), void g(a)) : void clearInterval(j);
                            }, 50));
                        }
                        function i() {
                            j && (clearInterval(j), (j = null)), e.stopScrolling(a);
                        }
                        var j = null,
                            k = { top: 0, left: 0 },
                            l = !1;
                        b.event.bind(b.ownerDocument, "selectionchange", function () {
                            a.contains(c()) ? (l = !0) : ((l = !1), i());
                        }),
                            b.event.bind(window, "mouseup", function () {
                                l && ((l = !1), i());
                            }),
                            b.event.bind(window, "keyup", function () {
                                l && ((l = !1), i());
                            }),
                            b.event.bind(window, "mousemove", function (b) {
                                if (l) {
                                    var c = { x: b.pageX, y: b.pageY },
                                        f = { left: a.offsetLeft, right: a.offsetLeft + a.offsetWidth, top: a.offsetTop, bottom: a.offsetTop + a.offsetHeight };
                                    c.x < f.left + 3 ? ((k.left = -5), e.startScrolling(a, "x")) : c.x > f.right - 3 ? ((k.left = 5), e.startScrolling(a, "x")) : (k.left = 0),
                                        c.y < f.top + 3 ? ((k.top = f.top + 3 - c.y < 5 ? -5 : -20), e.startScrolling(a, "y")) : c.y > f.bottom - 3 ? ((k.top = c.y - f.bottom + 3 < 5 ? 5 : 20), e.startScrolling(a, "y")) : (k.top = 0),
                                        0 === k.top && 0 === k.left ? i() : d();
                                }
                            });
                    }
                    var e = a("../../lib/helper"),
                        f = a("../instances"),
                        g = a("../update-geometry"),
                        h = a("../update-scroll");
                    b.exports = function (a) {
                        d(a, f.get(a));
                    };
                },
                { "../../lib/helper": 6, "../instances": 18, "../update-geometry": 19, "../update-scroll": 20 },
            ],
            16: [
                function (a, b, c) {
                    "use strict";
                    function d(a, b, c, d) {
                        function e(c, d) {
                            var e = a.scrollTop,
                                f = a.scrollLeft,
                                g = Math.abs(c),
                                h = Math.abs(d);
                            if (h > g) {
                                if ((d < 0 && e === b.contentHeight - b.containerHeight) || (d > 0 && 0 === e)) return !b.settings.swipePropagation;
                            } else if (g > h && ((c < 0 && f === b.contentWidth - b.containerWidth) || (c > 0 && 0 === f))) return !b.settings.swipePropagation;
                            return !0;
                        }
                        function i(b, c) {
                            h(a, "top", a.scrollTop - c), h(a, "left", a.scrollLeft - b), g(a);
                        }
                        function j() {
                            u = !0;
                        }
                        function k() {
                            u = !1;
                        }
                        function l(a) {
                            return a.targetTouches ? a.targetTouches[0] : a;
                        }
                        function m(a) {
                            return !(!a.targetTouches || 1 !== a.targetTouches.length) || !(!a.pointerType || "mouse" === a.pointerType || a.pointerType === a.MSPOINTER_TYPE_MOUSE);
                        }
                        function n(a) {
                            if (m(a)) {
                                v = !0;
                                var b = l(a);
                                (q.pageX = b.pageX), (q.pageY = b.pageY), (r = new Date().getTime()), null !== t && clearInterval(t), a.stopPropagation();
                            }
                        }
                        function o(a) {
                            if ((!v && b.settings.swipePropagation && n(a), !u && v && m(a))) {
                                var c = l(a),
                                    d = { pageX: c.pageX, pageY: c.pageY },
                                    f = d.pageX - q.pageX,
                                    g = d.pageY - q.pageY;
                                i(f, g), (q = d);
                                var h = new Date().getTime(),
                                    j = h - r;
                                j > 0 && ((s.x = f / j), (s.y = g / j), (r = h)), e(f, g) && (a.stopPropagation(), a.preventDefault());
                            }
                        }
                        function p() {
                            !u &&
                            v &&
                            ((v = !1),
                                clearInterval(t),
                                (t = setInterval(function () {
                                    return f.get(a) && (s.x || s.y) ? (Math.abs(s.x) < 0.01 && Math.abs(s.y) < 0.01 ? void clearInterval(t) : (i(30 * s.x, 30 * s.y), (s.x *= 0.8), void (s.y *= 0.8))) : void clearInterval(t);
                                }, 10)));
                        }
                        var q = {},
                            r = 0,
                            s = {},
                            t = null,
                            u = !1,
                            v = !1;
                        c
                            ? (b.event.bind(window, "touchstart", j), b.event.bind(window, "touchend", k), b.event.bind(a, "touchstart", n), b.event.bind(a, "touchmove", o), b.event.bind(a, "touchend", p))
                            : d &&
                            (window.PointerEvent
                                ? (b.event.bind(window, "pointerdown", j), b.event.bind(window, "pointerup", k), b.event.bind(a, "pointerdown", n), b.event.bind(a, "pointermove", o), b.event.bind(a, "pointerup", p))
                                : window.MSPointerEvent &&
                                (b.event.bind(window, "MSPointerDown", j), b.event.bind(window, "MSPointerUp", k), b.event.bind(a, "MSPointerDown", n), b.event.bind(a, "MSPointerMove", o), b.event.bind(a, "MSPointerUp", p)));
                    }
                    var e = a("../../lib/helper"),
                        f = a("../instances"),
                        g = a("../update-geometry"),
                        h = a("../update-scroll");
                    b.exports = function (a) {
                        if (e.env.supportsTouch || e.env.supportsIePointer) {
                            d(a, f.get(a), e.env.supportsTouch, e.env.supportsIePointer);
                        }
                    };
                },
                { "../../lib/helper": 6, "../instances": 18, "../update-geometry": 19, "../update-scroll": 20 },
            ],
            17: [
                function (a, b, c) {
                    "use strict";
                    var d = a("../lib/helper"),
                        e = a("../lib/class"),
                        f = a("./instances"),
                        g = a("./update-geometry"),
                        h = {
                            "click-rail": a("./handler/click-rail"),
                            "drag-scrollbar": a("./handler/drag-scrollbar"),
                            keyboard: a("./handler/keyboard"),
                            wheel: a("./handler/mouse-wheel"),
                            touch: a("./handler/touch"),
                            selection: a("./handler/selection"),
                        },
                        i = a("./handler/native-scroll");
                    b.exports = function (a, b) {
                        (b = "object" == typeof b ? b : {}), e.add(a, "ps-container");
                        var c = f.add(a);
                        (c.settings = d.extend(c.settings, b)),
                            e.add(a, "ps-theme-" + c.settings.theme),
                            c.settings.handlers.forEach(function (b) {
                                h[b](a);
                            }),
                            i(a),
                            g(a);
                    };
                },
                {
                    "../lib/class": 2,
                    "../lib/helper": 6,
                    "./handler/click-rail": 10,
                    "./handler/drag-scrollbar": 11,
                    "./handler/keyboard": 12,
                    "./handler/mouse-wheel": 13,
                    "./handler/native-scroll": 14,
                    "./handler/selection": 15,
                    "./handler/touch": 16,
                    "./instances": 18,
                    "./update-geometry": 19,
                },
            ],
            18: [
                function (a, b, c) {
                    "use strict";
                    function d(a) {
                        function b() {
                            i.add(a, "ps-focus");
                        }
                        function c() {
                            i.remove(a, "ps-focus");
                        }
                        var d = this;
                        (d.settings = h.clone(j)),
                            (d.containerWidth = null),
                            (d.containerHeight = null),
                            (d.contentWidth = null),
                            (d.contentHeight = null),
                            (d.isRtl = "rtl" === k.css(a, "direction")),
                            (d.isNegativeScroll = (function () {
                                var b = a.scrollLeft,
                                    c = null;
                                return (a.scrollLeft = -1), (c = a.scrollLeft < 0), (a.scrollLeft = b), c;
                            })()),
                            (d.negativeScrollAdjustment = d.isNegativeScroll ? a.scrollWidth - a.clientWidth : 0),
                            (d.event = new l()),
                            (d.ownerDocument = a.ownerDocument || document),
                            (d.scrollbarXRail = k.appendTo(k.e("div", "ps-scrollbar-x-rail"), a)),
                            (d.scrollbarX = k.appendTo(k.e("div", "ps-scrollbar-x"), d.scrollbarXRail)),
                            d.scrollbarX.setAttribute("tabindex", 0),
                            d.event.bind(d.scrollbarX, "focus", b),
                            d.event.bind(d.scrollbarX, "blur", c),
                            (d.scrollbarXActive = null),
                            (d.scrollbarXWidth = null),
                            (d.scrollbarXLeft = null),
                            (d.scrollbarXBottom = h.toInt(k.css(d.scrollbarXRail, "bottom"))),
                            (d.isScrollbarXUsingBottom = d.scrollbarXBottom === d.scrollbarXBottom),
                            (d.scrollbarXTop = d.isScrollbarXUsingBottom ? null : h.toInt(k.css(d.scrollbarXRail, "top"))),
                            (d.railBorderXWidth = h.toInt(k.css(d.scrollbarXRail, "borderLeftWidth")) + h.toInt(k.css(d.scrollbarXRail, "borderRightWidth"))),
                            k.css(d.scrollbarXRail, "display", "block"),
                            (d.railXMarginWidth = h.toInt(k.css(d.scrollbarXRail, "marginLeft")) + h.toInt(k.css(d.scrollbarXRail, "marginRight"))),
                            k.css(d.scrollbarXRail, "display", ""),
                            (d.railXWidth = null),
                            (d.railXRatio = null),
                            (d.scrollbarYRail = k.appendTo(k.e("div", "ps-scrollbar-y-rail"), a)),
                            (d.scrollbarY = k.appendTo(k.e("div", "ps-scrollbar-y"), d.scrollbarYRail)),
                            d.scrollbarY.setAttribute("tabindex", 0),
                            d.event.bind(d.scrollbarY, "focus", b),
                            d.event.bind(d.scrollbarY, "blur", c),
                            (d.scrollbarYActive = null),
                            (d.scrollbarYHeight = null),
                            (d.scrollbarYTop = null),
                            (d.scrollbarYRight = h.toInt(k.css(d.scrollbarYRail, "right"))),
                            (d.isScrollbarYUsingRight = d.scrollbarYRight === d.scrollbarYRight),
                            (d.scrollbarYLeft = d.isScrollbarYUsingRight ? null : h.toInt(k.css(d.scrollbarYRail, "left"))),
                            (d.scrollbarYOuterWidth = d.isRtl ? h.outerWidth(d.scrollbarY) : null),
                            (d.railBorderYWidth = h.toInt(k.css(d.scrollbarYRail, "borderTopWidth")) + h.toInt(k.css(d.scrollbarYRail, "borderBottomWidth"))),
                            k.css(d.scrollbarYRail, "display", "block"),
                            (d.railYMarginHeight = h.toInt(k.css(d.scrollbarYRail, "marginTop")) + h.toInt(k.css(d.scrollbarYRail, "marginBottom"))),
                            k.css(d.scrollbarYRail, "display", ""),
                            (d.railYHeight = null),
                            (d.railYRatio = null);
                    }
                    function e(a) {
                        return a.getAttribute("data-ps-id");
                    }
                    function f(a, b) {
                        a.setAttribute("data-ps-id", b);
                    }
                    function g(a) {
                        a.removeAttribute("data-ps-id");
                    }
                    var h = a("../lib/helper"),
                        i = a("../lib/class"),
                        j = a("./default-setting"),
                        k = a("../lib/dom"),
                        l = a("../lib/event-manager"),
                        m = a("../lib/guid"),
                        n = {};
                    (c.add = function (a) {
                        var b = m();
                        return f(a, b), (n[b] = new d(a)), n[b];
                    }),
                        (c.remove = function (a) {
                            delete n[e(a)], g(a);
                        }),
                        (c.get = function (a) {
                            return n[e(a)];
                        });
                },
                { "../lib/class": 2, "../lib/dom": 3, "../lib/event-manager": 4, "../lib/guid": 5, "../lib/helper": 6, "./default-setting": 8 },
            ],
            19: [
                function (a, b, c) {
                    "use strict";
                    function d(a, b) {
                        return a.settings.minScrollbarLength && (b = Math.max(b, a.settings.minScrollbarLength)), a.settings.maxScrollbarLength && (b = Math.min(b, a.settings.maxScrollbarLength)), b;
                    }
                    function e(a, b) {
                        var c = { width: b.railXWidth };
                        b.isRtl ? (c.left = b.negativeScrollAdjustment + a.scrollLeft + b.containerWidth - b.contentWidth) : (c.left = a.scrollLeft),
                            b.isScrollbarXUsingBottom ? (c.bottom = b.scrollbarXBottom - a.scrollTop) : (c.top = b.scrollbarXTop + a.scrollTop),
                            h.css(b.scrollbarXRail, c);
                        var d = { top: a.scrollTop, height: b.railYHeight };
                        b.isScrollbarYUsingRight
                            ? b.isRtl
                                ? (d.right = b.contentWidth - (b.negativeScrollAdjustment + a.scrollLeft) - b.scrollbarYRight - b.scrollbarYOuterWidth)
                                : (d.right = b.scrollbarYRight - a.scrollLeft)
                            : b.isRtl
                                ? (d.left = b.negativeScrollAdjustment + a.scrollLeft + 2 * b.containerWidth - b.contentWidth - b.scrollbarYLeft - b.scrollbarYOuterWidth)
                                : (d.left = b.scrollbarYLeft + a.scrollLeft),
                            h.css(b.scrollbarYRail, d),
                            h.css(b.scrollbarX, { left: b.scrollbarXLeft, width: b.scrollbarXWidth - b.railBorderXWidth }),
                            h.css(b.scrollbarY, { top: b.scrollbarYTop, height: b.scrollbarYHeight - b.railBorderYWidth });
                    }
                    var f = a("../lib/helper"),
                        g = a("../lib/class"),
                        h = a("../lib/dom"),
                        i = a("./instances"),
                        j = a("./update-scroll");
                    b.exports = function (a) {
                        var b = i.get(a);
                        (b.containerWidth = a.clientWidth), (b.containerHeight = a.clientHeight), (b.contentWidth = a.scrollWidth), (b.contentHeight = a.scrollHeight);
                        var c;
                        a.contains(b.scrollbarXRail) ||
                        ((c = h.queryChildren(a, ".ps-scrollbar-x-rail")),
                        c.length > 0 &&
                        c.forEach(function (a) {
                            h.remove(a);
                        }),
                            h.appendTo(b.scrollbarXRail, a)),
                        a.contains(b.scrollbarYRail) ||
                        ((c = h.queryChildren(a, ".ps-scrollbar-y-rail")),
                        c.length > 0 &&
                        c.forEach(function (a) {
                            h.remove(a);
                        }),
                            h.appendTo(b.scrollbarYRail, a)),
                            !b.settings.suppressScrollX && b.containerWidth + b.settings.scrollXMarginOffset < b.contentWidth
                                ? ((b.scrollbarXActive = !0),
                                    (b.railXWidth = b.containerWidth - b.railXMarginWidth),
                                    (b.railXRatio = b.containerWidth / b.railXWidth),
                                    (b.scrollbarXWidth = d(b, f.toInt((b.railXWidth * b.containerWidth) / b.contentWidth))),
                                    (b.scrollbarXLeft = f.toInt(((b.negativeScrollAdjustment + a.scrollLeft) * (b.railXWidth - b.scrollbarXWidth)) / (b.contentWidth - b.containerWidth))))
                                : (b.scrollbarXActive = !1),
                            !b.settings.suppressScrollY && b.containerHeight + b.settings.scrollYMarginOffset < b.contentHeight
                                ? ((b.scrollbarYActive = !0),
                                    (b.railYHeight = b.containerHeight - b.railYMarginHeight),
                                    (b.railYRatio = b.containerHeight / b.railYHeight),
                                    (b.scrollbarYHeight = d(b, f.toInt((b.railYHeight * b.containerHeight) / b.contentHeight))),
                                    (b.scrollbarYTop = f.toInt((a.scrollTop * (b.railYHeight - b.scrollbarYHeight)) / (b.contentHeight - b.containerHeight))))
                                : (b.scrollbarYActive = !1),
                        b.scrollbarXLeft >= b.railXWidth - b.scrollbarXWidth && (b.scrollbarXLeft = b.railXWidth - b.scrollbarXWidth),
                        b.scrollbarYTop >= b.railYHeight - b.scrollbarYHeight && (b.scrollbarYTop = b.railYHeight - b.scrollbarYHeight),
                            e(a, b),
                            b.scrollbarXActive ? g.add(a, "ps-active-x") : (g.remove(a, "ps-active-x"), (b.scrollbarXWidth = 0), (b.scrollbarXLeft = 0), j(a, "left", 0)),
                            b.scrollbarYActive ? g.add(a, "ps-active-y") : (g.remove(a, "ps-active-y"), (b.scrollbarYHeight = 0), (b.scrollbarYTop = 0), j(a, "top", 0));
                    };
                },
                { "../lib/class": 2, "../lib/dom": 3, "../lib/helper": 6, "./instances": 18, "./update-scroll": 20 },
            ],
            20: [
                function (a, b, c) {
                    "use strict";
                    var d,
                        e,
                        f = a("./instances"),
                        g = function (a) {
                            var b = document.createEvent("Event");
                            return b.initEvent(a, !0, !0), b;
                        };
                    b.exports = function (a, b, c) {
                        if (void 0 === a) throw "You must provide an element to the update-scroll function";
                        if (void 0 === b) throw "You must provide an axis to the update-scroll function";
                        if (void 0 === c) throw "You must provide a value to the update-scroll function";
                        "top" === b && c <= 0 && ((a.scrollTop = c = 0), a.dispatchEvent(g("ps-y-reach-start"))), "left" === b && c <= 0 && ((a.scrollLeft = c = 0), a.dispatchEvent(g("ps-x-reach-start")));
                        var h = f.get(a);
                        "top" === b && c >= h.contentHeight - h.containerHeight && ((c = h.contentHeight - h.containerHeight), c - a.scrollTop <= 1 ? (c = a.scrollTop) : (a.scrollTop = c), a.dispatchEvent(g("ps-y-reach-end"))),
                        "left" === b && c >= h.contentWidth - h.containerWidth && ((c = h.contentWidth - h.containerWidth), c - a.scrollLeft <= 1 ? (c = a.scrollLeft) : (a.scrollLeft = c), a.dispatchEvent(g("ps-x-reach-end"))),
                        d || (d = a.scrollTop),
                        e || (e = a.scrollLeft),
                        "top" === b && c < d && a.dispatchEvent(g("ps-scroll-up")),
                        "top" === b && c > d && a.dispatchEvent(g("ps-scroll-down")),
                        "left" === b && c < e && a.dispatchEvent(g("ps-scroll-left")),
                        "left" === b && c > e && a.dispatchEvent(g("ps-scroll-right")),
                        "top" === b && ((a.scrollTop = d = c), a.dispatchEvent(g("ps-scroll-y"))),
                        "left" === b && ((a.scrollLeft = e = c), a.dispatchEvent(g("ps-scroll-x")));
                    };
                },
                { "./instances": 18 },
            ],
            21: [
                function (a, b, c) {
                    "use strict";
                    var d = a("../lib/helper"),
                        e = a("../lib/dom"),
                        f = a("./instances"),
                        g = a("./update-geometry"),
                        h = a("./update-scroll");
                    b.exports = function (a) {
                        var b = f.get(a);
                        b &&
                        ((b.negativeScrollAdjustment = b.isNegativeScroll ? a.scrollWidth - a.clientWidth : 0),
                            e.css(b.scrollbarXRail, "display", "block"),
                            e.css(b.scrollbarYRail, "display", "block"),
                            (b.railXMarginWidth = d.toInt(e.css(b.scrollbarXRail, "marginLeft")) + d.toInt(e.css(b.scrollbarXRail, "marginRight"))),
                            (b.railYMarginHeight = d.toInt(e.css(b.scrollbarYRail, "marginTop")) + d.toInt(e.css(b.scrollbarYRail, "marginBottom"))),
                            e.css(b.scrollbarXRail, "display", "none"),
                            e.css(b.scrollbarYRail, "display", "none"),
                            g(a),
                            h(a, "top", a.scrollTop),
                            h(a, "left", a.scrollLeft),
                            e.css(b.scrollbarXRail, "display", ""),
                            e.css(b.scrollbarYRail, "display", ""));
                    };
                },
                { "../lib/dom": 3, "../lib/helper": 6, "./instances": 18, "./update-geometry": 19, "./update-scroll": 20 },
            ],
        },
        {},
        [1]
    ),
    (function a(b, c, d) {
        function e(g, h) {
            if (!c[g]) {
                if (!b[g]) {
                    var i = "function" == typeof require && require;
                    if (!h && i) return i(g, !0);
                    if (f) return f(g, !0);
                    var j = new Error("Cannot find module '" + g + "'");
                    throw ((j.code = "MODULE_NOT_FOUND"), j);
                }
                var k = (c[g] = { exports: {} });
                b[g][0].call(
                    k.exports,
                    function (a) {
                        var c = b[g][1][a];
                        return e(c || a);
                    },
                    k,
                    k.exports,
                    a,
                    b,
                    c,
                    d
                );
            }
            return c[g].exports;
        }
        for (var f = "function" == typeof require && require, g = 0; g < d.length; g++) e(d[g]);
        return e;
    })(
        {
            1: [
                function (a, b, c) {
                    "use strict";
                    function d(a) {
                        a.fn.perfectScrollbar = function (a) {
                            return this.each(function () {
                                if ("object" == typeof a || void 0 === a) {
                                    var b = a;
                                    f.get(this) || e.initialize(this, b);
                                } else {
                                    var c = a;
                                    "update" === c ? e.update(this) : "destroy" === c && e.destroy(this);
                                }
                            });
                        };
                    }
                    var e = a("../main"),
                        f = a("../plugin/instances");
                    if ("function" == typeof define && define.amd) define(["jquery"], d);
                    else {
                        var g = window.jQuery ? window.jQuery : window.$;
                        void 0 !== g && d(g);
                    }
                    b.exports = d;
                },
                { "../main": 7, "../plugin/instances": 18 },
            ],
            2: [
                function (a, b, c) {
                    "use strict";
                    function d(a, b) {
                        var c = a.className.split(" ");
                        c.indexOf(b) < 0 && c.push(b), (a.className = c.join(" "));
                    }
                    function e(a, b) {
                        var c = a.className.split(" "),
                            d = c.indexOf(b);
                        d >= 0 && c.splice(d, 1), (a.className = c.join(" "));
                    }
                    (c.add = function (a, b) {
                        a.classList ? a.classList.add(b) : d(a, b);
                    }),
                        (c.remove = function (a, b) {
                            a.classList ? a.classList.remove(b) : e(a, b);
                        }),
                        (c.list = function (a) {
                            return a.classList ? Array.prototype.slice.apply(a.classList) : a.className.split(" ");
                        });
                },
                {},
            ],
            3: [
                function (a, b, c) {
                    "use strict";
                    function d(a, b) {
                        return window.getComputedStyle(a)[b];
                    }
                    function e(a, b, c) {
                        return "number" == typeof c && (c = c.toString() + "px"), (a.style[b] = c), a;
                    }
                    function f(a, b) {
                        for (var c in b) {
                            var d = b[c];
                            "number" == typeof d && (d = d.toString() + "px"), (a.style[c] = d);
                        }
                        return a;
                    }
                    var g = {};
                    (g.e = function (a, b) {
                        var c = document.createElement(a);
                        return (c.className = b), c;
                    }),
                        (g.appendTo = function (a, b) {
                            return b.appendChild(a), a;
                        }),
                        (g.css = function (a, b, c) {
                            return "object" == typeof b ? f(a, b) : void 0 === c ? d(a, b) : e(a, b, c);
                        }),
                        (g.matches = function (a, b) {
                            return void 0 !== a.matches
                                ? a.matches(b)
                                : void 0 !== a.matchesSelector
                                    ? a.matchesSelector(b)
                                    : void 0 !== a.webkitMatchesSelector
                                        ? a.webkitMatchesSelector(b)
                                        : void 0 !== a.mozMatchesSelector
                                            ? a.mozMatchesSelector(b)
                                            : void 0 !== a.msMatchesSelector
                                                ? a.msMatchesSelector(b)
                                                : void 0;
                        }),
                        (g.remove = function (a) {
                            void 0 !== a.remove ? a.remove() : a.parentNode && a.parentNode.removeChild(a);
                        }),
                        (g.queryChildren = function (a, b) {
                            return Array.prototype.filter.call(a.childNodes, function (a) {
                                return g.matches(a, b);
                            });
                        }),
                        (b.exports = g);
                },
                {},
            ],
            4: [
                function (a, b, c) {
                    "use strict";
                    var d = function (a) {
                        (this.element = a), (this.events = {});
                    };
                    (d.prototype.bind = function (a, b) {
                        void 0 === this.events[a] && (this.events[a] = []), this.events[a].push(b), this.element.addEventListener(a, b, !1);
                    }),
                        (d.prototype.unbind = function (a, b) {
                            var c = void 0 !== b;
                            this.events[a] = this.events[a].filter(function (d) {
                                return !(!c || d === b) || (this.element.removeEventListener(a, d, !1), !1);
                            }, this);
                        }),
                        (d.prototype.unbindAll = function () {
                            for (var a in this.events) this.unbind(a);
                        });
                    var e = function () {
                        this.eventElements = [];
                    };
                    (e.prototype.eventElement = function (a) {
                        var b = this.eventElements.filter(function (b) {
                            return b.element === a;
                        })[0];
                        return void 0 === b && ((b = new d(a)), this.eventElements.push(b)), b;
                    }),
                        (e.prototype.bind = function (a, b, c) {
                            this.eventElement(a).bind(b, c);
                        }),
                        (e.prototype.unbind = function (a, b, c) {
                            this.eventElement(a).unbind(b, c);
                        }),
                        (e.prototype.unbindAll = function () {
                            for (var a = 0; a < this.eventElements.length; a++) this.eventElements[a].unbindAll();
                        }),
                        (e.prototype.once = function (a, b, c) {
                            var d = this.eventElement(a),
                                e = function (a) {
                                    d.unbind(b, e), c(a);
                                };
                            d.bind(b, e);
                        }),
                        (b.exports = e);
                },
                {},
            ],
            5: [
                function (a, b, c) {
                    "use strict";
                    b.exports = (function () {
                        function a() {
                            return Math.floor(65536 * (1 + Math.random()))
                                .toString(16)
                                .substring(1);
                        }
                        return function () {
                            return a() + a() + "-" + a() + "-" + a() + "-" + a() + "-" + a() + a() + a();
                        };
                    })();
                },
                {},
            ],
            6: [
                function (a, b, c) {
                    "use strict";
                    var d = a("./class"),
                        e = a("./dom"),
                        f = (c.toInt = function (a) {
                            return parseInt(a, 10) || 0;
                        }),
                        g = (c.clone = function (a) {
                            if (a) {
                                if (a.constructor === Array) return a.map(g);
                                if ("object" == typeof a) {
                                    var b = {};
                                    for (var c in a) b[c] = g(a[c]);
                                    return b;
                                }
                                return a;
                            }
                            return null;
                        });
                    (c.extend = function (a, b) {
                        var c = g(a);
                        for (var d in b) c[d] = g(b[d]);
                        return c;
                    }),
                        (c.isEditable = function (a) {
                            return e.matches(a, "input,[contenteditable]") || e.matches(a, "select,[contenteditable]") || e.matches(a, "textarea,[contenteditable]") || e.matches(a, "button,[contenteditable]");
                        }),
                        (c.removePsClasses = function (a) {
                            for (var b = d.list(a), c = 0; c < b.length; c++) {
                                var e = b[c];
                                0 === e.indexOf("ps-") && d.remove(a, e);
                            }
                        }),
                        (c.outerWidth = function (a) {
                            return f(e.css(a, "width")) + f(e.css(a, "paddingLeft")) + f(e.css(a, "paddingRight")) + f(e.css(a, "borderLeftWidth")) + f(e.css(a, "borderRightWidth"));
                        }),
                        (c.startScrolling = function (a, b) {
                            d.add(a, "ps-in-scrolling"), void 0 !== b ? d.add(a, "ps-" + b) : (d.add(a, "ps-x"), d.add(a, "ps-y"));
                        }),
                        (c.stopScrolling = function (a, b) {
                            d.remove(a, "ps-in-scrolling"), void 0 !== b ? d.remove(a, "ps-" + b) : (d.remove(a, "ps-x"), d.remove(a, "ps-y"));
                        }),
                        (c.env = {
                            isWebKit: "WebkitAppearance" in document.documentElement.style,
                            supportsTouch: "ontouchstart" in window || (window.DocumentTouch && document instanceof window.DocumentTouch),
                            supportsIePointer: null !== window.navigator.msMaxTouchPoints,
                        });
                },
                { "./class": 2, "./dom": 3 },
            ],
            7: [
                function (a, b, c) {
                    "use strict";
                    var d = a("./plugin/destroy"),
                        e = a("./plugin/initialize"),
                        f = a("./plugin/update");
                    b.exports = { initialize: e, update: f, destroy: d };
                },
                { "./plugin/destroy": 9, "./plugin/initialize": 17, "./plugin/update": 21 },
            ],
            8: [
                function (a, b, c) {
                    "use strict";
                    b.exports = {
                        handlers: ["click-rail", "drag-scrollbar", "keyboard", "wheel", "touch"],
                        maxScrollbarLength: null,
                        minScrollbarLength: null,
                        scrollXMarginOffset: 0,
                        scrollYMarginOffset: 0,
                        suppressScrollX: !1,
                        suppressScrollY: !1,
                        swipePropagation: !0,
                        useBothWheelAxes: !1,
                        wheelPropagation: !1,
                        wheelSpeed: 1,
                        theme: "default",
                    };
                },
                {},
            ],
            9: [
                function (a, b, c) {
                    "use strict";
                    var d = a("../lib/helper"),
                        e = a("../lib/dom"),
                        f = a("./instances");
                    b.exports = function (a) {
                        var b = f.get(a);
                        b && (b.event.unbindAll(), e.remove(b.scrollbarX), e.remove(b.scrollbarY), e.remove(b.scrollbarXRail), e.remove(b.scrollbarYRail), d.removePsClasses(a), f.remove(a));
                    };
                },
                { "../lib/dom": 3, "../lib/helper": 6, "./instances": 18 },
            ],
            10: [
                function (a, b, c) {
                    "use strict";
                    function d(a, b) {
                        function c(a) {
                            return a.getBoundingClientRect();
                        }
                        var d = function (a) {
                            a.stopPropagation();
                        };
                        b.event.bind(b.scrollbarY, "click", d),
                            b.event.bind(b.scrollbarYRail, "click", function (d) {
                                var e = d.pageY - window.pageYOffset - c(b.scrollbarYRail).top,
                                    h = e > b.scrollbarYTop ? 1 : -1;
                                g(a, "top", a.scrollTop + h * b.containerHeight), f(a), d.stopPropagation();
                            }),
                            b.event.bind(b.scrollbarX, "click", d),
                            b.event.bind(b.scrollbarXRail, "click", function (d) {
                                var e = d.pageX - window.pageXOffset - c(b.scrollbarXRail).left,
                                    h = e > b.scrollbarXLeft ? 1 : -1;
                                g(a, "left", a.scrollLeft + h * b.containerWidth), f(a), d.stopPropagation();
                            });
                    }
                    var e = a("../instances"),
                        f = a("../update-geometry"),
                        g = a("../update-scroll");
                    b.exports = function (a) {
                        d(a, e.get(a));
                    };
                },
                { "../instances": 18, "../update-geometry": 19, "../update-scroll": 20 },
            ],
            11: [
                function (a, b, c) {
                    "use strict";
                    function d(a, b) {
                        function c(c) {
                            var e = d + c * b.railXRatio,
                                g = Math.max(0, b.scrollbarXRail.getBoundingClientRect().left) + b.railXRatio * (b.railXWidth - b.scrollbarXWidth);
                            b.scrollbarXLeft = e < 0 ? 0 : e > g ? g : e;
                            var h = f.toInt((b.scrollbarXLeft * (b.contentWidth - b.containerWidth)) / (b.containerWidth - b.railXRatio * b.scrollbarXWidth)) - b.negativeScrollAdjustment;
                            j(a, "left", h);
                        }
                        var d = null,
                            e = null,
                            h = function (b) {
                                c(b.pageX - e), i(a), b.stopPropagation(), b.preventDefault();
                            },
                            k = function () {
                                f.stopScrolling(a, "x"), b.event.unbind(b.ownerDocument, "mousemove", h);
                            };
                        b.event.bind(b.scrollbarX, "mousedown", function (c) {
                            (e = c.pageX),
                                (d = f.toInt(g.css(b.scrollbarX, "left")) * b.railXRatio),
                                f.startScrolling(a, "x"),
                                b.event.bind(b.ownerDocument, "mousemove", h),
                                b.event.once(b.ownerDocument, "mouseup", k),
                                c.stopPropagation(),
                                c.preventDefault();
                        });
                    }
                    function e(a, b) {
                        function c(c) {
                            var e = d + c * b.railYRatio,
                                g = Math.max(0, b.scrollbarYRail.getBoundingClientRect().top) + b.railYRatio * (b.railYHeight - b.scrollbarYHeight);
                            b.scrollbarYTop = e < 0 ? 0 : e > g ? g : e;
                            var h = f.toInt((b.scrollbarYTop * (b.contentHeight - b.containerHeight)) / (b.containerHeight - b.railYRatio * b.scrollbarYHeight));
                            j(a, "top", h);
                        }
                        var d = null,
                            e = null,
                            h = function (b) {
                                c(b.pageY - e), i(a), b.stopPropagation(), b.preventDefault();
                            },
                            k = function () {
                                f.stopScrolling(a, "y"), b.event.unbind(b.ownerDocument, "mousemove", h);
                            };
                        b.event.bind(b.scrollbarY, "mousedown", function (c) {
                            (e = c.pageY),
                                (d = f.toInt(g.css(b.scrollbarY, "top")) * b.railYRatio),
                                f.startScrolling(a, "y"),
                                b.event.bind(b.ownerDocument, "mousemove", h),
                                b.event.once(b.ownerDocument, "mouseup", k),
                                c.stopPropagation(),
                                c.preventDefault();
                        });
                    }
                    var f = a("../../lib/helper"),
                        g = a("../../lib/dom"),
                        h = a("../instances"),
                        i = a("../update-geometry"),
                        j = a("../update-scroll");
                    b.exports = function (a) {
                        var b = h.get(a);
                        d(a, b), e(a, b);
                    };
                },
                { "../../lib/dom": 3, "../../lib/helper": 6, "../instances": 18, "../update-geometry": 19, "../update-scroll": 20 },
            ],
            12: [
                function (a, b, c) {
                    "use strict";
                    function d(a, b) {
                        function c(c, d) {
                            var e = a.scrollTop;
                            if (0 === c) {
                                if (!b.scrollbarYActive) return !1;
                                if ((0 === e && d > 0) || (e >= b.contentHeight - b.containerHeight && d < 0)) return !b.settings.wheelPropagation;
                            }
                            var f = a.scrollLeft;
                            if (0 === d) {
                                if (!b.scrollbarXActive) return !1;
                                if ((0 === f && c < 0) || (f >= b.contentWidth - b.containerWidth && c > 0)) return !b.settings.wheelPropagation;
                            }
                            return !0;
                        }
                        var d = !1;
                        b.event.bind(a, "mouseenter", function () {
                            d = !0;
                        }),
                            b.event.bind(a, "mouseleave", function () {
                                d = !1;
                            });
                        var g = !1;
                        b.event.bind(b.ownerDocument, "keydown", function (j) {
                            if (!((j.isDefaultPrevented && j.isDefaultPrevented()) || j.defaultPrevented)) {
                                var k = f.matches(b.scrollbarX, ":focus") || f.matches(b.scrollbarY, ":focus");
                                if (d || k) {
                                    var l = document.activeElement ? document.activeElement : b.ownerDocument.activeElement;
                                    if (l) {
                                        if ("IFRAME" === l.tagName) l = l.contentDocument.activeElement;
                                        else for (; l.shadowRoot; ) l = l.shadowRoot.activeElement;
                                        if (e.isEditable(l)) return;
                                    }
                                    var m = 0,
                                        n = 0;
                                    switch (j.which) {
                                        case 37:
                                            m = j.metaKey ? -b.contentWidth : j.altKey ? -b.containerWidth : -30;
                                            break;
                                        case 38:
                                            n = j.metaKey ? b.contentHeight : j.altKey ? b.containerHeight : 30;
                                            break;
                                        case 39:
                                            m = j.metaKey ? b.contentWidth : j.altKey ? b.containerWidth : 30;
                                            break;
                                        case 40:
                                            n = j.metaKey ? -b.contentHeight : j.altKey ? -b.containerHeight : -30;
                                            break;
                                        case 33:
                                            n = 90;
                                            break;
                                        case 32:
                                            n = j.shiftKey ? 90 : -90;
                                            break;
                                        case 34:
                                            n = -90;
                                            break;
                                        case 35:
                                            n = j.ctrlKey ? -b.contentHeight : -b.containerHeight;
                                            break;
                                        case 36:
                                            n = j.ctrlKey ? a.scrollTop : b.containerHeight;
                                            break;
                                        default:
                                            return;
                                    }
                                    i(a, "top", a.scrollTop - n), i(a, "left", a.scrollLeft + m), h(a), (g = c(m, n)) && j.preventDefault();
                                }
                            }
                        });
                    }
                    var e = a("../../lib/helper"),
                        f = a("../../lib/dom"),
                        g = a("../instances"),
                        h = a("../update-geometry"),
                        i = a("../update-scroll");
                    b.exports = function (a) {
                        d(a, g.get(a));
                    };
                },
                { "../../lib/dom": 3, "../../lib/helper": 6, "../instances": 18, "../update-geometry": 19, "../update-scroll": 20 },
            ],
            13: [
                function (a, b, c) {
                    "use strict";
                    function d(a, b) {
                        function c(c, d) {
                            var e = a.scrollTop;
                            if (0 === c) {
                                if (!b.scrollbarYActive) return !1;
                                if ((0 === e && d > 0) || (e >= b.contentHeight - b.containerHeight && d < 0)) return !b.settings.wheelPropagation;
                            }
                            var f = a.scrollLeft;
                            if (0 === d) {
                                if (!b.scrollbarXActive) return !1;
                                if ((0 === f && c < 0) || (f >= b.contentWidth - b.containerWidth && c > 0)) return !b.settings.wheelPropagation;
                            }
                            return !0;
                        }
                        function d(a) {
                            var b = a.deltaX,
                                c = -1 * a.deltaY;
                            return (
                                (void 0 !== b && void 0 !== c) || ((b = (-1 * a.wheelDeltaX) / 6), (c = a.wheelDeltaY / 6)),
                                a.deltaMode && 1 === a.deltaMode && ((b *= 10), (c *= 10)),
                                b !== b && c !== c && ((b = 0), (c = a.wheelDelta)),
                                    a.shiftKey ? [-c, -b] : [b, c]
                            );
                        }
                        function e(b, c) {
                            var d = a.querySelector("textarea:hover, select[multiple]:hover, .ps-child:hover");
                            if (d) {
                                if (!window.getComputedStyle(d).overflow.match(/(scroll|auto)/)) return !1;
                                var e = d.scrollHeight - d.clientHeight;
                                if (e > 0 && !((0 === d.scrollTop && c > 0) || (d.scrollTop === e && c < 0))) return !0;
                                var f = d.scrollLeft - d.clientWidth;
                                if (f > 0 && !((0 === d.scrollLeft && b < 0) || (d.scrollLeft === f && b > 0))) return !0;
                            }
                            return !1;
                        }
                        function h(h) {
                            var j = d(h),
                                k = j[0],
                                l = j[1];
                            e(k, l) ||
                            ((i = !1),
                                b.settings.useBothWheelAxes
                                    ? b.scrollbarYActive && !b.scrollbarXActive
                                        ? (l ? g(a, "top", a.scrollTop - l * b.settings.wheelSpeed) : g(a, "top", a.scrollTop + k * b.settings.wheelSpeed), (i = !0))
                                        : b.scrollbarXActive && !b.scrollbarYActive && (k ? g(a, "left", a.scrollLeft + k * b.settings.wheelSpeed) : g(a, "left", a.scrollLeft - l * b.settings.wheelSpeed), (i = !0))
                                    : (g(a, "top", a.scrollTop - l * b.settings.wheelSpeed), g(a, "left", a.scrollLeft + k * b.settings.wheelSpeed)),
                                f(a),
                            (i = i || c(k, l)) && (h.stopPropagation(), h.preventDefault()));
                        }
                        var i = !1;
                        void 0 !== window.onwheel ? b.event.bind(a, "wheel", h) : void 0 !== window.onmousewheel && b.event.bind(a, "mousewheel", h);
                    }
                    var e = a("../instances"),
                        f = a("../update-geometry"),
                        g = a("../update-scroll");
                    b.exports = function (a) {
                        d(a, e.get(a));
                    };
                },
                { "../instances": 18, "../update-geometry": 19, "../update-scroll": 20 },
            ],
            14: [
                function (a, b, c) {
                    "use strict";
                    function d(a, b) {
                        b.event.bind(a, "scroll", function () {
                            f(a);
                        });
                    }
                    var e = a("../instances"),
                        f = a("../update-geometry");
                    b.exports = function (a) {
                        d(a, e.get(a));
                    };
                },
                { "../instances": 18, "../update-geometry": 19 },
            ],
            15: [
                function (a, b, c) {
                    "use strict";
                    function d(a, b) {
                        function c() {
                            var a = window.getSelection ? window.getSelection() : document.getSelection ? document.getSelection() : "";
                            return 0 === a.toString().length ? null : a.getRangeAt(0).commonAncestorContainer;
                        }
                        function d() {
                            j ||
                            (j = setInterval(function () {
                                return f.get(a) ? (h(a, "top", a.scrollTop + k.top), h(a, "left", a.scrollLeft + k.left), void g(a)) : void clearInterval(j);
                            }, 50));
                        }
                        function i() {
                            j && (clearInterval(j), (j = null)), e.stopScrolling(a);
                        }
                        var j = null,
                            k = { top: 0, left: 0 },
                            l = !1;
                        b.event.bind(b.ownerDocument, "selectionchange", function () {
                            a.contains(c()) ? (l = !0) : ((l = !1), i());
                        }),
                            b.event.bind(window, "mouseup", function () {
                                l && ((l = !1), i());
                            }),
                            b.event.bind(window, "keyup", function () {
                                l && ((l = !1), i());
                            }),
                            b.event.bind(window, "mousemove", function (b) {
                                if (l) {
                                    var c = { x: b.pageX, y: b.pageY },
                                        f = { left: a.offsetLeft, right: a.offsetLeft + a.offsetWidth, top: a.offsetTop, bottom: a.offsetTop + a.offsetHeight };
                                    c.x < f.left + 3 ? ((k.left = -5), e.startScrolling(a, "x")) : c.x > f.right - 3 ? ((k.left = 5), e.startScrolling(a, "x")) : (k.left = 0),
                                        c.y < f.top + 3 ? ((k.top = f.top + 3 - c.y < 5 ? -5 : -20), e.startScrolling(a, "y")) : c.y > f.bottom - 3 ? ((k.top = c.y - f.bottom + 3 < 5 ? 5 : 20), e.startScrolling(a, "y")) : (k.top = 0),
                                        0 === k.top && 0 === k.left ? i() : d();
                                }
                            });
                    }
                    var e = a("../../lib/helper"),
                        f = a("../instances"),
                        g = a("../update-geometry"),
                        h = a("../update-scroll");
                    b.exports = function (a) {
                        d(a, f.get(a));
                    };
                },
                { "../../lib/helper": 6, "../instances": 18, "../update-geometry": 19, "../update-scroll": 20 },
            ],
            16: [
                function (a, b, c) {
                    "use strict";
                    function d(a, b, c, d) {
                        function e(c, d) {
                            var e = a.scrollTop,
                                f = a.scrollLeft,
                                g = Math.abs(c),
                                h = Math.abs(d);
                            if (h > g) {
                                if ((d < 0 && e === b.contentHeight - b.containerHeight) || (d > 0 && 0 === e)) return !b.settings.swipePropagation;
                            } else if (g > h && ((c < 0 && f === b.contentWidth - b.containerWidth) || (c > 0 && 0 === f))) return !b.settings.swipePropagation;
                            return !0;
                        }
                        function i(b, c) {
                            h(a, "top", a.scrollTop - c), h(a, "left", a.scrollLeft - b), g(a);
                        }
                        function j() {
                            u = !0;
                        }
                        function k() {
                            u = !1;
                        }
                        function l(a) {
                            return a.targetTouches ? a.targetTouches[0] : a;
                        }
                        function m(a) {
                            return !(!a.targetTouches || 1 !== a.targetTouches.length) || !(!a.pointerType || "mouse" === a.pointerType || a.pointerType === a.MSPOINTER_TYPE_MOUSE);
                        }
                        function n(a) {
                            if (m(a)) {
                                v = !0;
                                var b = l(a);
                                (q.pageX = b.pageX), (q.pageY = b.pageY), (r = new Date().getTime()), null !== t && clearInterval(t), a.stopPropagation();
                            }
                        }
                        function o(a) {
                            if ((!v && b.settings.swipePropagation && n(a), !u && v && m(a))) {
                                var c = l(a),
                                    d = { pageX: c.pageX, pageY: c.pageY },
                                    f = d.pageX - q.pageX,
                                    g = d.pageY - q.pageY;
                                i(f, g), (q = d);
                                var h = new Date().getTime(),
                                    j = h - r;
                                j > 0 && ((s.x = f / j), (s.y = g / j), (r = h)), e(f, g) && (a.stopPropagation(), a.preventDefault());
                            }
                        }
                        function p() {
                            !u &&
                            v &&
                            ((v = !1),
                                clearInterval(t),
                                (t = setInterval(function () {
                                    return f.get(a) && (s.x || s.y) ? (Math.abs(s.x) < 0.01 && Math.abs(s.y) < 0.01 ? void clearInterval(t) : (i(30 * s.x, 30 * s.y), (s.x *= 0.8), void (s.y *= 0.8))) : void clearInterval(t);
                                }, 10)));
                        }
                        var q = {},
                            r = 0,
                            s = {},
                            t = null,
                            u = !1,
                            v = !1;
                        c
                            ? (b.event.bind(window, "touchstart", j), b.event.bind(window, "touchend", k), b.event.bind(a, "touchstart", n), b.event.bind(a, "touchmove", o), b.event.bind(a, "touchend", p))
                            : d &&
                            (window.PointerEvent
                                ? (b.event.bind(window, "pointerdown", j), b.event.bind(window, "pointerup", k), b.event.bind(a, "pointerdown", n), b.event.bind(a, "pointermove", o), b.event.bind(a, "pointerup", p))
                                : window.MSPointerEvent &&
                                (b.event.bind(window, "MSPointerDown", j), b.event.bind(window, "MSPointerUp", k), b.event.bind(a, "MSPointerDown", n), b.event.bind(a, "MSPointerMove", o), b.event.bind(a, "MSPointerUp", p)));
                    }
                    var e = a("../../lib/helper"),
                        f = a("../instances"),
                        g = a("../update-geometry"),
                        h = a("../update-scroll");
                    b.exports = function (a) {
                        if (e.env.supportsTouch || e.env.supportsIePointer) {
                            d(a, f.get(a), e.env.supportsTouch, e.env.supportsIePointer);
                        }
                    };
                },
                { "../../lib/helper": 6, "../instances": 18, "../update-geometry": 19, "../update-scroll": 20 },
            ],
            17: [
                function (a, b, c) {
                    "use strict";
                    var d = a("../lib/helper"),
                        e = a("../lib/class"),
                        f = a("./instances"),
                        g = a("./update-geometry"),
                        h = {
                            "click-rail": a("./handler/click-rail"),
                            "drag-scrollbar": a("./handler/drag-scrollbar"),
                            keyboard: a("./handler/keyboard"),
                            wheel: a("./handler/mouse-wheel"),
                            touch: a("./handler/touch"),
                            selection: a("./handler/selection"),
                        },
                        i = a("./handler/native-scroll");
                    b.exports = function (a, b) {
                        (b = "object" == typeof b ? b : {}), e.add(a, "ps-container");
                        var c = f.add(a);
                        (c.settings = d.extend(c.settings, b)),
                            e.add(a, "ps-theme-" + c.settings.theme),
                            c.settings.handlers.forEach(function (b) {
                                h[b](a);
                            }),
                            i(a),
                            g(a);
                    };
                },
                {
                    "../lib/class": 2,
                    "../lib/helper": 6,
                    "./handler/click-rail": 10,
                    "./handler/drag-scrollbar": 11,
                    "./handler/keyboard": 12,
                    "./handler/mouse-wheel": 13,
                    "./handler/native-scroll": 14,
                    "./handler/selection": 15,
                    "./handler/touch": 16,
                    "./instances": 18,
                    "./update-geometry": 19,
                },
            ],
            18: [
                function (a, b, c) {
                    "use strict";
                    function d(a) {
                        function b() {
                            i.add(a, "ps-focus");
                        }
                        function c() {
                            i.remove(a, "ps-focus");
                        }
                        var d = this;
                        (d.settings = h.clone(j)),
                            (d.containerWidth = null),
                            (d.containerHeight = null),
                            (d.contentWidth = null),
                            (d.contentHeight = null),
                            (d.isRtl = "rtl" === k.css(a, "direction")),
                            (d.isNegativeScroll = (function () {
                                var b = a.scrollLeft,
                                    c = null;
                                return (a.scrollLeft = -1), (c = a.scrollLeft < 0), (a.scrollLeft = b), c;
                            })()),
                            (d.negativeScrollAdjustment = d.isNegativeScroll ? a.scrollWidth - a.clientWidth : 0),
                            (d.event = new l()),
                            (d.ownerDocument = a.ownerDocument || document),
                            (d.scrollbarXRail = k.appendTo(k.e("div", "ps-scrollbar-x-rail"), a)),
                            (d.scrollbarX = k.appendTo(k.e("div", "ps-scrollbar-x"), d.scrollbarXRail)),
                            d.scrollbarX.setAttribute("tabindex", 0),
                            d.event.bind(d.scrollbarX, "focus", b),
                            d.event.bind(d.scrollbarX, "blur", c),
                            (d.scrollbarXActive = null),
                            (d.scrollbarXWidth = null),
                            (d.scrollbarXLeft = null),
                            (d.scrollbarXBottom = h.toInt(k.css(d.scrollbarXRail, "bottom"))),
                            (d.isScrollbarXUsingBottom = d.scrollbarXBottom === d.scrollbarXBottom),
                            (d.scrollbarXTop = d.isScrollbarXUsingBottom ? null : h.toInt(k.css(d.scrollbarXRail, "top"))),
                            (d.railBorderXWidth = h.toInt(k.css(d.scrollbarXRail, "borderLeftWidth")) + h.toInt(k.css(d.scrollbarXRail, "borderRightWidth"))),
                            k.css(d.scrollbarXRail, "display", "block"),
                            (d.railXMarginWidth = h.toInt(k.css(d.scrollbarXRail, "marginLeft")) + h.toInt(k.css(d.scrollbarXRail, "marginRight"))),
                            k.css(d.scrollbarXRail, "display", ""),
                            (d.railXWidth = null),
                            (d.railXRatio = null),
                            (d.scrollbarYRail = k.appendTo(k.e("div", "ps-scrollbar-y-rail"), a)),
                            (d.scrollbarY = k.appendTo(k.e("div", "ps-scrollbar-y"), d.scrollbarYRail)),
                            d.scrollbarY.setAttribute("tabindex", 0),
                            d.event.bind(d.scrollbarY, "focus", b),
                            d.event.bind(d.scrollbarY, "blur", c),
                            (d.scrollbarYActive = null),
                            (d.scrollbarYHeight = null),
                            (d.scrollbarYTop = null),
                            (d.scrollbarYRight = h.toInt(k.css(d.scrollbarYRail, "right"))),
                            (d.isScrollbarYUsingRight = d.scrollbarYRight === d.scrollbarYRight),
                            (d.scrollbarYLeft = d.isScrollbarYUsingRight ? null : h.toInt(k.css(d.scrollbarYRail, "left"))),
                            (d.scrollbarYOuterWidth = d.isRtl ? h.outerWidth(d.scrollbarY) : null),
                            (d.railBorderYWidth = h.toInt(k.css(d.scrollbarYRail, "borderTopWidth")) + h.toInt(k.css(d.scrollbarYRail, "borderBottomWidth"))),
                            k.css(d.scrollbarYRail, "display", "block"),
                            (d.railYMarginHeight = h.toInt(k.css(d.scrollbarYRail, "marginTop")) + h.toInt(k.css(d.scrollbarYRail, "marginBottom"))),
                            k.css(d.scrollbarYRail, "display", ""),
                            (d.railYHeight = null),
                            (d.railYRatio = null);
                    }
                    function e(a) {
                        return a.getAttribute("data-ps-id");
                    }
                    function f(a, b) {
                        a.setAttribute("data-ps-id", b);
                    }
                    function g(a) {
                        a.removeAttribute("data-ps-id");
                    }
                    var h = a("../lib/helper"),
                        i = a("../lib/class"),
                        j = a("./default-setting"),
                        k = a("../lib/dom"),
                        l = a("../lib/event-manager"),
                        m = a("../lib/guid"),
                        n = {};
                    (c.add = function (a) {
                        var b = m();
                        return f(a, b), (n[b] = new d(a)), n[b];
                    }),
                        (c.remove = function (a) {
                            delete n[e(a)], g(a);
                        }),
                        (c.get = function (a) {
                            return n[e(a)];
                        });
                },
                { "../lib/class": 2, "../lib/dom": 3, "../lib/event-manager": 4, "../lib/guid": 5, "../lib/helper": 6, "./default-setting": 8 },
            ],
            19: [
                function (a, b, c) {
                    "use strict";
                    function d(a, b) {
                        return a.settings.minScrollbarLength && (b = Math.max(b, a.settings.minScrollbarLength)), a.settings.maxScrollbarLength && (b = Math.min(b, a.settings.maxScrollbarLength)), b;
                    }
                    function e(a, b) {
                        var c = { width: b.railXWidth };
                        b.isRtl ? (c.left = b.negativeScrollAdjustment + a.scrollLeft + b.containerWidth - b.contentWidth) : (c.left = a.scrollLeft),
                            b.isScrollbarXUsingBottom ? (c.bottom = b.scrollbarXBottom - a.scrollTop) : (c.top = b.scrollbarXTop + a.scrollTop),
                            h.css(b.scrollbarXRail, c);
                        var d = { top: a.scrollTop, height: b.railYHeight };
                        b.isScrollbarYUsingRight
                            ? b.isRtl
                                ? (d.right = b.contentWidth - (b.negativeScrollAdjustment + a.scrollLeft) - b.scrollbarYRight - b.scrollbarYOuterWidth)
                                : (d.right = b.scrollbarYRight - a.scrollLeft)
                            : b.isRtl
                                ? (d.left = b.negativeScrollAdjustment + a.scrollLeft + 2 * b.containerWidth - b.contentWidth - b.scrollbarYLeft - b.scrollbarYOuterWidth)
                                : (d.left = b.scrollbarYLeft + a.scrollLeft),
                            h.css(b.scrollbarYRail, d),
                            h.css(b.scrollbarX, { left: b.scrollbarXLeft, width: b.scrollbarXWidth - b.railBorderXWidth }),
                            h.css(b.scrollbarY, { top: b.scrollbarYTop, height: b.scrollbarYHeight - b.railBorderYWidth });
                    }
                    var f = a("../lib/helper"),
                        g = a("../lib/class"),
                        h = a("../lib/dom"),
                        i = a("./instances"),
                        j = a("./update-scroll");
                    b.exports = function (a) {
                        var b = i.get(a);
                        (b.containerWidth = a.clientWidth), (b.containerHeight = a.clientHeight), (b.contentWidth = a.scrollWidth), (b.contentHeight = a.scrollHeight);
                        var c;
                        a.contains(b.scrollbarXRail) ||
                        ((c = h.queryChildren(a, ".ps-scrollbar-x-rail")),
                        c.length > 0 &&
                        c.forEach(function (a) {
                            h.remove(a);
                        }),
                            h.appendTo(b.scrollbarXRail, a)),
                        a.contains(b.scrollbarYRail) ||
                        ((c = h.queryChildren(a, ".ps-scrollbar-y-rail")),
                        c.length > 0 &&
                        c.forEach(function (a) {
                            h.remove(a);
                        }),
                            h.appendTo(b.scrollbarYRail, a)),
                            !b.settings.suppressScrollX && b.containerWidth + b.settings.scrollXMarginOffset < b.contentWidth
                                ? ((b.scrollbarXActive = !0),
                                    (b.railXWidth = b.containerWidth - b.railXMarginWidth),
                                    (b.railXRatio = b.containerWidth / b.railXWidth),
                                    (b.scrollbarXWidth = d(b, f.toInt((b.railXWidth * b.containerWidth) / b.contentWidth))),
                                    (b.scrollbarXLeft = f.toInt(((b.negativeScrollAdjustment + a.scrollLeft) * (b.railXWidth - b.scrollbarXWidth)) / (b.contentWidth - b.containerWidth))))
                                : (b.scrollbarXActive = !1),
                            !b.settings.suppressScrollY && b.containerHeight + b.settings.scrollYMarginOffset < b.contentHeight
                                ? ((b.scrollbarYActive = !0),
                                    (b.railYHeight = b.containerHeight - b.railYMarginHeight),
                                    (b.railYRatio = b.containerHeight / b.railYHeight),
                                    (b.scrollbarYHeight = d(b, f.toInt((b.railYHeight * b.containerHeight) / b.contentHeight))),
                                    (b.scrollbarYTop = f.toInt((a.scrollTop * (b.railYHeight - b.scrollbarYHeight)) / (b.contentHeight - b.containerHeight))))
                                : (b.scrollbarYActive = !1),
                        b.scrollbarXLeft >= b.railXWidth - b.scrollbarXWidth && (b.scrollbarXLeft = b.railXWidth - b.scrollbarXWidth),
                        b.scrollbarYTop >= b.railYHeight - b.scrollbarYHeight && (b.scrollbarYTop = b.railYHeight - b.scrollbarYHeight),
                            e(a, b),
                            b.scrollbarXActive ? g.add(a, "ps-active-x") : (g.remove(a, "ps-active-x"), (b.scrollbarXWidth = 0), (b.scrollbarXLeft = 0), j(a, "left", 0)),
                            b.scrollbarYActive ? g.add(a, "ps-active-y") : (g.remove(a, "ps-active-y"), (b.scrollbarYHeight = 0), (b.scrollbarYTop = 0), j(a, "top", 0));
                    };
                },
                { "../lib/class": 2, "../lib/dom": 3, "../lib/helper": 6, "./instances": 18, "./update-scroll": 20 },
            ],
            20: [
                function (a, b, c) {
                    "use strict";
                    var d,
                        e,
                        f = a("./instances"),
                        g = function (a) {
                            var b = document.createEvent("Event");
                            return b.initEvent(a, !0, !0), b;
                        };
                    b.exports = function (a, b, c) {
                        if (void 0 === a) throw "You must provide an element to the update-scroll function";
                        if (void 0 === b) throw "You must provide an axis to the update-scroll function";
                        if (void 0 === c) throw "You must provide a value to the update-scroll function";
                        "top" === b && c <= 0 && ((a.scrollTop = c = 0), a.dispatchEvent(g("ps-y-reach-start"))), "left" === b && c <= 0 && ((a.scrollLeft = c = 0), a.dispatchEvent(g("ps-x-reach-start")));
                        var h = f.get(a);
                        "top" === b && c >= h.contentHeight - h.containerHeight && ((c = h.contentHeight - h.containerHeight), c - a.scrollTop <= 1 ? (c = a.scrollTop) : (a.scrollTop = c), a.dispatchEvent(g("ps-y-reach-end"))),
                        "left" === b && c >= h.contentWidth - h.containerWidth && ((c = h.contentWidth - h.containerWidth), c - a.scrollLeft <= 1 ? (c = a.scrollLeft) : (a.scrollLeft = c), a.dispatchEvent(g("ps-x-reach-end"))),
                        d || (d = a.scrollTop),
                        e || (e = a.scrollLeft),
                        "top" === b && c < d && a.dispatchEvent(g("ps-scroll-up")),
                        "top" === b && c > d && a.dispatchEvent(g("ps-scroll-down")),
                        "left" === b && c < e && a.dispatchEvent(g("ps-scroll-left")),
                        "left" === b && c > e && a.dispatchEvent(g("ps-scroll-right")),
                        "top" === b && ((a.scrollTop = d = c), a.dispatchEvent(g("ps-scroll-y"))),
                        "left" === b && ((a.scrollLeft = e = c), a.dispatchEvent(g("ps-scroll-x")));
                    };
                },
                { "./instances": 18 },
            ],
            21: [
                function (a, b, c) {
                    "use strict";
                    var d = a("../lib/helper"),
                        e = a("../lib/dom"),
                        f = a("./instances"),
                        g = a("./update-geometry"),
                        h = a("./update-scroll");
                    b.exports = function (a) {
                        var b = f.get(a);
                        b &&
                        ((b.negativeScrollAdjustment = b.isNegativeScroll ? a.scrollWidth - a.clientWidth : 0),
                            e.css(b.scrollbarXRail, "display", "block"),
                            e.css(b.scrollbarYRail, "display", "block"),
                            (b.railXMarginWidth = d.toInt(e.css(b.scrollbarXRail, "marginLeft")) + d.toInt(e.css(b.scrollbarXRail, "marginRight"))),
                            (b.railYMarginHeight = d.toInt(e.css(b.scrollbarYRail, "marginTop")) + d.toInt(e.css(b.scrollbarYRail, "marginBottom"))),
                            e.css(b.scrollbarXRail, "display", "none"),
                            e.css(b.scrollbarYRail, "display", "none"),
                            g(a),
                            h(a, "top", a.scrollTop),
                            h(a, "left", a.scrollLeft),
                            e.css(b.scrollbarXRail, "display", ""),
                            e.css(b.scrollbarYRail, "display", ""));
                    };
                },
                { "../lib/dom": 3, "../lib/helper": 6, "./instances": 18, "./update-geometry": 19, "./update-scroll": 20 },
            ],
        },
        {},
        [1]
    ),
    (function (a) {
        function b() {
            a(".main-level > li").hover(
                function () {
                    a(this).children("[id^=menu]").stop().slideDown("slow");
                },
                function () {
                    a(this).children("[id^=menu]").stop().slideUp("slow");
                }
            );
        }
        a(document).ready(function () {
            a(window).width() > 992 && b(),
                a(function () {
                    a('[data-toggle="tooltip"]').tooltip();
                }),
                a("#slider").slider({
                    range: "min",
                    value: 1,
                    min: 5,
                    step: 5,
                    max: 50,
                    slide: function (b, c) {
                        a("#form_distance").val(c.value);
                    },
                }),
                a("#form_distance").val(a("#slider").slider("value")),
                a(".icon-nav .search").click(function (b) {
                    b.preventDefault(), a(".search-wrapper").slideToggle("fast");
                }),
                a("#accordion-menu").collapse(),
                a("#menu-trigger").click(function (b) {
                    b.stopPropagation(), b.preventDefault(), a(".mobile-nav, .header, .wrapper").toggleClass("has-push-left");
                }),
                a(document).on("click touchstart", function (b) {
                    a(b.target).closest(".mobile-nav").length || a(".mobile-nav, .header, .wrapper").removeClass("has-push-left");
                }),
                /*
                a(".slider-for").slick({ slidesToShow: 1, slidesToScroll: 1, arrows: !1, fade: !0, asNavFor: ".slider-nav" }),
                a(".slider-nav").slick({ slidesToShow: 4, slidesToScroll: 1, asNavFor: ".slider-for", dots: !1, arrows: !0, focusOnSelect: !0, responsive: [{ breakpoint: 405, settings: { slidesToShow: 2 } }] }),
                */
                a("table .pin").click(function (b) {
                    a(this).toggleClass("selected");
                }),
                // a(".fancybox").fancybox(),
                a(".text-left-map-right .table-responsive").perfectScrollbar();
        }),
            a(window).scroll(function () {
                a(window).scrollTop() >= 50 ? (a(".header").addClass("small-header"), a("body").addClass("less-padding")) : (a(".header").removeClass("small-header"), a("body").removeClass("less-padding"));
            }),
            a(window).on("resize", function () {
                b();
            });
    })(jQuery.noConflict());
